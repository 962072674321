import { Controller } from "@hotwired/stimulus";
import { ajaxResponse } from "../../utils/forms";
import $ from "jquery";

export default class extends Controller {
  static targets = ["modal"];

  onSuccess(event) {
    this.handleModal();
    this.handleSuccessReplace(ajaxResponse(event));
  }

  handleModal() {
    if (this.hasModalTarget) {
      $(this.modalTarget).modal("hide");
    }
  }

  handleSuccessReplace(content) {
    if (this.data.has("success-replace")) {
      this.replaceContent(this.data.get("success-replace"), content);
    }
  }

  handleFailureReplace(event) {
    const replacement = ajaxResponse(event);
    if (this.data.has("failure-replace")) {
      this.replaceContent(this.data.get("failure-replace"), replacement);
    } else {
      this.replaceContent("self", replacement);
    }
  }

  replaceContent(selector, content) {
    if (selector === "self") {
      this.element.outerHTML = content;
    } else if (selector === "append") {
      let newDiv = document.createElement("div");
      this.element.parentNode.appendChild(newDiv);
      newDiv.outerHTML = content;
    } else {
      document.getElementById(selector).outerHTML = content;
    }
  }

  handleJSONSuccessReplace(event) {
    let response = event.detail[0];
    this.handleSuccessReplace(response.html);
  }

  resetForm() {
    this.element.reset();
  }

  submitForm() {
    const event = new CustomEvent("submit", {
      bubbles: true,
      cancelable: true
    });
    this.element.dispatchEvent(event);
  }
}
