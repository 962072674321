import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // Toggling the up/down arrow in the drawer does 3 things:
  // 1) hides or shows the category name depending on whether its open.
  // 2) changes the arrow (up if close, down if open)
  // 3) remove a class from the tab navigation that allows the drawer to expand
  toggle() {
    $(".slide-out-nav").toggleClass("slide-out-closed");
    var isOpen = $(".slide-out-nav").hasClass("slide-out-closed");
    if (!isOpen) {
      $(".mobile-tab-icon").removeClass("fa-chevron-up");
      $(".mobile-tab-icon").addClass("fa-chevron-down");
    } else {
      $(".mobile-tab-icon").removeClass("fa-chevron-down");
      $(".mobile-tab-icon").addClass("fa-chevron-up");
    }
  }

  moveUp(event) {
    const selected = $(event.target);
    $("li").removeClass("selected_item");
    selected.closest("li").addClass("selected_item");
    $(".slide-out-nav").toggleClass("slide-out-closed");
    $(".mobile-tab-icon").addClass("fa-chevron-up");
  }
}
