import { Controller } from "@hotwired/stimulus";
import { portal } from "./drawer_portal_controller";

/**
 * Closes the drawer that contains this element. Can be used to:
 * - Close a drawer in response to a turbo-stream update
 */
export default class extends Controller {
  connect() {
    portal(this.element, this.application, (drawer) => {
      drawer.hide();
    });
  }
}
