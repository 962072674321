import { Controller } from "@hotwired/stimulus";

/**
 * The Hubspot Schedule embedded iframe posts messages to our window when the
 * user has completed scheduling the event.
 *
 * This is a generic response mechanism for a Hubspot embed modeled off of
 * app/webpack/javascripts/controllers/manage/settings/churn_schedule_completion_controller.js.
 *
 */
export default class extends Controller {
  static targets = ["doneHide", "failureShow", "successHide", "successShow"];

  connect() {
    window.addEventListener("message", this.handleMessage);
  }

  disconnect() {
    window.removeEventListener("message", this.handleMessage);
  }

  handleMessage = event => {
    if (event.data.eventName === 'onFormSubmitted') {
      this.toggleSuccessTargets();
    } else {
      return
    }
  }

  toggleSuccessTargets() {
    this.successShowTargets.forEach((target) => {
      target.classList.remove("d-none");
    });
    this.successHideTargets.forEach((target) => {
      target.classList.add("d-none");
    });
  }
}
