import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  submit() {
    if (!this.hasFormTarget) return;

    this.formTarget.requestSubmit();
  }
}
