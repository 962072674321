import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["validate", "submit"];

  connect() {
    this.validate();
  }

  validate(_e) {
    let targets = [];

    if ($(this.element).find("form").length) {
      this.enableSubmit();
    }
    this.validateTargets.forEach((target) => {
      if (targets.includes(target.name)) {
        return;
      }
      targets.push(target.name);
      if (!this.validateElement(target)) this.disableSubmit();
    });
  }

  validateElement(target) {
    switch (target.type) {
      case "radio":
      case "checkbox":
        let input = $(`[name='${target.name}']:checked`);
        return input.length;
      default:
        return target.value;
    }
  }

  disableSubmit() {
    this.submitTargets.forEach((submit) => {
      submit.disabled = "disabled";
      submit.classList.add("disabled");
      submit.title = "Please fill out all required fields before continuing";
    });
  }

  enableSubmit() {
    this.submitTargets.forEach((submit) => {
      submit.disabled = false;
      submit.classList.remove("disabled");
      submit.title = "";
    });
  }
}
