import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "progress",
    "input",
    "nextButton",
    "previousButton",
    "index",
    "count"
  ];

  connect() {
    this.nextButtonContent = this.nextButtonTarget.innerHTML;
    this.filterDisabledInputs();
    this.togglePreviousButton();

    if (this.mobile) {
      // When a user on mobile clicks return, it goes to next question
      //   instead of submitting the form
      document.addEventListener("keydown", (event) => {
        if (event.code === "Enter") {
          this.nextQuestion(event);
        }
      });
    }
  }

  // debounces events to every half-second
  toggleHeight(event) {
    const now = new Date().getTime();
    if (this.toggleHeightWait) {
      if (this.toggleHeightWait > now) return;
    }
    this.toggleHeightWait = now + 500;
    let element = event.target.parentElement;
    if (element.classList.contains("pdf_form_taker__form_wrapper")) {
      element.classList.toggle("pdf_form_taker__form_wrapper--expanded");
    }
  }

  previousQuestion(_event) {
    this.data.set("index", Math.max(0, this.inputIndex - 1));
    this.adjustNextButton();
    this.focusQuestion();
    this.indexTarget.innerHTML = this.inputIndex + 1;
    this.togglePreviousButton();
  }

  nextQuestion(event) {
    if (this.nextButtonTarget.getAttribute("type") == "submit") return;

    event.preventDefault();
    this.data.set("index", Math.min(this.inputCount - 1, this.inputIndex + 1));
    this.adjustNextButton();
    this.focusQuestion();
    this.indexTarget.innerHTML = this.inputIndex + 1;
    this.togglePreviousButton();
  }

  focusQuestion() {
    this.inputTargets.forEach((element) => {
      element.classList.remove("pdf_form_taker__form_input--focused");
    });
    this.currentInput.classList.add("pdf_form_taker__form_input--focused");
    this.updateProgressBar();
    this.moveHighlight(this.currentInput);
  }

  updateProgressBar() {
    this.progressTarget.style.width = this.progressPercent() + "%";
  }

  filterDisabledInputs() {
    this.activeInputs = [];
    this.inputTargets.forEach((input, index) => {
      if (
        input.querySelector(".d-none[data-pdf-form-taker-target~='input']") ==
        null
      ) {
        this.activeInputs.push(index);
      }
    });
    this.updateProgressBar();
    this.adjustNextButton();
  }

  adjustNextButton() {
    if (this.inputIndex < this.inputCount - 1) {
      this.submitToNext();
    } else {
      this.nextToSubmit();
    }
  }

  submitToNext() {
    if (this.nextButtonTarget.getAttribute("type") == "button") return;

    this.nextButtonTarget.innerHTML = this.nextButtonContent;
    this.nextButtonTarget.setAttribute("type", "button");
  }

  nextToSubmit() {
    if (this.nextButtonTarget.getAttribute("type") == "submit") return;

    this.nextButtonTarget.innerHTML = "Submit";
    this.nextButtonTarget.setAttribute("type", "submit");
  }

  progressPercent() {
    return Math.round(((this.inputIndex + 1) / this.inputCount) * 100.0);
  }

  moveHighlight(element) {
    const takerEl = element.querySelector(
      "[data-pdf-form-taker-target~='input']"
    );
    if (!takerEl) return;

    takerEl.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center"
    });
    takerEl.dispatchEvent(new Event("focusin"));
  }

  togglePreviousButton() {
    if (this.inputIndex == 0) {
      this.previousButtonTarget.classList.add("opacity-0");
    } else {
      this.previousButtonTarget.classList.remove("opacity-0");
    }
  }

  clickedQuestion(event) {
    if (
      event.target.parentNode.classList.contains(
        "pdf_form_taker__form_input--focused"
      )
    )
      return;
    event.preventDefault();

    const position =
      event.target.dataset.position || event.target.parentNode.dataset.position;
    if (position) {
      this.data.set("index", parseInt(position) - 1);
      this.indexTarget.innerHTML = this.inputIndex + 1;
    }

    event.target.parentNode.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center"
    });
    this.focusQuestion();
    this.togglePreviousButton();
    this.adjustNextButton();
  }

  get inputCount() {
    return this.activeInputs.length;
  }

  get inputIndex() {
    return parseInt(this.data.get("index"));
  }

  get currentInput() {
    return this.inputTargets[this.activeInputs[this.inputIndex]];
  }

  get mobile() {
    return this.element.querySelector(".pdf_form_taker--mobile");
  }
}
