import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import $ from "jquery";

export default class extends Controller {
  static targets = ["labelText", "container ", "remoteContent"];

  handleClick(evt) {
    this.labelTextTarget.innerHTML = evt.target.dataset["label"];
    $(evt.target)
      .parent()
      .parent()
      .find(".dynamic-drop-down-item")
      .each((_, item) => {
        item.classList.remove("dynamic-drop-down-item--selected");
      });
    evt.target.parentElement.classList.add("dynamic-drop-down-item--selected");
    axios.get(evt.target.dataset["value"]).then(({ data }) => {
      this.remoteContentTarget.innerHTML = data;
    });
  }
}
