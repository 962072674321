import { Controller } from "@hotwired/stimulus";
import { portal } from "./drawer_portal_controller";
import { generateRemoteModal, removeRemoteModal } from "../../utils/modals";
import $ from "jquery";

/**
 * Usage:
 * <drawer>
 *   <this-element>
 *     <drawer-content-possibly-with-form-inputs>
 *   </this-element>
 * </drawer>
 *
 * Set data-confirm-close-drawer-only-changed="1" to track form-input changes
 * and only trigger modal if something changed. Not setting this value, or setting
 * it to "0" will open confirm-modal regardless of changed-inputs.
 */
export default class extends Controller {
  static targets = ["confirmModal"];

  connect() {
    this.initializeDirtyDetection();
    this.initializeRemoteModal();
  }

  disconnect() {
    $(this.element).off("change", "input,select,textarea", this.markDirty);
    removeRemoteModal(this.data.get("remoteModalId"));
  }

  /**
   * Listen to all contained inputs to detect when something has changed (i.e. is dirty).
   * When an existing drawer closed then re-opened, we need to reset the dirty-flag.
   */
  initializeDirtyDetection() {
    this.isDirty = false;
    this.drawer.element.addEventListener("drawer_portal:fetched", () => {
      this.isDirty = false;
    });
    $(this.element).on("change", "input,select,textarea", this.markDirty);
  }

  /**
   * Need to push the modal outside of the layout so that it renders "above" the drawer.
   * When this happens, the remote modal is no longer contained within the drawer,
   * therefore we need to artificially handle the close-drawer button.
   */
  initializeRemoteModal() {
    this.data.set(
      "remoteModalId",
      generateRemoteModal(this.confirmModalTarget.outerHTML)
    );
    this.modal
      .find("[data-action~='click->drawer-portal#hide']")
      .on("click", () => {
        this.drawer.hide();
      });
  }

  get drawer() {
    let result = null;
    portal(this.element, this.application, (drawer) => {
      result = drawer;
    });
    return result;
  }

  get modal() {
    return $(`#${this.data.get("remoteModalId")}`).find(".modal");
  }

  markDirty = () => {
    this.isDirty = true;
  };

  showModal() {
    if (parseInt(this.data.get("onlyChanged")) < 1 || this.isDirty) {
      this.modal.modal();
    } else {
      this.drawer.hide();
    }
  }
}
