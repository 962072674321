import { Controller } from "@hotwired/stimulus";

/**
 * ClipboardCopyController is to be used when you want to copy some content to
 * the users clipboard. The content can be in an input that is either visible
 * or not visible (button only).
 *
 * @example <caption>Rails helpers and hidden input.</caption>
 *
 * <div data-controller="clipboard-copy" data-clipboard-copy-success-content="Copied!">
 *   <%= hidden_field_tag(:some_url, employees_url, data: { clipboard_copy_target: "source" }) %>
 *   <%= button_outline_primary(data: { clipboard_copy_target: "button", action: "clipboard-copy#copy" }) do %>
 *     <%= far_icon('far fa-sm fa-copy') %> <%= 'Copy Link' %>
 *   <% end %>
 * </div>
 */
export default class extends Controller {
  static targets = ['button', 'source']
  static values = {
    successDuration: Number
  }

  connect () {
    if (!this.hasButtonTarget) return

    this.originalText = this.buttonTarget.innerHTML
    this.successDuration = this.successDurationValue || 2000
  }

  copy (event) {
    event.preventDefault()

    let text = this.sourceTarget.value

    navigator.clipboard.writeText(text).then(()=> {
      this.copied()
    });
  }

  // private

  copied () {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerHTML = this.data.get('successContent')

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalText
    }, this.successDuration)
  }
}
