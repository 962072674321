import { Controller } from "@hotwired/stimulus"
import axios from "axios";
import { toastDanger, toastSuccess } from "../manage/toasts_controller";

/**
 * Controller for toggling between two icons when one is clicked
 *
 * @example HTML in view (if using Stimulus 2 or later)
 *   <div data-controller="toggle-icon" data-toggle-icon-status-value='none'>
 *     <span data-toggle-icon-target="leftUnclickedIcon" data-action="click->toggle-icon#leftIconClicked">
 *        <%= image_pack_tag("swifticons/thumbs-up-unclicked.svg") %>
 *     </span>
 *     <span data-toggle-icon-target="leftClickedIcon" data-action="click->toggle-icon#leftIconClicked">
 *        <%= image_pack_tag("swifticons/thumbs-up-clicked.svg") %>
 *     </span>
 *     <span data-toggle-icon-target="rightUnclickedIcon" data-action="click->toggle-icon#rightIconClicked">
 *        <%= image_pack_tag("swifticons/thumbs-down-unclicked.svg") %>
 *     </span>
 *     <span data-toggle-icon-target="rightClickedIcon" data-action="click->toggle-icon#rightIconClicked">
 *        <%= image_pack_tag("swifticons/thumbs-down-clicked.svg") %>
 *     </span> 
 *   </div>
 */
export default class extends Controller {
  static targets = [
    'leftUnclickedIcon',
    'leftClickedIcon',
    'rightUnclickedIcon',
    'rightClickedIcon',
    'feedbackForm',
    'inputBox'
  ]
  static values = {
    iconStatus: { type: String, default: 'none'},
    urlPath: { type: String, default: '/kinesis_events' },
    managerId: Number
  }

  connect() {
    this.setIconDefaults();
  }

/**
 * Toggle the left icon with a clicked version of the icon
 *
 * @param {string} event the event from the action calling the function
 */
  leftIconClicked(event) {
    event.preventDefault()

    this.iconStatusValue = this.iconStatusValue == 'left-clicked' ? 'none' : 'left-clicked';

    if (this.iconStatusValue == 'none') {
      this.setIconDefaults();
    }
    if (this.iconStatusValue == 'left-clicked') {
      this.rightUnclickedIconTarget.hidden = false
      this.rightClickedIconTarget.hidden = true
      this.leftUnclickedIconTarget.hidden = true
      this.leftClickedIconTarget.hidden = false
      this.feedbackFormTarget.hidden = false
    }
  }

  /**
   * Toggle the right icon with a clicked version of the icon
   *
   * @param {string} event the event from the action calling the function
   */
   rightIconClicked(event) {
    event.preventDefault()

    this.iconStatusValue = this.iconStatusValue == 'right-clicked' ? 'none' : 'right-clicked';

    if (this.iconStatusValue == 'none') {
      this.setIconDefaults();
    }
    if (this.iconStatusValue == 'right-clicked') {
      this.rightUnclickedIconTarget.hidden = true
      this.rightClickedIconTarget.hidden = false
      this.leftUnclickedIconTarget.hidden = false
      this.leftClickedIconTarget.hidden = true
      this.feedbackFormTarget.hidden = false
    }
  }

  /**
   * Submit button will post to the kinesis endpoint to create a new redshift event
   * And then clears the input box and closes the expanded box
   *
   * @param {string} event the event from the action calling the function
   */
   submit() {
    const path = this.urlPathValue;
    axios.defaults.headers.common["X-CSRF-Token"] = document
      .querySelector("meta[name=csrf-token]")
      .getAttribute("content");
    axios.post(path, { 
      name: 'pulse_feedback',
      data: {
        thumb_status: this.iconStatusValue == 'left-clicked' ? 'thumbs-up' : 'thumbs-down',
        message: this.inputBoxTarget.value,
        manager_id: this.managerIdValue
      }
    }).then((_response) => {
      toastSuccess({
        title: 'Submitted successfully!'
      });
    }).catch((thrown) => {
      toastDanger({ title: "Something went wrong", body: "Please try again." });
    }).finally(() => this.setIconDefaults());
  }

  // Private

  setIconDefaults() {
      this.rightUnclickedIconTarget.hidden = false
      this.rightClickedIconTarget.hidden = true
      this.leftUnclickedIconTarget.hidden = false
      this.leftClickedIconTarget.hidden = true
      this.feedbackFormTarget.hidden = true
      this.inputBoxTarget.value = ''
  }
}
