import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["useDefaultsToggle", "saveDefaultsWrapper", "disable"];

  static values = {
    maySaveDefaults: Boolean
  };

  connect() {
    if (!this.hasUseDefaultsToggleTarget) {
      return;
    }
    this.toggleFieldState();
    this.toggleSaveDefaultsVisibility();
  }

  toggleFieldState() {
    if (this.useDefaultsToggleTarget.checked) {
      this.disableTargets.forEach((input) => {
        input.classList.add("disabled-field", "skip-pointer-events-all");
      });
    } else {
      this.disableTargets.forEach((input) => {
        input.classList.remove("disabled-field", "skip-pointer-events-all");
      });
    }
  }

  toggleSaveDefaultsVisibility() {
    if (this.maySaveDefaultsValue === false) {
      return;
    }
    if (this.useDefaultsToggleTarget.checked) {
      this.saveDefaultsWrapperTarget.hidden = true;
    } else {
      this.saveDefaultsWrapperTarget.hidden = false;
    }
  }
}
