import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["labelContainer"];

  connect() {
    if (this.data.get("name")) {
      this.updateFilename(this.data.get("name"));
    }
  }

  handleChange(evt) {
    this.updateFilename(evt.target.files[0].name);
  }

  /**
   * Normally, this replaces the entire contents of the labelContainerTarget, which includes
   * the label that acts as the open-file button. Setting data-has-attachments-editable="1"
   * preserves the label, allowing the user to click it again to replace the file-attachment.
   */
  updateFilename(name) {
    if (this.data.get("editable") === "1") {
      this.labelContainerTarget.querySelector("label").innerHTML = name;
    } else {
      this.labelContainerTarget.innerHTML = name;
    }
  }
}
