import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  get value() {
    return Number(this.inputTarget.value);
  }

  get step() {
    return this.numericAttribute("step", 1);
  }

  get minimum() {
    return this.numericAttribute("min", -Infinity);
  }

  get maximum() {
    return this.numericAttribute("max", Infinity);
  }

  update(newValue) {
    this.inputTarget.value = newValue;
    this.inputTarget.dispatchEvent(new Event("change"));
  }

  subtract() {
    const newValue = this.value - this.step;
    if (newValue >= this.minimum) this.update(newValue);
  }

  add() {
    const newValue = this.value + this.step;
    if (newValue <= this.maximum) this.update(newValue);
  }

  numericAttribute(name, defaultValue) {
    if (this.inputTarget.hasAttribute(name))
      return Number(this.inputTarget.getAttribute(name));
    return defaultValue;
  }
}
