import Controller from "./modal_reset_controller";

export default class extends Controller {
  static targets = [
    "deselectAllText",
    "selectAllText",
    "modal",
    "inputWrapper",
    "selectedCount"
  ];

  connect() {
    super.connect();
    this.updateSelected();
  }

  updateSelected() {
    if (!this.hasSelectedCountTarget) return;
    const count = this.inputWrapperTarget.querySelectorAll("input:checked")
      .length;
    this.selectedCountTargets.forEach((el) => {
      el.innerHTML = count;
    });
  }

  save() {
    this.hideModal();
    this.emitUpdateEvent();
  }

  cancel() {
    super.cancel();
    this.updateSelected();
    this.emitUpdateEvent();
  }

  reset() {
    this.allInputs((input) => (input.checked = false));
  }

  toggleAll() {
    const maxed = this.maxSelected();
    this.allInputs((input) => {
      if (input.disabled === false) {
        input.checked = !maxed;
      }
    });
    this.updateDom();
  }

  maxSelected() {
    let allEnabled = 0;
    let allChecked = 0;
    this.allInputs((input) => {
      if (input.value && input.disabled === false) {
        allEnabled += 1;
        if (input.checked === true) {
          allChecked += 1;
        }
      }
    });

    return allEnabled === allChecked;
  }

  adjustText() {
    if (!this.hasSelectAllTextTarget || !this.hasDeselectAllTextTarget) return;

    if (this.maxSelected()) {
      this.selectAllTextTarget.classList.add("d-none");
      this.deselectAllTextTarget.classList.remove("d-none");
    } else {
      this.selectAllTextTarget.classList.remove("d-none");
      this.deselectAllTextTarget.classList.add("d-none");
    }
  }

  updateDom() {
    this.updateSelected();
    this.adjustText();
  }

  emitUpdateEvent() {
    this.element.dispatchEvent(
      new CustomEvent("modal-collection:update", {
        bubbles: true,
        cancelable: true
      })
    );
  }
}
