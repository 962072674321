import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = [ "comment", "counter" ]
  static values = {
    max: Number
  }

  countCharacters(event) {
    let characters = this.commentTarget.value.length;
    this.counterTarget.innerText = this.maxValue - characters;
  }
}
