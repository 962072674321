import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import cableStore from "../../utils/cable_subscriber";

export default class extends Controller {
  static targets = ["status"];

  connect() {
    cableStore.subscribe(this);
  }

  disconnect() {
    cableStore.unsubscribe(this);
  }

  cableChannel() {
    return {
      channel: "IndeedSponsorStatusChannel",
      id: this.data.get("id")
    };
  }

  cableMessage({ msg, data }) {
    switch (msg) {
      case "Received":
        this._handleReceived(data);
    }
  }

  _handleReceived(data) {
    if (data == "updated") {
      window.location.reload();
    }
  }
}
