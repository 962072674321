import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "country",
    "state",
    "salary",
    "compensation",
    "compensationLabel",
    "description",
    "additionalState"
  ];
  static values = {
    currentStates: Array,
    requiredStates: Array,
    mainRequired: Boolean
  };

  connect() {
    this.currentStates = [];
    this.requiredStates = [];
  }

  requireSalary() {
    this.currentStates = []; // Reset when the primary dropdown gets changed
    this.requiredStates = [];
    this.mainRequiredValue = false;
    this.handleState(this.stateTarget.value, this.countryTarget.value, true);
  }

  handleSalaryRequirement(data) {
    if (data && !$(this.compensationTarget).hasClass("required")) {
      $(this.compensationTarget).removeClass("optional").addClass("required");
      $(this.compensationLabelTarget)
        .removeClass("optional")
        .addClass("required");
      $(this.compensationLabelTarget).append('<span title="required">*</span>');
      $(this.descriptionTarget).css("display", "inline");
    } else if (this.requiredStates.length == 0 && !this.mainRequiredValue) {
      $(this.compensationTarget).removeClass("required").addClass("optional");
      $(this.compensationLabelTarget)
        .removeClass("required")
        .addClass("optional");
      $(this.compensationLabelTarget).find("span").remove();
      $(this.descriptionTarget).css("display", "none");
    }
  }

  handleState(state, country, primary = false) {
    let url =
      "/manage/salary_transparencies?country=" + country + "&state=" + state;
    fetch(url, { headers: { accept: "application/json" } }).then((response) =>
      response.json().then((response) => {
        const code = response.state + "," + response.country;
        this.handleSalaryRequirement(response);
        if (response && primary) {
          this.mainRequiredValue = true;
        }
        if (response && this.requiredStates.includes(code)) return;
        if (response) {
          this.requiredStates.push(response.state + "," + response.country);
        }
      })
    );
  }

  handleAdditionalStates() {
    $(this.additionalStateTargets).each((element) => {
      let url =
        "/manage/accounts/" +
        $(element).data("account-id") +
        "/location/" +
        element.value;
      fetch(url, { headers: { accept: "application/json" } }).then((response) =>
        response.json().then((response) => {
          const code = response.state + "," + response.country;
          if (element.checked) {
            if (this.currentStates.includes(code)) {
              return;
            }
            this.currentStates.push(code);
          } else {
            this.currentStates = this.currentStates.filter(
              (item) => item != code
            );
            this.requiredStates = this.requiredStates.filter(
              (item) => item != code
            );
          }
          $(this.currentStates).each((item) => {
            var sc = item.split(",");
            this.handleState(sc[0], sc[1]);
          });
          if (this.requiredStates.length == 0) {
            this.handleSalaryRequirement([]);
          }
        })
      );
    });
  }
}
