import { Controller } from "@hotwired/stimulus";

/**
 * Redirects the page that contains this element. Can be used to:
 * - Redirect in response to a turbo-frame (or stream) update
 */
export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    window.location = this.urlValue;
  }
}
