import { Controller } from "@hotwired/stimulus";

let CP_VOYAGER_TOGGLE_SPINNER;

export default class extends Controller {
  initialize() {
    CP_VOYAGER_TOGGLE_SPINNER = this.toggle.bind(this);
  }

  toggle(args) {
    (this.element = args.element == undefined ? "body" : args.element),
      (this.toggleElement =
        args.toggleElement == undefined ? false : args.toggleElement),
      (this.title = args.title == undefined ? "" : args.title),
      (this.message = args.message == undefined ? "" : args.message),
      (this.timeout = args.timeout == undefined ? 0 : args.timeout);

    if (args.showOrHide == "show") {
      this.loading = true;
    } else {
      clearTimeout(this.loadingToggled);
      this.loading = false;
    }
  }

  get element() {
    return this.data.get("element");
  }

  set element(value) {
    return this.data.set("element", value);
  }

  get toggleElement() {
    return this.data.get("toggle-element") === "true";
  }

  set toggleElement(value) {
    return this.data.set("toggle-element", value);
  }

  get timeout() {
    return Number(this.data.get("timeout"));
  }

  set timeout(value) {
    return this.data.set("timeout", value);
  }

  get title() {
    return this.data.get("title");
  }

  set title(value) {
    return this.data.set("title", value);
  }

  get message() {
    return this.data.get("message");
  }

  set message(value) {
    return this.data.set("message", value);
  }

  get loading() {
    return this.data.get("loading");
  }

  get loadingToggled() {
    return this.data.get("loading-toggled");
  }

  set loadingToggled(value) {
    this.data.set("loading-toggled", value);
  }

  set loading(value) {
    const boolString = String(value),
      timeout = boolString == "true" ? this.timeout : 0;

    this.data.set("loading", boolString);
    clearTimeout(this.loadingToggled);
    this.loadingToggled = setTimeout(() => this.toggleLoading(), timeout);
  }

  toggleLoading() {
    if (this.loading === "true") {
      if (this.toggleElement) {
        let parent = $(this.element).parents(".layout__content");
        if (parent.length > 0) {
          parent.animate({ scrollTop: 0 }, 100);
          parent.css("overflow", "hidden");
        }
        $(this.element).show();
      }
      $(this.element).append(
        $(".voyager-loading-overlay").clone().addClass("d-flex")
      );
      $(this.element)
        .find('[data-loading-spinner-target="title"]')
        .text(this.title);
      $(this.element)
        .find('[data-loading-spinner-target="message"]')
        .text(this.message);
    } else {
      if (this.toggleElement) {
        $(this.element).hide();
        $(this.element).parents(".layout__content").css("overflow", "auto");
      }
      $(this.element).find(".voyager-loading-overlay.d-flex").remove();
    }
  }
}

export const showSpinner = function (args) {
  CP_VOYAGER_TOGGLE_SPINNER({ ...args, showOrHide: "show" });
};

export const hideSpinner = function (args) {
  CP_VOYAGER_TOGGLE_SPINNER({ ...args, showOrHide: "hide" });
};
