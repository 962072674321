import { Controller } from "@hotwired/stimulus";
import { ajaxResponse } from "../../utils/forms";

export default class extends Controller {
  handleJsonRedirect(event) {
    const json = JSON.parse(ajaxResponse(event));
    if (json.redirect) window.location = json.redirect;
  }

  handleRedirect(event) {
    const url = event.target.getAttribute("data-url");
    if (url) window.location = url;
  }
}
