import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { portal } from "./drawer_portal_controller";

export default class extends Controller {
  static targets = ["group"];

  showGroup(event) {
    if (event.target.value === "") {
      this.reset();
    } else {
      this.groupTargets.forEach((node) => {
        if (event.target.value === node.getAttribute("data-group-key")) {
          this.openGroup(node);
          setTimeout(() => {
            node.scrollIntoView();
          }, 200);
        } else {
          this.closeGroup(node);
        }
      });
    }
  }

  openGroup(node) {
    this.findContainer(node, (el) => {
      if (!el.classList.contains("show")) {
        $(el).collapse("show");
        this.prefetch(node);
      }
    });
  }

  closeGroup(node) {
    this.findContainer(node, (el) => {
      if (el.classList.contains("show")) {
        $(el).collapse("hide");
      }
    });
  }

  findContainer(node, callback) {
    const collapse = node.querySelector(".collapse");
    if (collapse != null) {
      callback(collapse);
    }
  }

  showGroups() {
    this.groupTargets.forEach((node) => {
      this.openGroup(node);
    });
  }

  reset() {
    for (var i = 0; i < this.groupTargets.length; i++) {
      const toggle = i == 0 ? "show" : "hide";
      this.findContainer(this.groupTargets[i], (el) => {
        $(el).collapse(toggle);
      });
    }
  }

  prefetch(node) {
    portal(node, this.application, (drawer) => {
      drawer.preFetch();
    });
  }
}
