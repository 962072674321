import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  // https://bootstrapious.com/p/bootstrap-multilevel-dropdown
  toggle(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!$(event.target).next().hasClass("show")) {
      $(event.target)
        .parents(".dropdown-menu")
        .first()
        .find(".show")
        .removeClass("show");
    }

    $(event.target).siblings().toggleClass("show");

    $(event.target)
      .parents(".dropdown.show")
      .on("hidden.bs.dropdown", function (_event) {
        $(".dropdown-submenu .show").removeClass("show");
      });
  }
}
