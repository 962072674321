// CREDIT: https://github.com/gorails-screencasts/dynamic-nested-forms-with-stimulusjs
//
// This example controller works with specially annotated HTML like:
//
//  <h4>Tasks</h4>
//  <div data-controller="nested-form">
//    <template data-nested-form-target="template">
//      <%= form.fields_for :tasks, Task.new, child_index: 'NEW_RECORD' do |task| %>
//        <%= render "task_fields", form: task %>
//      <% end %>
//    </template>
//
//    <%= form.fields_for :tasks do |task| %>
//      <%= render "task_fields", form: task %>
//    <% end %>
//
//    <div class="mb-3" data-nested-form-target="insert">
//      <%= link_to "Add Task", "#", class: "btn btn-outline-primary", data: { action: "click->nested-form#addAssociation" } %>
//    </div>
//  </div>
//
//  # _task_fields.html.erb
//  <%= content_tag :div, class: "nested-fields", data: { new_record: form.object.new_record? } do %>
//    <div class="form-group">
//      <%= form.label :description %>
//      <%= form.text_field :description, class: 'form-control' %>
//      <small><%= link_to "Remove", "#", data: { action: "click->nested-form#removeAssociation" } %></small>
//    </div>
//
//    <%= form.hidden_field :_destroy %>
//  <% end %>

import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { hideElement } from "../../utils/bootstrap";

export default class extends Controller {
  static targets = ["insert", "template", "positionedItem"];

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields";
  }

  insertTemplate(template) {
    const content = template.replace(/NEW_RECORD/g, new Date().getTime());
    this.insertTarget.insertAdjacentHTML("beforebegin", content);
    this.adjustItemPositions();
    this.notify("insert", content);
  }

  addAssociation(event) {
    event.preventDefault();

    this.insertTemplate(this.templateTarget.innerHTML);
  }

  addRemoteAssociation(event) {
    event.preventDefault();

    let target = event.target;
    if (!target.getAttribute("href")) target = target.closest("a");
    axios.get(target.getAttribute("href")).then(({ data }) => {
      this.insertTemplate(data);
    });
  }

  removeAssociation(event) {
    event.preventDefault();

    let wrapper = event.target.closest("." + this.wrapperClass);

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelectorAll("[name*='_destroy']").forEach((input) => {
        input.value = "true";
      });
      hideElement(wrapper);
    }
    this.notify("remove", wrapper);
  }

  discardAssociation(event) {
    event.preventDefault();
    let wrapper = event.target.closest("." + this.wrapperClass);
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();
    } else {
      wrapper.querySelectorAll("[name*='[mark_discard]']").forEach((input) => {
        input.value = "true";
      });
      hideElement(wrapper);
    }
    this.notify("remove", wrapper);
  }

  adjustItemPositions() {
    this.positionedItemTargets.forEach((el, pos) => {
      el.value = pos;
      el.dispatchEvent(new Event("change"));
    });
  }

  notify(eventType, detail) {
    const event = new CustomEvent(`nested-form:${eventType}`, {
      bubbles: true,
      cancelable: true,
      detail: detail
    });
    this.element.dispatchEvent(event);
  }
}
