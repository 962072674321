import Flatpickr from "stimulus-flatpickr";
export default class extends Flatpickr {
  // Addressing https://github.com/flatpickr/flatpickr/issues/1730
  open(_selectedDates, _dateStr, _instance) {
    const modal = this.modal();
    if (!modal) return;
    modal.removeAttribute("tabindex");
  }

  close(_selectedDates, _dateStr, _instance) {
    const modal = this.modal();
    if (!modal) return;
    modal.setAttribute("tabindex", -1);
  }

  modal() {
    return document.getElementById(this.modalId);
  }

  get modalId() {
    return this.data.get("modalId");
  }
}
