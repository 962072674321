export function hideElement(element) {
  for (let i = 0; i < element.classList.length; i++) {
    const item = element.classList.item(i);
    if (item.startsWith("d-") && item != "d-none") {
      if (!("originalDisplay" in element.dataset))
        element.dataset.originalDisplay = item;
      element.classList.remove(item);
    }
  }
  element.classList.add("d-none");
}

export function unhideElement(element) {
  let display = "d-block";
  if ("originalDisplay" in element.dataset)
    display = element.dataset.originalDisplay;
  element.classList.remove("d-none");
  element.classList.add(display);
}
