import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import elm from "assets/elm/elm.js";

export default class extends Controller {
  connect() {
    this.initializeSignatures();
  }

  initializeSignatures() {
    elm.Signature.embed(this.element, $(this.element).data());
  }
}

