import { Controller } from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {
  static targets = ["submitButton", "answers"]

  static values = {
    redirectPath: String,
    logKinesisEventPath: String,
    questionKey: String
  }

  getCheckboxes() {
    return Array.from(this.answersTarget.querySelectorAll('input[type="checkbox"]'));
  }

  answerClick() {
    this.logKinesisEvent("answer_clicked");
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    const checked = this.getCheckboxes().some(x => x.checked);
    if (checked) {
      this.submitButtonTarget.removeAttribute("disabled");
    }
    else {
      this.submitButtonTarget.setAttribute("disabled", "disabled");
    }
  }

  closeAndRedirect() {
    window.location.href = this.redirectPathValue;
    this.logKinesisEvent("closed");
  }

  logKinesisEvent(eventName) {
    const answerKeys = this.getCheckboxes().filter(x => x.checked).map(x => x.value);
    const questionKey = this.questionKeyValue;

    axios.post(this.logKinesisEventPathValue, {
      event_name: eventName,
      question_key: questionKey,
      answer_keys: answerKeys
    });
  }
}
