import { Controller } from "@hotwired/stimulus";
import cableStore from "../../utils/cable_subscriber";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "alert",
    "alertsContainer",
    "noMessages",
    "markReadContainer"
  ];

  connect() {
    cableStore.subscribe(this);
  }

  disconnect() {
    cableStore.unsubscribe(this);
  }

  cableChannel() {
    return {
      channel: "ApplicationAlertsChannel",
      user_id: this.userId
    };
  }

  cableMessage({ msg, data }) {
    switch (msg) {
      case "Received":
        this.handleReorder(data);
        this.removeNoMessages();
        this.showMarkReadContainer();
        // SetTimeout to ensure DOM updates have been completed
        setTimeout(() => {
          this.updateIcon();
        });
    }
  }

  removeNoMessages() {
    if (!this.hasNoMessagesTarget) return;
    this.noMessagesTarget.remove();
  }

  showMarkReadContainer() {
    this.markReadContainerTarget.classList.add('d-flex');
    this.markReadContainerTarget.hidden = false;
  }

  markAllAsRead() {
    this.alertTargets.forEach((el) => {
      const alert = JSON.parse(el.dataset.alert);
      if (alert.read_at === null) {
        this.markAsRead(alert.id);
      }
    });
  }

  markAsRead(id) {
    axios.patch(`/manage/application_alerts/${id}`);
  }

  handleReorder(data) {
    if (data.read_at != null) return;
    this.removeFromDom(data.id);
    this.render(data.markup);
  }

  updateIcon() {
    const unreadCount = this.alertTargets.reduce((acc, el) => {
      const alert = JSON.parse(el.dataset.alert);
      acc += alert.read_at === null ? 1 : 0;
      return acc;
    }, 0);

    if (unreadCount > 0) {
      this.toggleIcon("alert-center-unread-icon");
      this.toggleIcon("alert-center-read-icon", true);
    } else {
      this.toggleIcon("alert-center-unread-icon", true);
      this.toggleIcon("alert-center-read-icon");
    }
  }

  toggleIcon(id, add = false) {
    document.getElementById(id).classList[add ? "add" : "remove"]("d-none");
  }

  render(markup) {
    this.alertsContainerTarget.insertAdjacentHTML("afterbegin", markup);
  }

  removeFromDom(id) {
    this.alertTargets.map((el) => {
      if (Number(JSON.parse(el.dataset.alert).id) !== id) return;
      el.remove();
    });
  }

  get userId() {
    return Number(this.data.get("userId"));
  }
}
