import { createConsumer } from "@rails/actioncable";
import * as broadcast from "./broadcast.js";

/**
 * Maintains a single ActionCable connection that broadcasts to multiple subscribers.
 *
 * Usage:
 * Subscriber must implement two methods:
 *
 * cableChannel(): returns a hash describing the ActionCable channel to subscribe to.
 *   See `consumer.subscriptions.create` [here](https://guides.rubyonrails.org/action_cable_overview.html#client-side-components-connections)
 * cableMessage({ msg, data }): handle an outgoing message from the cable.
 *   msg will be one of: "Connected", "Disconnected", "Received", or "Rejected"
 *   data will be present when msg == "Received"
 *
 * On Stimulus `connect()`, call `subscribe(this)`
 * On Stimulus `disconnect()`, call `unsubscribe(this)`
 */
class CableStore {
  constructor() {
    if (!CableStore.instance) {
      this._cable = createConsumer();
      this._subscriptions = {};
      CableStore.instance = this;
    }

    return CableStore.instance;
  }

  subscribe(subscriber) {
    this._addSubscriber(subscriber);
  }

  unsubscribe(subscriber) {
    const key = this._key(subscriber.cableChannel());
    this._withSubscription(key, (subscription) => {
      const idx = this._findSubscriberIndex(subscription, subscriber);
      if (idx > -1) {
        subscription.subscribers[idx].broadcast.close();
        subscription.subscribers.splice(idx, 1);
      }
      if (subscription.subscribers.length < 1) {
        this._removeSubscription(key);
      }
    });
  }

  /**
   * Broadcast messages to all local subscribers without contacting the server
   *
   * @param subscriber Implements `cableChannel()` as described above
   * @param { msg, data } message A message that other subscribers would understand (i.e. same format as ActionCable message)
   */
  sendMessage(subscriber, message) {
    this._withSubscription(
      this._key(subscriber.cableChannel()),
      (subscription) => {
        subscription.broadcast.postMessage(message);
      }
    );
  }

  _findSubscriberIndex(subscription, subscriber) {
    for (let i = 0; i < subscription.subscribers.length; i++) {
      if (subscription.subscribers[i].subscriber === subscriber) {
        return i;
      }
    }
    return -1;
  }

  _key(channel) {
    return Object.keys(channel)
      .map((key) => {
        return channel[key];
      })
      .join(":");
  }

  _ensureSubscription(channel) {
    const key = this._key(channel);
    if (this._subscriptions.hasOwnProperty(key)) return;

    const bChan = broadcast.channel(key);
    const subscription = this._cable.subscriptions.create(channel, {
      connected: () => {
        bChan.postMessage({ msg: "Connected", data: null });
      },
      disconnected: () => {
        bChan.postMessage({ msg: "Disconnected", data: null });
      },
      received: (data) => {
        bChan.postMessage({ msg: "Received", data: data });
      },
      rejected: () => {
        bChan.postMessage({ msg: "Rejected", data: null });
      }
    });
    this._subscriptions[key] = {
      subscribers: [],
      cable: subscription,
      broadcast: bChan
    };
  }

  _withSubscription(key, callback) {
    const subscription = this._subscriptions[key];
    if (subscription) {
      callback(subscription);
    }
  }

  _addSubscriber(subscriber) {
    const channel = subscriber.cableChannel();
    this._ensureSubscription(channel);
    const key = this._key(channel);
    this._withSubscription(key, (subscription) => {
      const subChan = broadcast.channel(key);
      subChan.addEventListener(
        "message",
        subscriber.cableMessage.bind(subscriber)
      );
      subscription.subscribers.push({
        subscriber: subscriber,
        broadcast: subChan
      });
    });
  }

  _removeSubscription(key) {
    this._withSubscription(key, (subscription) => {
      subscription.broadcast.close();
      this._cable.subscriptions.remove(subscription.cable);
    });
    delete this._subscriptions[key];
  }
}

const instance = new CableStore();
Object.freeze(instance);

export default instance;
