import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selected"];

  connect() {
    this.data.set("original-selected", this.selectedTarget.innerHTML);
  }

  select(event) {
    this.selectedTarget.innerHTML = event.target.innerHTML;
    this.selectedTarget.classList.remove("dropdown-select--unselected");
  }

  reset() {
    this.selectedTarget.innerHTML = this.data.get("original-selected");
    this.selectedTarget.classList.add("dropdown-select--unselected");
  }
}
