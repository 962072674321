import { Controller } from "@hotwired/stimulus";

/**
 * This controller turns a text field input into a search field with automatic submission.
 *
 * @example <caption>Example using text_field_tag helper.</caption>
 *  <%= text_field_tag(
 *      :employee_search,
        {},
        value: params[:term],
        data: {
          action: 'keyup->auto-search#search',
          controller: 'auto-search',
          auto_search_url_value: retain_employees_path(@facade.filters),
          auto_search_param_value: 'term'
        }
      )
    %>
 */

export default class extends Controller {

  static values = {
    url: String,
    param: String,
    timeout: { type: Number, default: 500 }
  }

  /**
   * Handler for performing search. Updates/removes search param based on value
   * of field.
   *
   * If Turbo is defined on the window, use this to navigate to the new location
   * to get the benefits of caching.
   *
   * @param {Event} event object implementing the Event interface
  */

  search(event) {
    clearTimeout(this.searching);
    this.searching = setTimeout(() => this.goToUrl(event.target.value), this.timeoutValue);
  }

  // private

  goToUrl(value) {
    var url = new URL(this.urlValue)
    if (value) {
      url.searchParams.set(this.paramValue, value);
    } else {
      url.searchParams.delete(this.paramValue);
    }

    if (window.Turbo) {
      window.Turbo.visit(url.toString());
    } else {
      window.location = url.toString();
    }
  }
}
