import { Controller } from "@hotwired/stimulus";
import { portal } from "./drawer_portal_controller";
import $ from "jquery";

export default class extends Controller {
  static targets = [
    "accountHolderTypeField",
    "accountNumberField",
    "ach",
    "alert",
    "bankNameField",
    "confirmModal",
    "credit",
    "field",
    "monthField",
    "numberField",
    "paymentType",
    "routingNumberField",
    "token"
  ];

  connect() {
    this.chargify = new window.Chargify();
    this._observer = new MutationObserver((_mutations, _observer) => this.loadChargify());
    this._observer.observe(this.element, { subtree: true, childList: true });
    this.loadChargify();
    this.data.set("attempts", 0);
    this._readyToClose = false;
    portal(this.element, this.application, (drawer) => {
      drawer.element.addEventListener("drawer_portal:hide", this.handleHide);
    });
  }

  disconnect() {
    portal(this.element, this.application, (drawer) => {
      drawer.element.removeEventListener("drawer_portal:hide", this.handleHide);
    });
  }

  get activePaymentTarget() {
    if (this.creditTarget.classList.contains("d-none")) {
      return this.achTarget;
    } else {
      return this.creditTarget;
    }
  }

  get attempts() {
    return Number(this.data.get("attempts"));
  }

  loadChargify() {
    if (!this.hasCreditTarget) return;

    this._observer.disconnect();
    this.chargify.load(JSON.parse(this.activePaymentTarget.dataset.chargify));
  }

  logAttempt() {
    this.data.set("attempts", this.attempts + 1);
  }

  load(event) {
    if (this.hasPaymentTypeTarget) {
      this.paymentTypeTarget.value = event.target.value;
    }
    if (event.target.value == "ach") {
      this.handleLoad(this.achTarget, this.creditTarget);
    } else {
      this.handleLoad(this.creditTarget, this.achTarget);
    }
  }

  handleLoad(enabled, disabled) {
    this.chargify.load(JSON.parse(enabled.dataset.chargify));
    this.clearErrors();
    disabled.classList.add("d-none");
    enabled.classList.remove("d-none");
  }

  submit(event, callback) {
    if (this._readyToClose || !this.visuallyEnabled()) return;

    this.disableButtons();
    event.preventDefault();
    this.logAttempt();
    this.chargify.token(
      this.element,
      this.handleToken.bind(this, callback),
      this.handleError
    );
  }

  // Can the user interact with the ChargifyJS fields?
  visuallyEnabled() {
    return (this.hasCreditTarget && $(this.creditTarget).is(":visible")) ||
      (this.hasAchTarget && $(this.achTarget).is(":visible"));
  };

  handleToken = (callback, token) => {
    this._readyToClose = true;
    this.tokenTarget.value = token;
    if (typeof callback === "function") {
      callback();
    } else {
      const event = new CustomEvent("submit", {
        bubbles: true,
        cancelable: true
      });
      this.element.dispatchEvent(event);
    }
  };

  handleError = (error) => {
    console.log("ChargifyJS ERROR: ", error);
    this.enableButtons();
    this._readyToClose = false;
    if (this.attempts > 4) {
      portal(this.element, this.application, (drawer) => {
        drawer.hide();
      });
    } else {
      this.alertTarget.classList.remove("d-none");
      this.alertTarget.scrollIntoView();
      this.toggleError(error.invalidFields);
    }
  };

  toggleError(invalidFields) {
    const classList = this.activePaymentTarget.closest(
      ".chargify-payment-wrapper"
    ).classList;
    if (invalidFields) {
      classList.add(this.data.get("invalidClass"));
      classList.remove(this.data.get("validClass"));
      this.addFieldErrors(invalidFields);
    } else {
      classList.add(this.data.get("validClass"));
      classList.remove(this.data.get("invalidClass"));
      this.clearAllFieldErrors();
    }
  }

  clearErrors() {
    this.alertTarget.classList.add("d-none");
    this.toggleError(null);
  }

  handleHide = (event) => {
    if (this._readyToClose || !this.hasConfirmModalTarget) return;

    if (this.confirmModalTarget.classList.contains("show")) {
      this._readyToClose = true;
      $(this.confirmModalTarget).modal("hide");
      return;
    }

    event.preventDefault();
    $(this.confirmModalTarget).modal({ backdrop: false });
  };

  disableButtons() {
    this.withSingleSubmitController((singleSubmit) => singleSubmit.disable());
  }

  enableButtons() {
    this.withSingleSubmitController((singleSubmit) => singleSubmit.reset());
  }

  withSingleSubmitController(callback) {
    const result = this.application.getControllerForElementAndIdentifier(
      this.element,
      "single-submit"
    );
    if (result) callback(result);
  }

  addFieldErrors(invalidFields) {
    this.clearAllFieldErrors();
    for (const field of invalidFields) {
      const targetKey = this.fieldTargetKey(field);
      if (this["has" + targetKey.charAt(0).toUpperCase() + targetKey.slice(1)]) {
        const element = this[targetKey];
        const errorElement = document.createElement("div");
        errorElement.classList.add("invalid-feedback");
        errorElement.classList.add("d-block");
        errorElement.innerHTML = element.dataset.errorMessage;
        element.appendChild(errorElement);
        element.classList.add("form-group-invalid");
      }
    }
  }

  /**
   * We group month and year in the same wrapper, so year needs to flag as month.
   */
  fieldTargetKey(field) {
    if (field === "year") {
      return "monthFieldTarget";
    } else {
      return field + "FieldTarget"
    }
  }

  clearAllFieldErrors() {
    for (const field of this.fieldTargets) {
      field.classList.remove("form-group-invalid");
      const errorNotice = field.querySelector(".invalid-feedback");
      if (errorNotice) errorNotice.remove();
    }
  }
}
