import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["button", "container"];

  load() {
    const nextPage = this.current_page + 1;
    this.loading = true;
    let path = this.data.get("path");

    if (path.includes("?")) {
      path += "&";
    } else {
      path += "?";
    }

    axios
      .get(`${path}page=${nextPage}`)
      .then(({ data }) => {
        this.loading = false;
        this.containerTarget.insertAdjacentHTML("beforeend", data.html);
        this.current_page++;
        if (data.count === 0) {
          this.buttonTarget.style.display = "none";
        }
      })
      .catch((thrown) => {
        this.loading = false;
        // @TODO - what is the generic error handling behavior here?
      });
  }

  toggleButton() {
    const button = this.buttonTarget;
    if (this.loading === "true") {
      button.setAttribute("disabled", true);
      button.insertAdjacentHTML(
        "afterbegin",
        '<i class="fal fa-sync fa-spin"></i> '
      );
    } else {
      button.removeAttribute("disabled");
      button.innerHTML = "Load More";
    }
  }

  get current_page() {
    return parseInt(this.data.get("current-page"));
  }

  set current_page(value) {
    this.data.set("current-page", value);
  }

  get loading() {
    return this.data.get("loading");
  }

  set loading(value) {
    this.data.set("loading", String(value));
    this.toggleButton();
  }
}
