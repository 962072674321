import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "remoteContent"];

  connect() {
    this.insertPortal();
  }

  disconnect() {
    // On Turbo Drive pages we need to reset the drawer back to it's original state
    // before the Turbo Drive snapshot is taken. This way when the back/forward browser buttons
    // are used we can successfully connect this controller.
    this.contentTarget.innerHTML = this.contentCache;

    // When the controller for this drawer is removed from the dom, also
    // remove the drawer itself. This prevents multiple drawers being created
    // when content is replaced.
    let drawer = document.getElementById(this.data.get("portalId"));

    if (drawer !== null && this.portal !== null && this.portal.status == "closed") {
      drawer.remove();
    }
  }

  show() {
    this.portal.show();
  }

  preFetch() {
    this.portal.preFetch();
  }

  hide() {
    this.portal.hide();
  }

  insertPortal() {
    const portalId = this.setPortalId();

    if (document.getElementById(portalId)) return;

    const template = document
      .getElementById("drawer-portal-template")
      .innerHTML.replace(/%PORTAL_ID%/, portalId)
      .replace(/%PORTAL_REMOTE_PATH%/, this.data.get("remotePath"))
      .replace(/%PORTAL_REFETCH%/, this.data.get("refetch"))
      .replace(/%PORTAL_DIRECTION%/, this.data.get("direction"))
      .replace(/%PORTAL_SERIALIZE%/, this.data.get("serialize"))
      .replace(
        "<portal_content></portal_content>",
        this.contentTarget.innerHTML
      );

    this.cleanUp();

    document
      .getElementById("drawer-portal-container")
      .insertAdjacentHTML("beforeend", template);
  }

  cleanUp() {
    this.contentCache = this.contentTarget.innerHTML;
    this.contentTarget.innerHTML = "";
  }

  setPortalId() {
    let portalId;

    if (this.data.get("drawerId")) {
      portalId = this.data.get("drawerId");
    } else {
      portalId = `drawer-portal-${Math.round(Math.random() * 10000000)}`;

      // If by some chance our Math.random call generates a duplicate ID.
      while (document.getElementById(portalId)) {
        portalId = `drawer-portal-${Math.round(Math.random() * 10000000)}`;
      }
    }

    this.data.set("portalId", portalId);
    return portalId;
  }

  get portal() {
    if (this.portalController) {
      return this.portalController;
    }

    return (this.portalController = this.application.getControllerForElementAndIdentifier(
      document.getElementById(this.data.get("portalId")),
      "drawer-portal"
    ));
  }
}
