import { Controller } from "@hotwired/stimulus";
import broadcast, { ensureChannel } from "../../utils/broadcast";
import axios from "axios";

export default class extends Controller {
  connect() {
    ensureChannel(this.data.get("channel"));
    this.channel = broadcast[`${this.data.get("channel")}Channel`]();
    this.channel.addEventListener("message", this.handleMessage);
  }

  disconnect() {
    this.channel.removeEventListener("message", this.handleMessage);
    this.channel.close();
  }

  handleMessage = (message) => {
    if (this.matchesId(message) && this.matchesPayload(message)) {
      axios.get(this.data.get("path")).then(({ data }) => {
        this.element.outerHTML = data;
      });
    }
  };

  get id() {
    if (this.data.has("id")) {
      if (this.data.get("id") == "any") return /\d+/;
      return this.data.get("id");
    }

    return "index";
  }

  matchesId(message) {
    if (message.id == null) return true;
    return message.id.match(this.id);
  }

  matchesPayload(message) {
    if (this.data.has("payload")) {
      if (this.data.get("payload") != message.payload) return false;
    }
    return true;
  }
}
