import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import { scrollIntoViewIfNeeded } from "../../utils/scrolling";
import elm from "assets/elm/elm.js";

const MINIMUM_FONT_SIZE = 6;
export default class extends Controller {
  static targets = ["answer", "highlight", "input", "question"];
  saveDraftInterval = null;

  connect() {
    this.initializeSignatures();
    this.questionTargets.forEach((question) =>
      this.shrinkQuestionToFit(question)
    );

    this.insertBreaksAtEndOfEachPage();

    // Set up auto-save every minute
    this.saveDraftInterval = setInterval(() => {
      this.saveDraft();
    }, 60 * 1000);
  }

  disconnect() {
    clearInterval(this.saveDraftInterval);
  }

  insertBreaksAtEndOfEachPage() {
    // Map page numbers to their last input elements
    const lastInputsOfPages = {};

    this.inputTargets.forEach((input) => {
      const currentPage = parseInt(input.dataset.page);
      if (
        !lastInputsOfPages[currentPage] ||
        parseInt(lastInputsOfPages[currentPage].dataset.position) <
          parseInt(input.dataset.position)
      ) {
        lastInputsOfPages[currentPage] = input;
      }
    });

    // Insert breaks after the last input on each page except the final page
    const pages = Object.keys(lastInputsOfPages).map((page) => parseInt(page));
    const maxPage = Math.max(...pages);

    pages.forEach((page) => {
      if (page !== maxPage) {
        const input = lastInputsOfPages[page];

        const endOfPageDiv = document.createElement("div");
        endOfPageDiv.classList.add("end-of-page");
        input.insertAdjacentElement("afterend", endOfPageDiv);
      }
    });
  }

  /**
   * Normally, remote-form would handle all form submissions, but in this case
   * I do not want to handle "failure" or "success"-- I just want to push the
   * current state to the server.
   */
  saveDraft() {
    const form = $("form[id^='edit_task_assignment']");

    this.updateLastSaveMessage("<em>Saving...</em>");
    axios
      .patch(`${form.attr("action")}.json`, form.serialize())
      .then((response) => {
        this.handleDraftResponse(response.data);
        this.onLastSave();
      });
  }

  handleDraftResponse(data) {
    if (data.visibility) this.adjustInputVisibility(data.visibility);
    if (data.select_options) this.adjustSelectOptions(data.select_options);
    this.withFormController((form) => {
      form.filterDisabledInputs();
    });
  }

  adjustInputVisibility(questions) {
    for (const questionId in questions) {
      const element = this.findInput(questionId);
      if (element) {
        if (questions[questionId] == "show") {
          element.classList.remove("d-none");
        } else {
          element.classList.add("d-none");
        }
      }
    }
  }

  adjustSelectOptions(questions) {
    for (const questionId in questions) {
      const element = this.findInput(questionId);
      if (element) {
        const select = element.querySelector("select");
        if (select) {
          const selected = select.options[select.selectedIndex].value;
          select.options.length = 0;
          select.options[0] = new Option("", "");
          questions[questionId].forEach((option, index) => {
            select.options[index + 1] = new Option(
              option.label,
              option.value,
              option.value == selected,
              option.value == selected
            );
          });
        }
      }
    }
  }

  onLastSave = () => {
    let message = null;
    if (this.mobile) {
      message =
        '<span class="font-size-4 align-middle cloud-check-outline"></span>';
    } else {
      message = `Last autosaved: ${moment().format("h:mm A")}`;
    }
    this.updateLastSaveMessage(message);
  };

  updateLastSaveMessage(message) {
    // can not use a target because it is outside of this controller
    const element = document.getElementById("auto_save_details");
    element.innerHTML = message;
  }

  highlightQuestion(event) {
    const question = this.findQuestionFromInput(event.target);
    if (!question) return;

    this.highlightSpecificQuestion(question);
  }

  highlightSpecificQuestion(question) {
    const pageCount = this.element.querySelectorAll(".pdf_form_taker__canvas")
      .length;
    this.highlightTarget.style.left = question.style.left;
    this.highlightTarget.style.width = question.style.width;
    this.highlightTarget.style.height = `${
      parseFloat(question.style.height) / pageCount
    }%`;
    const percentTop =
      (100.0 / pageCount) * (parseInt(question.dataset.page) - 1) +
      parseFloat(question.style.top) / pageCount;
    // mobileTop is the top position of the question in the mobile view
    // it is calculated by the percentage of the page the question is on
    // 76 is a magic number that averages the calculation of the top position
    // and using px because the % value was different depending on browser
    const mobileTop =
      (100.0 / pageCount) * (parseInt(question.dataset.page) - 1) +
      parseFloat(question.style.top) * (document.body.scrollWidth / 76);
    this.highlightTarget.style.top = this.mobile
      ? `${mobileTop}px`
      : `${percentTop}%`;
    const scrollEvent = new CustomEvent("pdf_form_taker:highlight", {
      cancelable: true,
      detail: question
    });
    if (this.element.dispatchEvent(scrollEvent)) {
      scrollIntoViewIfNeeded(question, {
        behavior: "smooth"
      });
    }
  }

  updateQuestionValue(event) {
    const question = this.findQuestionFromInput(event.target);
    if (!question) return;

    const answer = this.findAnswer(event.target.value);
    if (answer) {
      this.selectAnswer(question, answer);
    } else if (event.target.type == "checkbox") {
      this.selectCheckbox(question, event.target.checked, event.target);
    } else {
      this.setQuestionValue(question, event.target);
    }
  }

  selectAnswer(question, answer) {
    this.answersForQuestion(question).forEach((element) => {
      element.classList.remove("pdf_form_taker__answer--selected");
    });
    answer.classList.add("pdf_form_taker__answer--selected");
  }

  selectCheckbox(question, checked, triggeringCheckbox) {
    let fieldset = triggeringCheckbox.closest("fieldset");

    question.classList.toggle("pdf_form_taker__answer--selected", checked);

    if (!fieldset) return;

    switch (fieldset.dataset.internal) {
      case "show_supplement_a":
        this.handleSupplementAInputs(checked);
        break;
      case "show_supplement_b":
        this.handleSupplementBInputs(checked);
        break;
    }
  }

  handleSupplementAInputs(checked) {
    this.handleInputsState(this.getPreparerInputs(), checked);
  }

  handleSupplementBInputs(checked) {
    this.handleInputsState(this.getRehireInputs(), checked);
  }

  handleInputsState(inputs, checked) {
    inputs.forEach((input) => {
      input.classList.toggle("d-none", !checked);
    });
  }

  setQuestionValue(question, input) {
    const element = question.querySelector("text");
    if (input.localName == "select") {
      element.innerHTML = this.textSpans(element, this.getSelectValue(input));
    } else {
      element.innerHTML = this.textSpans(
        element,
        input.value,
        this.shouldNotShrink(question)
      );
    }
    this.shrinkQuestionToFit(question);
  }

  getSelectValue(input) {
    return input.options[input.selectedIndex].text;
  }

  textSpans(element, value, wrap) {
    let wrappedValue = value;
    if (wrap) wrappedValue = this.wordWrap(value, this.lineLength(element));
    const attrs = this.tspanAttributes(element);
    const splitLines = wrappedValue.split("\n");
    if (splitLines.length > 1) {
      return splitLines
        .map(
          (line, index) =>
            `<tspan ${attrs} dy="${index == 0 ? "0" : "1.2em"}">${line}</tspan>`
        )
        .join("");
    } else {
      return `<tspan ${attrs} dy="1.2em">${splitLines[0]}</tspan>`;
    }
  }

  shouldNotShrink(question) {
    return !question.classList.contains("pdf_form_taker__question--shrink");
  }

  shrinkQuestionToFit(question) {
    if (this.shouldNotShrink(question)) return;

    const element = question.querySelector("text");
    if (!element) return; // non-text question

    const valueElement = element.querySelector("tspan");
    if (!valueElement) return; // empty text question

    const fontSize = parseInt(question.dataset.baseFontSize);
    const value = valueElement.innerHTML,
      optimalLength = this.lineLength(element, fontSize),
      fontFraction = optimalLength / value.length;
    if (fontFraction < 1) {
      const newFontSize = Math.round(fontFraction * fontSize),
        newFontPixels = Math.max(MINIMUM_FONT_SIZE, newFontSize);
      element.setAttribute("font-size", newFontPixels);
    } else {
      element.setAttribute("font-size", fontSize);
    }
  }

  // credit: https://stackoverflow.com/questions/14484787/wrap-text-in-javascript
  wordWrap(text, line_width) {
    return text.replace(
      new RegExp(
        `(?![^\\n]{1,${line_width}}$)([^\\n]{1,${line_width}})\\s`,
        "g"
      ),
      "$1\n"
    );
  }

  lineLength(element, fontSize) {
    if (!fontSize) fontSize = parseInt(element.getAttribute("font-size"));
    const width = parseInt(element.getAttribute("inline-size")),
      letterSpacing = parseInt(element.getAttribute("letter-spacing")),
      characterWidth = fontSize * 0.5 + letterSpacing;
    return Math.round(width / characterWidth);
  }

  tspanAttributes(element) {
    const width = parseInt(element.getAttribute("inline-size"));
    let attrs = { x: 0 };
    if (element.dataset.align == "center") {
      attrs["x"] = Math.round(width / 2);
      attrs["text-anchor"] = "middle";
    } else if (element.dataset.align == "right") {
      attrs["x"] = width;
      attrs["text-anchor"] = "end";
    }
    return Object.keys(attrs)
      .map((key) => `${key}=${attrs[key]}`)
      .join(" ");
  }

  getFormattedValue(input) {
    return input.value.replaceAll("\n", "<br>");
  }

  // because of custom inputs such as datepickr, we can not target inputs directly
  // therefore, the stimulus target is the input-wrapper
  findQuestionFromInput(element) {
    const wrapper = element.closest("[data-question]");
    if (!wrapper) return null;

    return this.findQuestion(wrapper.dataset.question);
  }

  findQuestion(questionId) {
    return this.questionTargets.find((target) => {
      return target.dataset.question == questionId;
    });
  }

  findAnswer(answerId) {
    return this.answerTargets.find((target) => {
      return target.dataset.answer == answerId;
    });
  }

  answersForQuestion(question) {
    return this.answerTargets.filter((element) => {
      return element.dataset.question == question.dataset.question;
    });
  }

  findInput(questionId) {
    return this.inputTargets.find((target) => {
      return target.dataset.question == questionId;
    });
  }

  initializeSignatures() {
    this.inputTargets.forEach((input) => {
      const question = this.findQuestionFromInput(input);
      const signatureInput = input.querySelector(".signature_pad_app");
      if (signatureInput && question) {
        const inputApp = elm.Signature.embed(
          signatureInput,
          $(signatureInput).data()
        );
        $(question).data("signature", $(signatureInput).data("signature"));
        const viewApp = elm.Signature.embed(question, $(question).data());
        inputApp.ports.valueUpdated.subscribe((value) =>
          viewApp.ports.updateValue.send(value)
        );
      }
    });
  }

  get mobile() {
    return this.element.classList.contains("pdf_form_taker--mobile");
  }

  withFormController(callback) {
    const formElement = this.element.querySelector(
      "[data-controller~='pdf-form-taker-form']"
    );
    const form = this.application.getControllerForElementAndIdentifier(
      formElement,
      "pdf-form-taker-form"
    );
    if (form) callback(form);
  }

  getPreparerInputs() {
    return this.inputTargets.filter((input) => {
      const internalValue = input.dataset.internal || ""; // Get the 'internal' data attribute value
      // "preparer" is injected in all internal use fields that deal with preparer/translator
      return internalValue.includes("preparer");
    });
  }

  getRehireInputs() {
    return this.inputTargets.filter((input) => {
      const internalValue = input.dataset.internal || ""; // Get the 'internal' data attribute value
      // "rehire" is injected in all internal use fields that deal with rehire
      return internalValue.includes("rehire");
    });
  }
}
