import DeactivateDrawerController from "./deactivate_drawer_controller";
import { showToast, toastDanger } from "../manage/toasts_controller";

export default class extends DeactivateDrawerController {
  static targets = ["form"];

  handleFormSuccess(evt) {
    this.drawerPortalController.hide();
    if (this.duplicateAppsController) {
      this.duplicateAppsController.bulkRejectionSuccess();
    }
    evt.detail[0].forEach((toast) => {
      showToast(toast);
    });
  }

  handleFormError(evt) {
    let [response, , xhr] = evt.detail;

    if (xhr.status >= 500) {
      toastDanger({ title: "Something went wrong", body: "Please try again." });
      return;
    }

    this.formTarget.outerHTML = response;
  }

  get duplicateAppsController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById(`duplicate-details__accordion`),
      "duplicate-apps"
    );
  }
}
