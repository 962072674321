import { Controller } from "@hotwired/stimulus";
import cableStore from "../../utils/cable_subscriber";
import $ from "jquery";

export const success = "SUCCESS";
export const info = "INFO";
export const danger = "DANGER";
export const warning = "WARNING";

let CP_VOYAGER_SHOW_TOAST;

export default class extends Controller {
  static targets = ["container", "template"];
  static values = {
    user: Number
  }

  initialize() {
    CP_VOYAGER_SHOW_TOAST = this.show.bind(this);
  }

  connect() {
    cableStore.subscribe(this);
  }

  disconnect() {
    cableStore.unsubscribe(this);
  }

  cableChannel() {
    return {
      channel: "ToastsChannel",
      user_id: this.userValue
    };
  }

  cableMessage({ msg, data }) {
    switch (msg) {
      case "Received":
        showToast(data);
    }
  }

  show({ title, body, state = info, autohide = true, delay = 3000 }) {
    const toast = $(this.templateTarget.innerHTML);
    toast.find(".toast-header-content").html(title);
    toast.find(".toast-body").html(body);
    toast.addClass(this.stateClass(state));

    $(this.containerTarget).append(toast);
    this.scheduleCleanup(toast);
    toast.toast({ autohide, delay });
    toast.toast("show");
  }

  hide() {
    $(event.currentTarget).closest(".toast").toast("hide");
  }

  scheduleCleanup(toast) {
    toast.on("hidden.bs.toast", () => {
      setTimeout(() => {
        toast.remove();
      }, 1000);
    });
  }

  stateClass(state) {
    switch (state) {
      case success:
        return "toast--success";
      case info:
        return "toast--info";
      case warning:
        return "toast--warning";
      case danger:
        return "toast--danger";
      default:
        return "";
    }
  }
}

export const showToast = function (args, attempt = 0) {
  if (attempt >= 5) {
    throw "Cannot resolve function `CP_VOYAGER_SHOW_TOAST` in toasts_controller#showToast";
  }

  return setTimeout(() => {
    if (CP_VOYAGER_SHOW_TOAST === undefined) {
      return showToast(args, (attempt += 1));
    }

    return CP_VOYAGER_SHOW_TOAST(args);
  }, attempt * 250);
};

export const toastSuccess = function (args = {}) {
  showToast({ ...args, state: success });
};

export const toastInfo = function (args = {}) {
  showToast({ ...args, state: info });
};

export const toastWarning = function (args = {}) {
  showToast({ ...args, state: warning });
};

export const toastDanger = function (args = {}) {
  showToast({ ...args, state: danger });
};
