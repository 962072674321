import { Controller } from "@hotwired/stimulus";
import AutoNumeric from "autonumeric";

export default class extends Controller {
  static targets = [
    "editButton",
    "maximumFieldSection",
    "minimumFieldInput",
    "maximumFieldInput",
    "minimumFieldInputLabel"
  ];
  static values = { hiddenMin: String, hiddenMax: String };

  connect() {
    if (this.hasMinimumFieldInputTarget) {
      this.processMinimum();
    }
    if (this.hasMaximumFieldInputTarget) {
      this.processMaximum();
    }
  }

  processMinimum() {
    const parsedMinimum = parseFloat(
      this.minimumFieldInputTarget.value / 100
    ).toFixed(2);

    this.minField = new AutoNumeric(this.minimumFieldInputTarget, [
      "floatPos",
      { allowDecimalPadding: true, decimalPlacesShownOnBlur: 2 }
    ]);

    if (parsedMinimum > 0) {
      this.minField.set(parsedMinimum);
      this.hiddenMinValue = parsedMinimum
    }
  }

  processMaximum() {
    const parsedMaximum = parseFloat(
      this.maximumFieldInputTarget.value / 100
    ).toFixed(2);

    this.maxField = new AutoNumeric(this.maximumFieldInputTarget, [
      "floatPos",
      { allowDecimalPadding: true, decimalPlacesShownOnBlur: 2 }
    ]);

    if (parsedMaximum > 0) {
      this.maxField.set(parsedMaximum);
      this.hiddenMaxValue = parsedMaximum
    }
  }

  updateFields(e) {
    e.preventDefault();
    if (e.target.value == "fixed") {
      this.maxField.set("");
      this.minimumFieldInputLabelTarget.innerHTML = "Amount ($)";
      this.maximumFieldSectionTarget.classList.add("d-none");
    } else {
      this.maximumFieldSectionTarget.classList.remove("d-none");
      this.minimumFieldInputLabelTarget.innerHTML = "Min ($)";
    }
  }

  preventSubmit(e) {
    if (e.keyCode == "13") {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  updateHidden(e) {
    const fieldValue = e.target.value;

    if (e.target === this.minimumFieldInputTarget) {
      this.hiddenMinValue = fieldValue
    } else {
      this.hiddenMaxValue = fieldValue
    }
  }

  updateFieldValues(e) {
    if (this.minField) this.minField.set(this.hiddenMinValue);
    if (this.maxField) this.maxField.set(this.hiddenMaxValue);
  }

  hideEditButton() {
    $(this.editButtonTarget).hide();
  }
}
