import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleLink", "sideFilters"];

  connect() {
    this.titleShow = this.data.get("title-show");
    this.titleHide = this.data.get("title-hide");
    this.controllerEl = this.toggleLinkTarget.parentElement;
  }

  toggleFilterSideBar() {
    let target = event.currentTarget;
    $(this.controllerEl).removeClass("closed");
    this.sideFiltersTarget.classList.contains("show")
      ? this.hideFilterSideBar(target)
      : this.showFilterSideBar(target);
  }

  hideFilterSideBar(target) {
    $(this.sideFiltersTarget).collapse("hide");
    target.setAttribute("aria-expanded", "false");
    target.setAttribute("data-original-title", this.titleShow);
    $(this.controllerEl).addClass("closed");
  }

  showFilterSideBar(target) {
    $(this.sideFiltersTarget).collapse("show");
    target.setAttribute("aria-expanded", "true");
    target.setAttribute("data-original-title", this.titleHide);
  }
}
