import { Controller } from "@hotwired/stimulus";
import { toastDanger } from "../manage/toasts_controller";
import { smartSuggestionsChecker } from "../manage/jobs/smart_suggestions_controller";

export default class extends Controller {
  static targets = [
    "newBenefitInput",
    "benefit",
    "customBenefitField",
    "customBenefit",
    "default"
  ];
  static values = {
    toast: String
  };

  /**
   * Stimulus hook, initialize properties, add event listener
   */
  connect() {
    this.usedNums = [];
    this.filterEnterKeyListener = null;
    this.toastDangerEventListener = null;

    this._setCustomCount();
    this._filterEnterKey();
    this._setDefaultNames();
  }

  /**
   * Stimulus hook, cleanup form event listener
   */
  disconnect() {
    this._cleanupFilterEnterKey();
    this._cleanupToastDangerListener();
  }

  /**
   * Appends new custom benefit to Benefits component
   * Saves an id in this format: "benefit[new<integer>]" which lets the backend know it's new
   */
  addCustomBenefit() {
    const name = this.newBenefitInputTarget.value.trim(),
        loweredName = name.toLowerCase();

    if (name.length === 0) return;

    if (this.defaultNames.includes(loweredName)) {
      this._selectDefault(loweredName);
    } else {
      this._addCustomNode(name)
    }

    this.newBenefitInputTarget.value = "";
    this.dispatch('change');
  }

  /**
   * Removes the element from the DOM
   *
   * @param event {PointerEvent}
   */
  removeCustomBenefit(event) {
    const node = event.target.closest("div");
    this.dispatch;
    node.remove();

    this._decrementCustomCount();
    this.dispatch('change');
  }

  /**
   * Finds the default benefit for the custom input and selects it
   *
   * @param name {String}
   *
   * @private
   */
  _selectDefault(name) {
    const element = this.defaultTargets.find(element => {
      return element.dataset.benefitsNameValue === name;
    });

    element.querySelector('label').click();
  }

  /**
   * Clones a BenefitComponent and modifies properties
   * @see app/components/elements/badge_input_component.html.erb
   *
   * @param name {String}
   * @returns {Node}
   *
   * @private
   */
  _customBenefitNode(name) {
    let node = this.benefitTargets[this.benefitTargets.length - 1].cloneNode(
      true
    );
    const newId = `benefit[new${this._getIdNum()}]`;

    const i = document.createElement("i");
    const label = node.querySelector("label");
    const input = node.querySelector("input");
    const span = node.querySelector("span");

    node.classList.add("custom");

    i.classList.add("fas");
    i.classList.add("fa-times");
    i.dataset.action = "click->benefits#removeCustomBenefit";

    label.setAttribute("for", newId);

    input.id = newId;
    input.name = newId;
    input.setAttribute("checked", true);
    input.value = name;
    input.setAttribute("onclick", "event.preventDefault()");

    span.innerText = name;
    span.appendChild(i);

    return node;
  }

  /**
   * Used to get a unique id, recursively calls in the very rare scenario that there's a duplicate id
   * @returns {number|*}
   *
   * @private
   */
  _getIdNum() {
    let num = Math.floor(Math.random() * 100);
    if (!this.usedNums.includes(num)) {
      this.usedNums = [...this.usedNums, num];
      return num;
    }

    return this._getIdNum();
  }

  /**
   * Adds event listener to the form, to ignore enter key submission on form for
   *      only the newBenefitTarget input, assigns it to property to cleanup after
   * @private
   */
  _filterEnterKey() {
    const form = this.element.closest("form");
    this.filterEnterKeyListener = form.addEventListener("keypress", (e) => {
      if (e.key === "Enter" && e.target === this.newBenefitInputTarget) {
        e.preventDefault();
        this.addCustomBenefit();
      }
    });
  }

  /**
   * Cleans up filterEnterKey event listener to not pollute the DOM
   * @private
   */
  _cleanupFilterEnterKey() {
    const form = this.element.closest("form");
    form.removeEventListener("keypress", this.filterEnterKeyListener);
  }

  /**
   * Sets defaultNames array, to check for matching values
   * @private
   */
  _setDefaultNames() {
    this.defaultNames = this.defaultTargets.map(element => element.dataset.benefitsNameValue)
  }

  /**
   * Sets initial customCount property from length of existing benefits on load
   * @private
   */
  _setCustomCount() {
    this.customCount = this.customBenefitTargets.length;
    if (this.customCount >= 10) {
      this._disableInput();
    }
  }

  /**
   * Increments customCount property and disabled input if greater than 10
   * @private
   */
  _incrementCustomCount() {
    if (++this.customCount >= 10) {
      this._disableInput();
    }
  }

  /**
   * Decrements customCount property and enables input
   * @private
   */
  _decrementCustomCount() {
    --this.customCount;
    this.newBenefitInputTarget.removeAttribute("disabled");
    this.customBenefitFieldTarget.classList.remove("disabled");
    this.customBenefitFieldTarget
      .querySelector("button")
      .removeAttribute("disabled");
    this._cleanupToastDangerListener();
  }

  /**
   * Handles appending custom node for creating new custom benefit from input
   *
   * @param name {String}
   *
   * @private
   */
  _addCustomNode(name) {
    const node = this._customBenefitNode(name);
    this.customBenefitFieldTarget.insertAdjacentElement("afterend", node);
    this._incrementCustomCount();
  }

  /**
   * Sets up event listener to open toastDanger on clicking deactivated input
   * @private
   */
  _setupToastDangerListener() {
    this.toastDangerEventListener = this.customBenefitFieldTarget.addEventListener(
      "click",
      () => {
        toastDanger({ title: this.toastValue });
      }
    );
  }

  /**
   * Cleans up event listener when input is reactivated
   * @private
   */
  _cleanupToastDangerListener() {
    if (this.toastDangerEventListener !== null) {
      this.customBenefitFieldTarget.removeEventListener(
        "click",
        this.toastDangerEventListener
      );
      this.toastDangerEventListener = null;
    }
  }

  /**
   * Disables input and sets up dangerToast event listener
   * @private
   */
  _disableInput() {
    this.newBenefitInputTarget.setAttribute("disabled", true);
    this.customBenefitFieldTarget.classList.add("disabled");
    this.customBenefitFieldTarget
      .querySelector("button")
      .setAttribute("disabled", true);
    this._setupToastDangerListener();
  }
}
