import { Controller } from "@hotwired/stimulus";

/***
 * 
 * A Stimulus controller to toggle a class on one or multiple items to show or hide them.
 *
 * @see Stimulus-Components documentation: https://www.stimulus-components.com/docs/stimulus-reveal-controller/
 *
 * @example <caption>Toggle</caption>
 *
 * <div data-controller="reveal" data-reveal-hidden-class="d-none">
 *  <button data-action="click->reveal#toggle" type="button" class="btn">Toggle me!</button>
 *  <p data-reveal-target="item" class="d-none mt-4">Hey!</p>
 *  <p data-reveal-target="item" class="d-none mt-4">You can have multiple items</p>
 * </div>
 *
 * @example <caption>Show</caption>
 * 
 * <div data-controller="reveal">
 *  <button data-action="click->reveal#show" type="button" class="btn">Show me!</button>
 *  <p data-reveal-target="item" class="hidden mt-4">Hey!</p>
 * </div>
 *
 * @example <caption>Hide</caption>
 * 
 * <div data-controller="reveal">
 *  <button data-action="click->reveal#hide" type="button" class="btn">Hide me!</button>
 *  <p data-reveal-target="item" class="mt-4">Hey!</p>
 * </div>
 *
 ***/

export default class extends Controller {
  static targets = ['item']
  static classes = ['hidden']

  connect () {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'd-none'
  }

  toggle () {
    this.itemTargets.forEach(item => {
      item.classList.toggle(this.class)
    })
  }

  show () {
    this.itemTargets.forEach(item => {
      item.classList.remove(this.class)
    })
  }

  hide () {
    this.itemTargets.forEach(item => {
      item.classList.add(this.class)
    })
  }
}

