// Resets form error messages and removes the error classes.
// Add the controller to the form you want to reset and trigger the reset
// function via a stimulus action.

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  reset() {
    this.resetGroups();
    this.resetInputs();
    this.resetMessages();
  }

  resetGroups() {
    const groups = this.element.querySelectorAll(
      ".form-group.form-group-invalid"
    );

    groups.forEach(function (el) {
      el.classList.remove("form-group-invalid");
    });
  }

  resetInputs() {
    const inputs = this.element.querySelectorAll(".form-control.is-invalid");

    inputs.forEach(function (el) {
      el.classList.remove("is-invalid");
    });
  }

  resetMessages() {
    this.element.querySelectorAll(".invalid-feedback").forEach(function (el) {
      el.remove();
    });
  }
}
