import { Controller } from "@hotwired/stimulus";

// Handle use case where - by default, you'd like none of a set of radio buttons selected.
export default class extends Controller {
  static targets = ["disableRadioOption"];

  connect() {
    this.disableRadioOptionTargets.forEach((target) => {
      target.checked = false
    })
  }
}
