import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import debounce from 'lodash.debounce';  

export default class extends Controller {
  static targets = [
    'suggestionsContainer',
    'suggestionsHolder',
    'desktopVersion',
    'desktopProgressWrapper',
    'mobileVersion',
    'mobileProgressWrapper',
    'mobileButtonWrapper',
    'mobileButton',
    'progressBar',
    'suggestion',
    'ssField',
    'ssRangeField',
    'ssSelectionField',
    'ssTrixEditorField'
  ];

  static values = {
    jobId: String,
    suggestions: Array,
    currentSuggestions: String
  };

  connect() {
    document.addEventListener("wheel", this._adjustContainerPosition.bind(this));
    if (!this.hasSuggestionsContainerTarget) this.disconnect();

    this._checkExisting();
    this.checkAllFields();

    // FIXME: this can be further optimized by attaching the checkAllFields action
    //  to the specific elements in the partials. Outside of the scope of this refactor.
    document.querySelector('form').addEventListener('change', async event => {
      if (event.target.nodeName === 'INPUT' || event.target.nodeName === 'SELECT') {
        await this.checkAllFields();
      }
    });

    if (this.hasSsTrixEditorFieldTarget) {
      this.trixDebounce = debounce(this.checkAllFields.bind(this), 500);
      this.ssTrixEditorFieldTargets.forEach(target => {
        target.addEventListener("trix-change", this.trixDebounce);
      });
    }
  }

  disconnect() {
    document.removeEventListener("wheel", this._adjustContainerPosition);
    if (this.hasSsTrixEditorFieldTarget) {
      this.ssTrixEditorFieldTargets.forEach(target => {
        target.removeEventListener("trix-change", this.trixDebounce);
      });
    }
  }

  get currentSuggestions() {
    return [...new Set(this.suggestionTargets.map(node => parseInt(node.dataset.ruleId)))];
  }

  get baseSmartSuggestionsUrl() {
    return `/manage/jobs/${this.jobIdValue}/smart_suggestions`;
  }

  get smartSuggestionsBatchUrl() {
    return `${this.baseSmartSuggestionsUrl}/batch`;
  }

  showSuggestionsMobile() {
    const [showSuggestionsDisplay, mobileVersionDisplay] =
        [this.mobileButtonTarget.style.display, this.mobileVersionTarget.style.display];

    this.mobileButtonTarget.style.display = (showSuggestionsDisplay === 'none') ? 'block' : 'none';
    this.mobileVersionTarget.style.display = (mobileVersionDisplay === 'none') ? 'block' : 'none';
  }

  async checkAllFields() {
    const promises = [
      ...this._mapSsFields(),
      ...this._mapSsRangeFields(),
      ...this._mapSsSelectionFields(),
      ...this._mapSsTrixEditoFields()
    ];

    const { data } = await this._batchValidateFields(promises);
    const allRules = data.rule_ids;

    this._renderSuggestions(data.html_parts);
    this._markCompletedSuggestions(allRules);
    this._unMarkCompletedSuggestions(allRules);
    this._updateProgressBar();
  }

  async _checkExisting(){
    if (this.currentSuggestionsValue.length < 3) return;

    const vals = JSON.parse(this.currentSuggestionsValue.replaceAll('=>', ':'));
    const correct = Object.entries(vals)
      .filter(val => val[1].met)
      .map(val => val[0]);

    const { data } = await this._showCompletedSuggestions(correct);
    this._renderSuggestions(data.html_parts);
  }


  /**
   * Maps ssFields. ssField refers to a standalone input/select element which does not require data from
   *  any other field to validate. An ssField may have multiple SmartSuggestions attached to it, but the main
   *  point is on the DOM it is a single input.
   * @return {Array<Object>}
   * @private
   */
  _mapSsFields() {
    return this.ssFieldTargets.map(element => ({
      field: element.dataset.ssFieldIdentifier,
      value: element.value
    }));
  }

  /**
   * Maps ssRangeFields. ssRangeField refers to pairings of 2 inputs which represent a range of two values.
   *   ssRangeFields may also individually be ssFields, but ssRangeField refers specifically for SmartSuggestions which
   *   rely on 2 values to validate. There may be multiple SmartSuggestions attached to them, the main point is on the
   *   DOM it is two distinct inputs.
   * @return {Array<Object>}
   * @private
   */
  _mapSsRangeFields() {
    let fieldValues = [];

    for (let i = 0; i < this.ssRangeFieldTargets.length; i += 2) {
      const field1 = this.ssRangeFieldTargets[i];
      const field2 = this.ssRangeFieldTargets[i + 1];
      const suggestion = this._mapRangeField(field1, field2);
    
      if (suggestion) {
        fieldValues.push(suggestion);
      }
    }

    return fieldValues;
  }

  /**
   * Maps ssSelectionFields. ssSelectionField refers to a grouping of multiple inputs. Individual ssSelectionFields
   *  may also individually be ssFields.
   * @return {Array<Object>}
   * @private
   */
  _mapSsSelectionFields(){
    const selectionFields = this._countSelectionFields();
    return Object.keys(selectionFields).map(key => ({
      field: key,
      value: selectionFields[key]
    }));
  }

    /**
   * Maps ssTrixEditorFields. ssTrixEditorField refers to a text input field managed by Trix editor.
   * @return {Array<Object>}
   * @private
   */
  _mapSsTrixEditoFields() {
    return this.ssTrixEditorFieldTargets.map(element => ({
      field: element.id,
      value: element.textContent
    }));
  }

  _countSelectionFields() {
    let groups = {};
    this.ssSelectionFieldTargets.forEach(item => {
      const group = item.dataset.ssFieldIdentifier;
      const count = group === 'prescreen' ? 1 : item.querySelectorAll("input:checked").length;  
      
      if (!groups.hasOwnProperty(group)) {
        groups[group] = count;
      } else if (count > 0) {
        groups[group] = groups[group] + 1;
      }
    });

    return groups;
  }

  _mapRangeField(field1, field2) {
    const val1 = parseFloat(field1.value.replace(/,/g, ''));
    const val2 = parseFloat(field2.value.replace(/,/g, ''));
    
    if (!isNaN(val1) && !isNaN(val2)) {
      const fieldId = field1.dataset.ssFieldIdentifier
        .split(/(?=[A-Z])/)
        .slice(-1)
        .toString()
        .toLowerCase();

      return { field: fieldId, value: `${val1},${val2}` };
    }

    return null;
  }

  async _batchValidateFields(fieldValues) {
    try {
      return await axios.post(this.smartSuggestionsBatchUrl, {
        suggestions: fieldValues
      });
    } catch (error) {
      console.error("An error occurred while validating fields:", error.response ? error.response.data : error.message);
      throw new Error("Failed to fetch suggestions. Please try again .");
    }
  }

_renderSuggestions(htmlParts) {
    htmlParts.forEach((htmlPart) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlPart;
      const newSuggestion = tempDiv.firstChild;
      const ruleId = parseInt(newSuggestion.dataset.ruleId);

      let existingSuggestion = this.suggestionTargets.find(
        (node) => parseInt(node.dataset.ruleId) === ruleId
      );

      if (!existingSuggestion) {
        this.suggestionsHolderTargets.forEach((element) => {
          element.appendChild(newSuggestion.cloneNode(true));
        });
      }
    });
  }

  _markCompletedSuggestions(newSuggestions) {
    const completed = this.currentSuggestions.filter(
      (r) => !newSuggestions.includes(r)
    );
    completed.forEach((compId) => {
      let suggestions = Array.from(
        document.querySelectorAll(`[data-rule-id="${compId}"]`)
      );
      suggestions.forEach((suggestion) => {
        if (!suggestion.classList.contains('completed')) {
          suggestion.classList.add('completed');
          this._changeIcon(suggestion.querySelector('i'), true);
        }
      });
    });
  }

  _unMarkCompletedSuggestions(currentSuggestions) {
    const notCompleted = currentSuggestions.filter((r) =>
      this.currentSuggestions.includes(r)
    );
    notCompleted.forEach((compId) => {
      let suggestions = Array.from(
        document.querySelectorAll(`[data-rule-id="${compId}"]`)
      );
      suggestions.forEach((suggestion) => {
        if (suggestion.classList.contains('completed')) {
          suggestion.classList.remove('completed');
          this._changeIcon(suggestion.querySelector('i'), false);
        }
      });
    });
  }

  _changeIcon(icon, isCompleted) {
    if (isCompleted) {
      icon.classList.remove('far', 'fa-exclamation-triangle');
      icon.classList.add('fa', 'fa-check-circle');
    } else {
      icon.classList.remove('fa', 'fa-check-circle');
      icon.classList.add('far', 'fa-exclamation-triangle');
    }
  }

  _updateProgressBar() {
    const completed = Array.from(this.suggestionTargets)
      .filter(target => target.classList.contains('completed')).length;

    const progress = this.suggestionTargets.length > 0 ? (completed / this.suggestionTargets.length) * 100 : 100;
    this.progressBarTargets.forEach(p => {
      p.style.width = `${progress}%`;
      p.querySelector("div").textContent = `${progress.toFixed()}%`;
    });
  }

    /**
   * Checks the offset between the smart suggestions element and the card footer element.
   * Adjusts the position of the smart suggestions element and related elements if necessary.
   *
   * NOTE: the selectors for cardFooter is an element on the job_edit page.
   *  Thus, this is tightly coupled to the Job Edit pages specifically.
   *
   * @void
   */
  _adjustContainerPosition() {
    const smartSuggestions = document.querySelector('.smart_suggestions');
    const cardFooter = document.querySelector('.card-footer');

    if (smartSuggestions.getBoundingClientRect().top + smartSuggestions.offsetHeight >= cardFooter.getBoundingClientRect().top - 10) {
      smartSuggestions.style.position = 'absolute';
      smartSuggestions.style.marginLeft = '-15px';
      if (this.hasMobileButtonTarget) this.mobileButtonTarget.classList.add('mr-2');
      if (this.hasMobileButtonWrapperTarget) this.mobileButtonWrapperTarget.classList.add('pr-0');
    }

    if (document.documentElement.scrollTop + window.innerHeight < cardFooter.getBoundingClientRect().top) {
      smartSuggestions.style.position = 'fixed';
      smartSuggestions.style.marginLeft = '-25px';
      if (this.hasMobileButtonTarget) this.mobileButtonTarget.classList.remove('mr-2');
      if (this.hasMobileButtonWrapperTarget) this.mobileButtonWrapperTarget.classList.remove('pr-0');
    }
  }

  _showCompletedSuggestions(list) {
    return axios.get(`${this.baseSmartSuggestionsUrl}/${list.join(',')}`);
  }
}
