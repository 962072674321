import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];
  static values = { submitForm: Boolean };

  connect() {
    this.dragKey = this.data.get("dragKeyAttribute") || "data-drag-key";
    this.firefoxWorkaround();
  }

  dragstart(event) {
    this.data.set("draggedKey", event.target.getAttribute(this.dragKey));
    event.dataTransfer.effectAllowed = "move";
    event.target.classList.add("dragging");
  }

  dragover(event) {
    const overItem = event.target.closest(`[${this.dragKey}]`);
    if (overItem && this.matchesDraggedType(overItem)) {
      this.data.set("dropTarget", overItem.getAttribute(this.dragKey));
      this.clearInsertionArea();
      const midPoint = this.pageY(overItem) + overItem.offsetHeight / 2;
      if (event.pageY < midPoint) {
        this.data.set("dropPosition", "beforebegin");
        overItem.classList.add("mt-5");
      } else {
        this.data.set("dropPosition", "afterend");
        overItem.classList.add("mb-5");
      }
    }
    event.preventDefault();
  }

  drop(event) {
    this.clearInsertionArea();
    const dropTarget = this.element.querySelector(
      `[${this.dragKey}='${this.data.get("dropTarget")}']`
    );
    dropTarget.insertAdjacentElement(
      this.data.get("dropPosition"),
      this.draggedItem
    );
    event.preventDefault();
    if (this.submitFormValue) {
      this.submitForm();
    }
  }

  dragend(event) {
    this.inputTargets.forEach((el, pos) => {
      el.value = pos + 1;
      el.dispatchEvent(new Event("change"));
    });
    event.target.classList.remove("dragging");
  }

  clearInsertionArea() {
    $(this.element).find(`[${this.dragKey}]`).removeClass("mt-5 mb-5");
  }

  pageY(element) {
    let result = element.offsetTop;
    for (
      let parent = element.offsetParent;
      parent.offsetTop > 0;
      parent = parent.offsetParent
    ) {
      result = result + parent.offsetTop;
    }
    return result;
  }

  get draggedItem() {
    return this.element.querySelector(
      `[${this.dragKey}='${this.data.get("draggedKey")}']`
    );
  }

  matchesDraggedType(element) {
    const thisData = element.dataset;
    const otherData = this.draggedItem.dataset;
    if (!("dragItemType" in thisData) && !("dragItemType" in otherData))
      return true;
    if ("dragItemType" in thisData && !("dragItemType" in otherData))
      return false;
    if (!("dragItemType" in thisData) && "dragItemType" in otherData)
      return false;
    return thisData.dragItemType == otherData.dragItemType;
  }

  submitForm() {
    this.element.closest("form").submit();
  }

  /* Works-around this bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1189486
   * Any input nested within a `draggable="true"` element does not respond to mouse events
   */
  firefoxWorkaround() {
    if (navigator.userAgent.toLowerCase().indexOf("firefox") < 0) return;

    this.element.querySelectorAll("[draggable]").forEach((draggable) => {
      draggable
        .querySelectorAll('input[type="text"],textarea,trix-editor')
        .forEach((input) => {
          input.addEventListener("focus", () => {
            draggable.setAttribute("draggable", false);
          });
          input.addEventListener("blur", () => {
            draggable.setAttribute("draggable", true);
          });
        });
    });
  }
}
