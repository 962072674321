import CheckboxSelectAll from "../shared/checkbox_select_all_controller";

export default class extends CheckboxSelectAll {
  static targets = ["selectedText"];

  connect() {
    super.connect();

    this.updateSelectedText();
  }

  updateSelectedText() {
    if (this.checked.length === 1) {
      this.selectedTextTarget.innerHTML = "1 Task";
    } else {
      this.selectedTextTarget.innerHTML = `${this.checked.length} Tasks`;
    }
  }
}
