import { Controller } from "@hotwired/stimulus";

/**
 * Focuses an element on-connect. This is helpful in conjunction with the
 * `auto_search_controller` after the page has turned, this will re-focus the
 * search field to the end of the input.
 *
 * Warning: This will produce unpredictable results if there are multiple
 * elements using this controller.
 *
 * @example <caption>Example using text_field_tag helper.</caption>
 *  <%= text_field_tag(
 *      :employee_search,
        {},
        value: params[:term],
        data: {
          controller: 'auto-focus',
        }
      )
    %>
 *
 * @example When using a simple_form helper, wrap it in `input_html`
 *  <%= f.input(
      :code,
      label: false,
      input_html: {
        data: {
          controller: 'auto-focus',
        },
      },
    ) %>
 */

export default class extends Controller {
  connect() {
    this.element.focus();
    if (typeof this.element.selectionStart === 'number')
      this.element.selectionStart = this.element.selectionEnd = this.element.value.length;
  }
}
