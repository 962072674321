import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "value"];

  onInput(event) {
    this.updateValue();
    this.moveFocus(event.target);
  }

  moveFocus(target) {
    const index = this.inputTargets.indexOf(target);
    if (
      index < 0 ||
      index >= this.inputTargets.length ||
      !target.hasAttribute("maxlength") ||
      target.value.length < parseInt(target.getAttribute("maxlength"))
    )
      return;

    this.inputTargets[index + 1].focus();
  }

  updateValue() {
    this.valueTarget.value = this.inputTargets
      .map((el) => {
        return el.value;
      })
      .join("");
  }
}
