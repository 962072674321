import { Controller } from "@hotwired/stimulus";

/**
 * Updated URL query string based on actions in the page and optionally trigger a page reload
 * 
 * It is currently the developer's responsibility to ensure there are not multiple trigger targets
 *   with conflicting `query_string_key_param` values on a given page.
 * 
 * @example Dropdown that updates a query param based on its value and reloads the page
 *   <%= select_tag(
 *     :status,
 *     options_for_select({
 *       'Not Started' => 'assigned',
 *       'In Progress' => 'in_progress',
 *       'Completed' => 'completed',
 *     }, params[:status]),
 *     data: {
 *       controller: 'query-string',
 *       action: 'change->query-string#updateQueryString',
 *       query_string_target: 'trigger',
 *       query_string_key_param: 'status',
 *       query_string_should_reload_param: true
 *     }
 *   ) %>
 *
 * @example Checkbox that updates a boolean query param but does not reload the page
 *   <%= check_box_tag 'show_widget', '1', (params["show_widget"] != 'false'),
 *       data: {  
 *         controller: 'query-string',
 *         action: "query-string#updateQueryString",
 *         query_string_target: 'trigger',
 *         query_string_key_param: "show_widget",
 *         query_string_type_param: 'toggle',
 *         query_string_should_reload_param: false
 *       }
 *   %>
 */
export default class extends Controller {
  static targets = ["trigger"];

  updateQueryString({ params: { key, type, shouldReload }}) {
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, this.newValue(type));
    this.updateWindowLocation(searchParams, shouldReload);
  }

  newValue(type) {
    if (type == 'toggle') {
      return this.triggerTarget.checked;
    }

    return this.triggerTarget.value;
  }

  updateWindowLocation(searchParams, shouldReload) {
    let newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    if (shouldReload) {
      if (window.Turbo) {
        window.Turbo.visit(newUrl);
      } else {
        window.location = newUrl;
      }
    } else {
      // replace the history entry for forward/back navigation, but do not reload
      window.history.replaceState(null, null, newUrl);
    }
  }
}
