import $ from "jquery";

export function getScrollParent(element) {
  if (element == null) {
    return null;
  }

  if (element.scrollHeight > element.clientHeight) {
    return element;
  } else {
    return getScrollParent(element.parentNode);
  }
}

export function checkInView(element) {
  const container = getScrollParent(element);
  if (container === null) return true;

  const contHeight = $(container).height();
  const elemTop = $(element).offset().top - $(container).offset().top;
  const elemBottom = elemTop + $(element).height();
  return elemTop >= 0 && elemBottom <= contHeight;
}

export function scrollIntoViewIfNeeded(element, scrollOptions) {
  if (checkInView(element)) return;

  const scrollParent = getScrollParent(element);
  if (scrollParent) {
    // Calculate the desired scroll position for the parent
    const elementTop = element.getBoundingClientRect().top;
    const parentTop = scrollParent.getBoundingClientRect().top;
    const relativeTop = elementTop - parentTop;

    const desiredScrollTop = relativeTop + scrollParent.scrollTop - 50;

    if (scrollOptions && scrollOptions.behavior === "smooth") {
      $(scrollParent).animate({
        scrollTop: desiredScrollTop
      }, 300); // 300ms makes this snappy, we can increase the amount if we find this too fast
    } else {

      scrollParent.scrollTop = desiredScrollTop;
    }
  } else {
    const elementTop = element.getBoundingClientRect().top;
    const desiredScrollY = elementTop + window.pageYOffset - 50;

    if (scrollOptions && scrollOptions.behavior === "smooth") {
      $("html, body").animate({
        scrollTop: desiredScrollY
      }, 300);
    } else {
      window.scrollTo(0, desiredScrollY);
    }
  }
}