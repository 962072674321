import { Controller } from "@hotwired/stimulus";
import { portal } from "./drawer_portal_controller";
import { toastSuccess } from "../manage/toasts_controller";

export default class ExtendedModal extends Controller {
  connect() {
    this._readyToClose = false;
    portal(this.element, this.application, (drawer) => {
      drawer.element.addEventListener("drawer_portal:hide", this.handleHide);
    });
  }

  disconnect() {
    portal(this.element, this.application, (drawer) => {
      drawer.element.removeEventListener("drawer_portal:hide", this.handleHide);
    });
  }

  handleSuccess({ detail: { success } }) {
    if (success) {
      this.drawerPortalController.hide();
      toastSuccess({
        title: "Changes Saved",
        body: this.data.get("type") + " was successfully updated."
      });
    }
  }

  get drawerPortalController() {
    let drawerPortalEl = document.querySelector(
      ".voyager-drawer__container--open"
    ).parentElement;
    return this.application.getControllerForElementAndIdentifier(
      drawerPortalEl,
      "drawer-portal"
    );
  }
}
