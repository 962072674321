import { Controller } from "@hotwired/stimulus";
import { ajaxResponse } from "../../utils/forms.js";

export default class extends Controller {
  launchDrawer(evt) {
    let xhr = null;
    if (evt.detail.length > 2) {
      xhr = evt.detail[2];
    } else {
      xhr = evt.detail[0];
    }
    this.withPortal((portal) => {
      portal.contentTarget.innerHTML = ajaxResponse(evt);
      portal.show();
    });
  }

  withPortal(callback) {
    const drawerElement = document.getElementById(this.data.get("drawerId"));
    const portal = this.application.getControllerForElementAndIdentifier(
      drawerElement,
      "drawer-portal"
    );
    if (portal) {
      callback(portal);
    }
  }
}
