import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["column"];

  connect() {
    this.element.addEventListener('click', this.preventDropdownClose);

    this.updateAllColumns();
  }

  disconnect() {
    this.element.removeEventListener('click', this.preventDropdownClose);
  }

  toggleColumn(event) {
    const columnName = event.currentTarget.getAttribute("data-column-name");
    let searchParams = new URLSearchParams(window.location.search);

    this.columnTargets.filter(col => col.getAttribute("data-column-name") == columnName).forEach(column => {
      this.updateColumn(column, searchParams);
    });
  }

  updateAllColumns() {
    let searchParams = new URLSearchParams(window.location.search);

    this.columnTargets.forEach(column => {
      this.updateColumn(column, searchParams);
    });
  }

  updateColumn(column, searchParams) {
    let columnName = column.getAttribute("data-column-name");

    if (searchParams.get(`show_${columnName}`) == 'false') {
      column.style.display = "none";
    } else if (searchParams.get(`show_${columnName}`) == 'true') {
      column.style.display = '';
    } else {
      column.style.display = this.columnDefault(columnName);
    }
  }

  preventDropdownClose(event) {
    if (event.target.closest('.dropdown-menu')) {
      event.stopPropagation();
    }
  }

  columnDefault(columnName) {
    let defaultOffColumns = ['completed_at', 'assignees', 'collaborators'];

    if(defaultOffColumns.includes(columnName)) {
      return 'none';
    }

    return '';
  }
}
