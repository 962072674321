/*
************************************************************************************************

Usage notes:
  The intention of this controllers is to allow an arbitrary number of "submitter" buttons
  to toggle their enabled / disabled state based on the value of designated inputs.
  We check to see if all of the input targets have a value. If they do we will enable the buttons.
  If they do not we will disable the buttons.

<div data-controller="dependant-submit">
  <input type="text" data-dependant-submit-target="input" />
  <input type="text" data-dependant-submit-target="input" />

  <button type="submit" data-dependant-submit-target="submitter"></button>
</div>

************************************************************************************************
*/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "submitter"];

  connect() {
    this.inputTargets.forEach((input) =>
      input.addEventListener("change", this.toggleState)
    );
  }

  disconnect() {
    this.inputTargets.forEach((input) =>
      input.removeEventListener("change", this.toggleState)
    );
  }

  toggleState = () => {
    if (this.allSelected()) {
      this.submitterTargets.forEach((submitter) =>
        submitter.removeAttribute("disabled")
      );
    } else {
      this.submitterTargets.forEach((submitter) =>
        submitter.setAttribute("disabled", "disabled")
      );
    }
  };

  allSelected() {
    let selectedCount = 0;
    this.inputTargets.forEach(
      (input) => (selectedCount += input.value ? 1 : 0)
    );
    return selectedCount === this.inputTargets.length;
  }
}
