/*
************************************************************************************************

Usage notes:
  The idea behind this controller is to toggle the visibility of a form field based on the value of another.
  For example, you may have a form field that is only required or visible if a checkbox is checked.

  To use it, add the following to your form field container element:
    data-controller="shared--toggle-another-field"

  Add the following to the form field element you want to toggle:
    data-shared--toggle-another-field-target="field"
  
  Then add a checkbox or other field that will toggle the visibility of the field:
    data-action="click->shared--toggle-another-field#toggle"

  If you want to toggle the required attribute as well, use the following:
    data-action="click->shared--toggle-another-field#toggle_required"

************************************************************************************************
*/
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field"];

  toggle = () => {
    this.fieldTarget.classList.toggle("d-none");
    if (this.fieldTarget.classList.contains("d-none")) {
      this.fieldTarget.removeAttribute("disabled");
    } else {
      this.fieldTarget.setAttribute("disabled", "disabled");
    }
  };

  toggle_required = () => {
    this.fieldTarget.classList.toggle("d-none");
    if (this.fieldTarget.classList.contains("d-none")) {
      this.fieldTarget.setAttribute("disabled", "disabled");
      this.fieldTarget.children.required = false;
      this.fieldTarget.children[0].removeAttribute("required", "required");
    } else {
      this.fieldTarget.removeAttribute("disabled");
      this.fieldTarget.children.required = true;
      this.fieldTarget.children[0].setAttribute("required", "required");
    }
  };
}
