import $ from "jquery";

(() => {
  const isIe = () => {
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf("MSIE ") > 0 || userAgent.indexOf("Trident/") > 0;
  };
  if (!isIe()) return;

  function internalButtonId(element) {
    return [
      "ie_internal_submit_button",
      element.getAttribute("form"),
      element.getAttribute("name"),
      element.getAttribute("value")
    ].join("_");
  }

  function findInternalButton(element) {
    return document.getElementById(internalButtonId(element));
  }

  function ensureInternalButton(element) {
    if (element.id.indexOf("ie_internal_submit_button") !== -1) return;
    let internalButton = findInternalButton(element);
    if (internalButton) return;

    const form = document.getElementById(element.getAttribute("form"));
    if (form) {
      internalButton = element.cloneNode();
      internalButton.id = internalButtonId(element);
      internalButton.className = "ie_internal_submit_button w-0 h-0 border-0";
      form.appendChild(internalButton);
    }
  }

  // CREDIT: https://blog.yorkxin.org/2014/02/06/ajax-with-formdata-is-broken-on-ie10-ie11.html
  function ensureXHRWorkaround(form) {
    let workaroundId = ["ie_xhr_workaround", form.id].join("_");
    if (document.getElementById(workaroundId)) return;

    let element = document.createElement("input");
    element.setAttribute("type", "hidden");
    element.setAttribute("name", "_ignore");
    element.id = workaroundId;
    form.appendChild(element);
  }

  function ensureAllWorkarounds() {
    const allButtons = document.querySelectorAll("[form]");
    for (let i = 0; i < allButtons.length; i++) {
      ensureInternalButton(allButtons[i]);
    }
    const allForms = document.querySelectorAll("form");
    for (let i = 0; i < allForms.length; i++) {
      ensureXHRWorkaround(allForms[i]);
    }
  }
  ensureAllWorkarounds();

  const observer = new MutationObserver(ensureAllWorkarounds);
  observer.observe(document.querySelector("body"), {
    childList: true,
    subtree: true
  });

  function handleSubmit(event) {
    const internalButton = findInternalButton(event.target);
    if (internalButton) {
      internalButton.click();
    }
  }

  $(document).on(
    "click",
    "[form]:not(.ie_internal_submit_button)",
    handleSubmit
  );
})();
