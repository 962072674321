import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  toggle(_event) {
    this.inputTargets.forEach((input) => {
      input.dispatchEvent(new Event("update", { bubbles: true }));
    });
  }

  toggleItemInButtonGroup(event) {
    let eventName = this.data.get("update-event") || "update";
    let targetValue = event.currentTarget.innerText.toLowerCase().trim();
    this.inputTargets.forEach((input) => {
      //  only send checked (selected) input
      if (input.value == targetValue) {
        input.dispatchEvent(
          new CustomEvent(eventName, { bubbles: true })
        );
      }
    });
  }
}
