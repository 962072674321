import { Controller } from "@hotwired/stimulus";
import introJs from "intro.js";
import axios from "axios";

export default class extends Controller {
  static targets = [];
  static walkthrough = introJs();

  connect() {
    if (this.startOnConnect) {
      this.startWalkthrough();
    }
  }

  startWalkthrough() {
    this.setSteps().then(() => {
      this.beginObserving();
      this.setBeforeStepActions();
      this.setExitActions();
      this.walkthrough.start();
    });
  }

  beginObserving() {
    const observer = new MutationObserver((_mutations, _observer) => {
      this.walkthrough.refresh();
    });
    const observerOptions = {
      attributes: true,
      attributeFilter: ["class", "style"]
    };
    this.observeSteps.forEach((selector) => {
      let element = document.querySelector(selector);
      if (!element) {
        return;
      }
      observer.observe(element, observerOptions);
    });
  }

  async setSteps() {
    await axios
      .get("/walkthroughs", {
        params: {
          walkthrough_type: this.walkthroughType
        }
      })
      .then(({ data }) => {
        const options = { ...data.options, ...{ steps: data.steps } };
        this.observeSteps = JSON.stringify(data.observe_steps);
        this.eventListeners = JSON.stringify(data.event_listeners);
        this.walkthrough.setOptions(options);
      });
  }

  setBeforeStepActions() {
    this.walkthrough.onbeforechange((stepElement) => {
      this.findAndSetListeners(stepElement);
    });
  }

  findAndSetListeners(stepElement) {
    let listener = this.findListenerBySelector(
      this.eventListeners,
      stepElement
    );
    if (listener) {
      this.setEventListener(stepElement, listener);
    }
  }

  findListenerBySelector(selectorObj, element) {
    let selector = Object.keys(selectorObj).find((selector) => {
      return element.matches(selector);
    });
    return this.eventListeners[selector];
  }

  setEventListener(stepElement, listener) {
    let func = this.walkthroughAction(listener.action);
    stepElement.addEventListener(listener.event, func);
  }

  setExitActions() {
    const listeners = this.eventListeners;
    this.walkthrough.onexit(() => {
      Object.entries(listeners).forEach(([selector, listener]) => {
        let func = this.walkthroughAction(listener.action);
        document
          .querySelector(selector)
          .removeEventListener(listener.event, func);
      });
    });
  }

  walkthroughAction(action) {
    return action == "end" ? this.endWalkthrough : this.moveToNextStep;
  }

  moveToNextStep = (_event) => {
    this.walkthrough.nextStep();
  };

  endWalkthrough = (_event) => {
    this.walkthrough.exit();
  };

  get walkthroughType() {
    return this.data.get("type");
  }

  get walkthrough() {
    return this.constructor.walkthrough;
  }

  get startOnConnect() {
    return this.data.get("start-on-connect");
  }

  get eventListeners() {
    return JSON.parse(this.data.get("event-listeners"));
  }

  set eventListeners(value) {
    this.data.set("event-listeners", value);
  }

  get observeSteps() {
    return JSON.parse(this.data.get("observe-steps"));
  }

  set observeSteps(value) {
    this.data.set("observe-steps", value);
  }
}
