import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    // When using drawers, the z-index of the drawer itself will automatically apply
    // to any modals nested within those drawers. Because of this, the z-index of backdrops
    // will be in a different stack, and higher than the drawer.
    // The below fix will take the backdrop and place it in the same z-index stack as the
    // modal
    $(document).on(
      "shown.bs.modal",
      ".voyager-drawer__content .modal",
      function () {
        $(".modal-backdrop").insertBefore($(this));
      }
    );
  }

  dismiss() {
    $(this.element).modal("hide");
  }
}
