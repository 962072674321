import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    /*  layout__page-container needs flex class below in order to
        render the footer in the correct place in Safari */
    $(".layout__page-container").css("flex-shrink", "0");
  }
}
