import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["modal", "inputWrapper"];

  connect() {
    if (this.hasModalTarget) {
      $(this.modalTarget).on("shown.bs.modal", this.storeUnsavedState);
      $(this.modalTarget).on("show.bs.modal", this.safariFix);
      $(this.modalTarget).on("hidden.bs.modal", this.removeSafariFix);
    }
  }

  disconnect() {
    if (this.hasModalTarget) {
      $(this.modalTarget).off("shown.bs.modal", this.storeUnsavedState);
      $(this.modalTarget).off("show.bs.modal", this.safariFix);
      $(this.modalTarget).off("hidden.bs.modal", this.removeSafariFix);
    }

    // Don't leave open modals on the page, it causes weird issues if that page
    // is served up from a cache later.
    $(this.modalTarget).modal("hide");
  }

  storeUnsavedState = () => {
    const selections = JSON.stringify(
      Array.from(this.inputWrapperTarget.querySelectorAll("input:checked")).map(
        (el) => el.value
      )
    );
    this.data.set("original-selections", selections);
  };

  hideModal() {
    $(this.modalTarget).modal("hide");
  }

  cancel() {
    this.hideModal();
    const selections = JSON.parse(this.data.get("original-selections"));
    this.allInputs((el) => {
      el.checked = selections.includes(el.value);
    });
  }

  allInputs(callback) {
    if (!this.hasInputWrapperTarget) return;
    this.inputWrapperTarget.querySelectorAll("input").forEach(callback);
  }

  withMobileWrapper(callback) {
    const mobileWrapper = document.querySelector(
      ".layout__content.layout__content--mobile"
    );
    if (mobileWrapper) {
      callback(mobileWrapper);
    }
  }

  safariFix = () => {
    this.withMobileWrapper((el) => {
      el.style.zIndex = 1050;
    });
  };

  removeSafariFix = () => {
    this.withMobileWrapper((el) => {
      el.style.zIndex = null;
    });
  };
}
