import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["confirmPassword", "password"]

  async toggle() {
    if (this.passwordTarget.value != "") {
      this.confirmPasswordTarget.classList.remove('d-none')
    } else {
      this.confirmPasswordTarget.classList.add('d-none')
    }
  }
}
