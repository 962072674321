import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fullTime", "contractor", "stateForm", "employmentType"];

  connect() {
    const checkedRadioButton = this.employmentTypeTargets.find(
      (radio) => radio.checked
    );

    if (checkedRadioButton.id.includes("full_time")) {
      this.showFullTimeFields();
    } else {
      this.showContractorFields();
    }
  }

  toggleFields() {
    if (event.target.value == "full_time") {
      this.stateFormTarget.value = "";
      this.showFullTimeFields();
    } else {
      this.showContractorFields();
    }
  }

  showFullTimeFields() {
    this.contractorTarget.classList.add("d-none");
    this.fullTimeTarget.classList.remove("d-none");
  }

  showContractorFields() {
    this.fullTimeTarget.classList.add("d-none");
    this.contractorTarget.classList.remove("d-none");
    this.stateFormTarget.value = "non_applicable";
  }
}
