// SignatureInputController inherits from OutputInputController and is a unique case
// where it didnt make sense adding this functionality to the parent class.
//
// NOTE: This is not a reusable component. This is unique functionality to the review offer drawer.
//

import $ from "jquery";
import OfferApprovalSwitchController from "./../shared/optional_input_controller.js"

export default class extends OfferApprovalSwitchController {
  static targets = ["signatureArea"];

  connect() {
    $(this.signatureAreaTarget).hide();
  }

  toggleSignature(_event) {
    console.log(this.switchTarget.value);
    if (this.switchTarget.value == 'manager_accept') {
      $(this.signatureAreaTarget).show();
    } else {
      $(this.signatureAreaTarget).hide();
    }
  }
}
