import { Controller } from "@hotwired/stimulus";

/***
 *
 * A Stimulus controller to manage multiple checkboxes that may be selected
 * all at once (or de-selected all at once).
 * @extends Controller
 *
 * @see Stimulus-Components documentation: https://www.stimulus-components.com/docs/stimulus-checkbox-select-all
 *
 * @example <caption>Basic Usage</caption>
 *
 * <table data-controller="checkbox-select-all">
 *   <tbody>
 *     <td class="block">
 *       <label>
 *         <input type="checkbox" data-checkbox-select-all-target="checkboxAll" />
 *         <span>Select All / Deselect All</span>
 *       </label>
 *     </td>
 *
 *     <td class="block">
 *       <label>
 *         <input type="checkbox" data-checkbox-select-all-target="checkbox" value="1" />
 *         <span>Team 1</span>
 *       </label>
 *     </td>
 *
 *     <td class="block">
 *       <label>
 *         <input type="checkbox" data-checkbox-select-all-target="checkbox" checked="checked" value="2" />
 *         <span>Team 2</span>
 *       </label>
 *     </td>
 *
 *     <td class="block">
 *       <label>
 *         <input type="checkbox" data-checkbox-select-all-target="checkbox" value="3" />
 *         <span>Team 3</span>
 *       </label>
 *     </td>
 *   </tbody>
 * </table>
 * 
 * 
 * @example <caption>Pre-Select All</caption>
 *
 * <table data-controller="checkbox-select-all">
 *   <tbody>
 *     <td class="block">
 *       <label>
 *         <input type="checkbox" <%= 'checked' if params[:select_all].present? %> data-checkbox-select-all-target="checkboxAll" />
 *         <span>Select All / Deselect All</span>
 *       </label>
 *     </td>
 *
 *     <td class="block">
 *       <label>
 *         <input type="checkbox" data-checkbox-select-all-target="checkbox" value="1" />
 *         <span>Team 1</span>
 *       </label>
 *     </td>
 *
 *     <td class="block">
 *       <label>
 *         <input type="checkbox" data-checkbox-select-all-target="checkbox" checked="checked" value="2" />
 *         <span>Team 2</span>
 *       </label>
 *     </td>
 *   </tbody>
 * </table>
 *
 * @example <caption>Rails Form Helper Example</caption>
 *
 * <%= form_with model: @user, data: { controller: 'checkbox-select-all' } do |f| %>
 *   <label>
 *     <input type="checkbox" data-checkbox-select-all-target="checkboxAll" />
 *     <span>Select All / Deselect All</span>
 *   </label>
 *
 *   <%= f.collection_check_boxes :team_ids, Team.all, :id, :name do |b| %>
 *     <%= b.label do %>
 *       <%= b.check_box data: { checkbox_select_all_target: 'checkbox' } %>
 *       <%= b.text %>
 *     <% end %>
 *   <% end %>
 * <% end %>
 *
 * @example <caption>Extending the Controller to Layer On Additional Behavior</caption>
 *
 * import CheckboxSelectAll from 'shared/checkbox-select-all'
 *
 * export default class extends CheckboxSelectAll {
 *   connect() {
 *     super.connect()
 *     console.log('Do what you want here.')
 *
 *     // Get all checked checkboxes
 *     this.checked
 *
 *     // Get all unchecked checkboxes
 *     this.unchecked
 *   }
 * }
 *
 ***/
export default class extends Controller {
  static targets = ["checkboxAll", "checkbox"];

  initialize() {
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
    
    if (this.checkboxAllTarget.checked === true) {
      this.selectAll();
    }
  }

  selectAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = true;
      this.triggerInputEvent(checkbox);
    });
  }

  checkboxAllTargetConnected(checkbox) {
    checkbox.addEventListener("change", this.toggle);

    this.refresh();
  }

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener("change", this.refresh);

    this.refresh();
  }

  checkboxAllTargetDisconnected(checkbox) {
    checkbox.removeEventListener("change", this.toggle);

    this.refresh();
  }

  checkboxTargetDisconnected(checkbox) {
    checkbox.removeEventListener("change", this.refresh);

    this.refresh();
  }

  toggle(e) {
    e.preventDefault();

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = e.target.checked;
      this.triggerInputEvent(checkbox);
    });
  }

  refresh() {
    const checkboxesCount = this.checkboxTargets.length;
    const checkboxesCheckedCount = this.checked.length;

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0;
    this.checkboxAllTarget.indeterminate =
      checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount;
  }

  triggerInputEvent(checkbox) {
    const event = new Event("input", { bubbles: false, cancelable: true });

    checkbox.dispatchEvent(event);
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }

  get unchecked() {
    return this.checkboxTargets.filter((checkbox) => !checkbox.checked);
  }
}
