import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  format() {
    // If blank or contains letters just return the value
    if (this.value == "" || /[^\d\s.,]/g.test(this.value)) {
      return;
    }

    // Remove whitespace and comma characters
    this.inputTarget.value = this.value.replace(/\s|,/g, "");

    // Check for more than one decimal and remove excess decimals
    while (this.value.split(".").length > 2) {
      this.inputTarget.value = this.value.slice(0, this.value.lastIndexOf("."));
    }

    // Add cents to the integer if none exist
    if (this.value.indexOf(".") === -1) {
      this.inputTarget.value = this.value + ".00";
    }

    // Check that there is the proper amount of decimal places after the integer
    while (this.value.indexOf(".") > this.value.length - 3) {
      this.inputTarget.value = this.value + "0";
    }

    while (this.value.indexOf(".") < this.value.length - 3) {
      this.inputTarget.value = this.value.slice(0, -1);
    }
  }

  get value() {
    return this.inputTarget.value;
  }
}
