import { Controller } from "@hotwired/stimulus";

const roles = {
  ADMIN: "1",
  FULLACESS: "2",
  LIMITEDACCESS: "3",
  NORECRUITINGACCESS: "4"
};

export default class extends Controller {
  static targets = ["radioButton"];

  connect() {
    this.initialHide();
    this.registerEvent();
  }

  disconnect() {
    this.unregisterEvent();
  }

  initialHide() {
    if (
      this.checkedInputValue === roles.ADMIN ||
      this.checkedInputValue === roles.NORECRUITINGACCESS
    ) {
      this.hideLocationPermissions();
    }
  }

  registerEvent() {
    this.radioButtonTargets.forEach((radio_button) =>
      radio_button.addEventListener("change", this.toggleLocationPermissions)
    );
  }

  unregisterEvent() {
    this.radioButtonTargets.forEach((radio_button) =>
      radio_button.removeEventListener("change", this.toggleLocationPermissions)
    );
  }

  toggleLocationPermissions = () => {
    switch (this.checkedInputValue) {
      case roles.ADMIN:
      case roles.NORECRUITINGACCESS:
        this.hideLocationPermissions();
        break;
      case roles.FULLACESS:
      case roles.LIMITEDACCESS:
        this.showLocationPermissions();
        break;
      default:
        return;
    }
  };

  hideLocationPermissions() {
    $("#association-selectors").hide();
  }

  showLocationPermissions() {
    $("#association-selectors").show();
  }

  get checkedInput() {
    return this.radioButtonTargets.find((radio_button) => radio_button.checked);
  }

  get checkedInputValue() {
    return this.checkedInput.value;
  }
}
