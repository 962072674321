import { Controller } from "@hotwired/stimulus";

/**
 * CocoonResetController is to be used in conjunction with cocoon to show/hide a div that will
 * allow the user to reset any adding or removing of associations they have made. All this
 * controller does is show/hide a target depending on whether the associations are in a "clean"
 * state or not. It is up to the caller to actually perform the reset (i.e. refresh the page).
 *
 * @example <caption>Rails helpers and hidden input.</caption>
 *
 * <div id="hiring-steps" data-controller="cocoon-reset">
 *   <%= form.simple_fields_for(:hiring_steps) do |step_form| %>
 *     <div class="nested-fields">
 *       <%= step_form.object.name %>
 *       <%= cocoon_remove_association fas_icon(:trash), step_form %>
 *     </div>
 *   <% end %>
 *   <div data-cocoon-reset-target="button" class="d-none">
 *     <a href="javascript:window.location.href=window.location.href">Reset</a>
 *   </div>
 * </div>
 */
export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.buttonTarget.classList.add("d-none");
    const self = this;
    $(this.element).on("cocoon:after-remove", function () {
      self.buttonTarget.classList.remove("d-none");
    });
  }

  disconnect() {
    const self = this;
    $(this.element).off("cocoon:after-remove", function () {
      self.buttonTarget.classList.remove("d-none");
    });
  }
}
