import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["builder", "select"];
  static values = {
    questions: Object,
    titles: Object
  }

  updated() {
    const questionId = this.questionsValue[this.builderTarget.value.toString()];
    const titles = this.titlesValue[this.builderTarget.value.toString()];

    // Update select name
    this.selectTarget.name = this.selectTarget.name.replace(/(\d+)/, questionId);

    // Clear existing options
    this.selectTarget.innerHTML = "";

    // Add blank option to the top
    this._blankOption();

    for (const key in titles) {
      const option = new Option(titles[key][0], titles[key][1]);

      // Append the new option to the select box
      this.selectTarget.add(option, undefined);
    }
  }

  _blankOption() {
    const option = new Option('', '');
    this.selectTarget.add(option, undefined);
  }
}
