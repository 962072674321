import { Controller } from "@hotwired/stimulus";

/**
 * If you need to programatically request an existing turbo-frame to refresh itself
 * this controller should help. Example: handling a seemingly unrelated form post
 * that might affect a frame if that frame happens to be on the page right now.
 */
export default class extends Controller {
  refresh() {
    const turboFrame = document.getElementById(this.data.get("frameId"));
    if (turboFrame) {
      let src = turboFrame.getAttribute("src");

      // We can't use turboFrame.reload() yet so this is a suggested workaround.
      turboFrame.src = null;
      turboFrame.src = src;
    }
  }
}
