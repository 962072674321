import { Controller } from "@hotwired/stimulus";

/**
 * Keeps track of the currently logged-in user and reloads the page if the user
 * changes. This is useful for both impersonation and multi-account users for
 * keeping all browser tabs in-sync with the currently logged-in user.
 *
 * This stimulus controller is present on all Voyager manage-side and
 * public-side pages. Since the admin-side is unaffected, you may unimpersonate
 * a user, impersonate a second user and the other tab will reflect the second
 * user.
 */
export default class extends Controller {
  static values = {
    user: Number,
  };

  connect() {
    localStorage.setItem("session_controller:user_id", this.userValue);
    addEventListener("storage", this.userChanged);
  }

  disconnect() {
    removeEventListener("storage", this.userChanged);
  }

  userChanged(event) {
    if (event.key === "session_controller:user_id" && event.newValue !== this.userValue) {
      setTimeout(() => window.location.reload(), 1000);
    }
  }
}
