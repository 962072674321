import { Controller } from "@hotwired/stimulus";
import * as toast from "../manage/toasts_controller";

export default class extends Controller {
  success() {
    this.showToast("success");
  }

  info() {
    this.showToast("info");
  }

  danger() {
    this.showToast("danger");
  }

  warning() {
    this.showToast("warning");
  }

  showToast(state) {
    toast.showToast({
      title: this.data.get(`${state}Title`),
      body: this.data.get(`${state}Body`),
      state: toast[state]
    });
  }
}
