export function generateRemoteModal(innerHTML = "") {
  const modalId = generateRemoteModalId();
  document
    .getElementById("remote-modal-container")
    .insertAdjacentHTML("beforeend", `<div id=${modalId}>${innerHTML}</div>`);
  return modalId;
}

export function removeRemoteModal(modalId) {
  const element = document.getElementById(modalId);
  if (element) {
    element.parentNode.removeChild(element);
  }
}

function generateRemoteModalId() {
  let remoteModalId = `remote-modal-${Math.round(Math.random() * 10000000)}`;

  // If by some chance our Math.random call generates a duplicate ID.
  while (document.getElementById(remoteModalId)) {
    remoteModalId = `remote-modal-${Math.round(Math.random() * 10000000)}`;
  }
  return remoteModalId;
}
