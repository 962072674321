import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js';

/***
 * ChartController is a thin interface to the chart.js library.
 *
 * @see Chart.js documentation: https://www.chartjs.org/docs/latest/
 * @see stimulus-chartjs: https://www.stimulus-components.com/docs/stimulus-chartjs/
 *
 * If you need to customize a chart more than can be done by providing
 * 'options', you can extend this controller in your own new custom chart
 * controller.
 *
 * @example <caption>A line chart</caption>
 *
 * <canvas data-controller='chart'
 *         data-chart-data-value='{ "labels": ["November 5, 2022", "Nov 19", "Nov 26", "Dec 18"], "datasets": [{"data": [3, -3, 1, 2] }] }'>
 * </canvas>
 *
 * @example <caption>A bar chart</caption>
 *
 * <canvas data-controller='chart'
 *         data-chart-data-value=<%= values %>
 *         data-chart-type-value='bar'>
 * </canvas>
 *
 * @example <caption>A chart with options</caption>
 *
 * <canvas data-controller='chart'
 *         data-chart-data-value=<%= values %>
 *         data-chart-options-value='{ plugins: { legend: false } }'>
 * </canvas>
 *
 * @example <caption>Controller mounted outside of canvas</caption>
 *
 * <div data-controller='chart' data-chart-data-value=<%= values %>>
 *   <canvas data-chart-target='canvas'>
 *   </canvas>
 *
 *   <button type="button">Refresh</button>
 * </div>
 *
 ***/
export default class extends Controller {
  // Specify the canvas target (for when the controller is not mounted on the
  // canvas itself).
  static targets = ['canvas']
  static values = {
    // The type of the chart
    type: {
      type: String,
      default: 'line'
    },
    // Data for the chart
    data: Object,
    // Options to be passed to chart.js
    options: Object,
  }

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element

    this.chart = new Chart(element.getContext('2d'), {
      type: this.typeValue,
      data: this.chartData,
      options: this.chartOptions
    })
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  // Private

  get chartData() {
    if (!this.hasDataValue) {
      console.warn('[stimulus-chart] You need to pass data as JSON to see the chart.')
    }

    return this.dataValue
  }

  get chartOptions() {
    return {
      ...this.defaultOptions,
      ...this.optionsValue
    }
  }

  get defaultOptions() {
    return {}
  }
}
