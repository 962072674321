import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scrollContainer"];

  static values = {
    scrollOnConnect: Boolean
  };

  connect() {
    if (this.hasScrollOnConnectValue && this.scrollOnConnectValue) {
      this.scroll();
    }
  }

  scroll() {
    let invalidElements = $(
      `${this.scrollContainerTarget.className.split(" ")}, .is-invalid`
    );
    if (invalidElements.length == 0) return;

    $(this.scrollContainerTarget).animate(
      {
        scrollTop: invalidElements.first().offset().top
      },
      100
    );
  }
}
