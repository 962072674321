import Flatpickr from "stimulus-flatpickr";
import moment from "moment";

export default class extends Flatpickr {
  change(selectedDates, _dateStr, instance) {
    const new_time = selectedDates[0],
      selected_formatted = moment(selectedDates[0]).format("h:mm A"),
      input_id = instance.input.id,
      is_start_time = /start/.test(input_id);

    if (is_start_time) {
      // prevents double increment from click on am/pm when time has not changed
      if (_dateStr !== selected_formatted) return;
      this.set_opposite_field(input_id, new_time, /start/, "end", "<=", 60);
    } else {
      this.set_opposite_field(input_id, new_time, /end/, "start", ">=", 0);
    }
  }

  set_opposite_field(
    input_id,
    new_time,
    regex,
    opposite_field,
    eval_operator,
    modifier
  ) {
    let field_id = input_id.replace(regex, opposite_field),
      time_field = document.getElementById(field_id),
      time = time_field._flatpickr.selectedDates[0];

    if (eval(`time ${eval_operator} new_time`)) {
      if (opposite_field === "start") {
        // Request for time not to change for minute with the same hour
        if (moment(new_time).format("H") === moment(time).format("H")) return;
        // This takes care of am/pm click
        if (moment(new_time).format("h") === moment(time).format("h")) {
          time_field._flatpickr.setDate(
            new_time.setHours(new_time.getHours(), time.getMinutes())
          );
          return;
        }
      }

      time_field._flatpickr.setDate(
        new_time.setMinutes(new_time.getMinutes() + modifier)
      );
      return;
    }

    if (opposite_field === "end") {
      // Request to change end time to 60 minutes after start
      // Request for time not to change for minute with the same hour
      if (moment(new_time).format("H") === moment(time).format("H")) return;

      time_field._flatpickr.setDate(
        new_time.setMinutes(new_time.getMinutes() + modifier)
      );
    }
  }
}
