import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["card"];

  connect() {
    let navbarHeight = $(".layout__top-container").outerHeight(true),
      drawer = $("#drawer-app-bulk-actions .voyager-drawer__container"),
      content = $(".bulk-actions-drawer-content"),
      contentHeight = content.height();

    drawer.css("top", `${navbarHeight}px`);
    content.css("height", `${contentHeight + navbarHeight + 200}px`);
  }

  clearSelection() {
    this.bulkActionController.toggleAllTarget.checked = false;
    this.bulkActionController.toggleAll();
  }

  retrieve() {
    // This is to stop the default retrieve action of the modal controller
    event.stopImmediatePropagation();

    let element = event.currentTarget,
      controller_div = $(element).parents("[data-controller~=remote-modal]"),
      modal = this.remoteModalController(controller_div[0]);

    const orig_location = modal.data.get("location");
    modal.data.set(
      "location",
      this.urlWithSelectedIds(modal.data.get("location"), element)
    );
    modal.retrieve(event);
    modal.data.set("location", orig_location);
  }

  urlWithSelectedIds(base, element) {
    return `${base}?${encodeURI(
      this.selectedIds.map((id) => `${element.dataset.idParam}${id}`).join("&")
    )}`;
  }

  validate() {
    this.cardTargets.forEach((card) => {
      const endpoint = card.dataset.validationEndpoint;
      if (endpoint) {
        axios.get(this.urlWithSelectedIds(endpoint, card)).then(({ data }) => {
          card.outerHTML = data;
        });
      }
    });
  }

  get selectedIds() {
    return this.bulkActionController.selectedIds;
  }

  remoteModalController(element) {
    return this.application.getControllerForElementAndIdentifier(
      element,
      "remote-modal"
    );
  }

  get bulkActionController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("appIndexController"),
      "bulk-actions"
    );
  }
}
