import { Controller } from "@hotwired/stimulus";

/**
 * Used to swap placeholder text on focus
 */
export default class extends Controller {
    static values = {
        initial: String,
        new: String
    }

    /**
     * Update to newValue text
     * @param event {FocusEvent}
     */
    updatePlaceholder(event) {
        event.target.setAttribute('placeholder', this.newValue)
    }

    /**
     * Reset to initialValue text
     * @param event {FocusEvent}
     */
    resetPlaceholder(event) {
        event.target.setAttribute('placeholder', this.initialValue)
    }
}
