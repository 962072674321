import { Controller } from "@hotwired/stimulus";
import { toastSuccess, toastDanger } from "../manage/toasts_controller";
import axios from "axios";
import { appMessage } from "../../utils/broadcast";

export default class extends Controller {
  static targets = ["sendLater", "sendRejection"];

  showRejectionLetter(evt) {
    this.container.find(".deactivate_drawer-sending-email").toggleClass("show");
  }

  get container() {
    return $(this.element);
  }

  get appId() {
    return this.data.get("app-id");
  }

  rejectApp(event) {
    event.preventDefault();
    this.disableSubmitButton(event.target);
    this.deactivateApp(event.target);
  }

  deactivateApp(submitButton) {
    const path = this.data.get("path");
    const sendRejection = this.sendRejectionTarget.checked;
    let anyErrors = false;
    const applicantName = this.data.get("applicant-name");

    if (sendRejection) {
      anyErrors = this.validateRejectionLetter();
    }

    if (anyErrors) {
      this.enableSubmitButton(submitButton);
    } else {
      axios
        .post(path, {
          email: this.buildEmail(sendRejection),
          app_comment: this.buildComment(),
          reject_notify: sendRejection
        })
        .then((_response) => {
          this.successNotice();
        })
        .catch((_response) => {
          this.error(
            `Error deactivating ${applicantName}.`,
            "Please try again."
          );
        })
        .finally(() => this.enableSubmitButton(submitButton));
    }
  }

  disableSubmitButton(submitButton) {
    if (!submitButton) return;
    submitButton.setAttribute("disabled", true);
  }

  enableSubmitButton(submitButton) {
    if (!submitButton) return;
    submitButton.removeAttribute("disabled");
  }

  buildEmail(sendRejection) {
    if (sendRejection === false) return {};
    let email = {
      event: "app:" + this.appId,
      commands: this.data.get("commands"),
      to: this.data.get("applicant-email"),
      subject: document.getElementById("letter-subject-app-" + this.appId)
        .value,
      body: document.getElementById("letter-email-content-app-" + this.appId)
        .value,
      has_attachments_attributes: []
    };

    if (this.sendLaterTarget && this.sendLaterTarget.checked) {
      email = {
        ...email,
        scheduled_date: document.getElementById("email_scheduled_date").value,
        scheduled_time: document.getElementById("email_scheduled_time").value,
        timezone: document.getElementById("email_timezone").value
      };
    }

    return email;
  }

  buildComment() {
    const rejectReason = document.getElementById("reject-reason-" + this.appId)
        .value,
      rejectComment = document.getElementById("reject-comment-" + this.appId)
        .value;

    const commentData = { reject_reason: rejectReason, comment: rejectComment };

    return commentData;
  }

  validateRejectionLetter() {
    const template = document.getElementById(
        "letter-template-app-" + this.appId
      ),
      rejectionSubject = document.getElementById(
        "letter-subject-app-" + this.appId
      ),
      rejectionBody = document.getElementById(
        "letter-email-content-app-" + this.appId
      ),
      scheduledDate = document.getElementById("email_scheduled_date"),
      scheduledTime = document.getElementById("email_scheduled_time");

    let errors = [];
    rejectionSubject.classList.remove("is-invalid");
    rejectionBody.classList.remove("is-invalid");
    template.classList.remove("is-invalid");
    scheduledTime.classList.remove("is-invalid");
    scheduledDate.classList.remove("is-invalid");

    if (rejectionSubject.value == "") {
      rejectionSubject.classList.add("is-invalid");
      errors.push("Rejection letter must have a subject");
    }

    if (rejectionBody.value == "") {
      rejectionBody.classList.add("is-invalid");
      errors.push("Rejection letter body cannot be empty");
    }

    if (this.sendLaterTarget && this.sendLaterTarget.checked) {
      if (scheduledDate.value == "") {
        scheduledDate.classList.add("is-invalid");
        errors.push("Please select a valid date");
      }

      if (scheduledTime.value == "") {
        scheduledTime.classList.add("is-invalid");
        errors.push("Please select a valid time");
      }
    }

    if (errors.length > 0) {
      template.classList.add("is-invalid");
      this.error("Please fix issues before submitting", errors.join("<br />"));
      return true;
    } else {
      return false;
    }
  }

  error(errorTitle, errorMessage) {
    toastDanger({ title: errorTitle, body: errorMessage });
  }

  successNotice() {
    const isDuplicate = this.data.get("duplicate");
    const applicantName = this.data.get("applicant-name");
    this.drawerPortalController.hide();
    toastSuccess({
      title: `${applicantName} deactivated`,
      body: `${applicantName} was successfully deactivated.`
    });
    if (isDuplicate == "true") {
      this.duplicateAppsController.rejectionSuccess();
    }
    appMessage(this.appId, "index");
  }

  get drawerPortalController() {
    return this.application.getControllerForElementAndIdentifier(
      this.container.parents("div[id^='drawer-portal']")[0],
      "drawer-portal"
    );
  }

  get duplicateAppsController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById(`duplicate-details__card-${this.appId}`),
      "duplicate-apps"
    );
  }
}
