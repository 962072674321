import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static async complete(promptType, data) {
    const httpClient = axios.create({ timeout: 120000 });
    const url = "/manage/open_ai/completions";
    const payload = { prompt_type: promptType, data: data };
    const headers = {
      "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
      "Content-Type": "application/json",
      Accept: "application/json"
    };
    return httpClient.post(url, payload, { headers: headers });
  }
}
