import { Controller } from "@hotwired/stimulus";

let PROGRESS_BAR_TOGGLE;

export default class extends Controller {
  static targets = ["container", "percentage", "progressBar"];

  initialize() {
    PROGRESS_BAR_TOGGLE = this.toggle.bind(this);
  }

  toggle(args) {
    if (args.show == true) {
      this.showProgressBar();
    } else {
      this.hideProgressBar();
    }
  }

  showProgressBar() {
    this.containerTarget.classList.add("wizard_progress_overlay--shown");
  }

  hideProgressBar() {
    this.containerTarget.classList.remove("wizard_progress_overlay--shown");
  }
}

export const showProgressBar = function (args) {
  PROGRESS_BAR_TOGGLE({ ...args, show: true });
};

export const hideProgressBar = function (args) {
  PROGRESS_BAR_TOGGLE({ ...args, show: false });
};
