import Controller from "./remote_modal_controller";
import $ from "jquery";

export default class extends Controller {
  open() {
    const element = document.getElementById(this.data.get("remoteModalId"));
    element.innerHTML = document.getElementById(this.data.get("id")).outerHTML;
    $(element).find(".modal").modal();
  }
}
