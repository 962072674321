import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab"]

  toggle(event) {
    const activeClass = this.element.dataset.activeClass
    const activeIndex = this.tabTargets.indexOf(event.target.closest("a"))
    this.tabTargets.forEach((tab, index) => {
      if (index === activeIndex) {
        tab.classList.add(activeClass)
      } else {
        tab.classList.remove(activeClass)
      }
    });
  }
}
