import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputs"];

  static values = {
    fieldCount: Number,
    existingSlots: String
  };

  connect() {
    this.fieldCountValue = 0;
    this.manageExistingSlots();
  }

  addDateRow(e) {
    e.preventDefault();
    this.inputsTarget.appendChild(this.buildFormRow());
    this.fieldCountValue++;
  }

  buildFormRow(date=null, startTime=null, endTime=null) {
    const holder = document.createElement("div");
    holder.className = "one-time";

    const div = document.createElement("div");
    div.className = "row date-row";

    if (this.fieldCountValue == 0)
      holder.appendChild(document.createElement("hr"));

    holder.appendChild(div);

    const innerDiv = document.createElement("div");
    innerDiv.className = "col-md-12 col-12 mt-1 mb-1";
    innerDiv.appendChild(this.buildDateField(date));

    const timeRowsHolder = document.createElement("div");
    timeRowsHolder.className = "row time-rows";
    timeRowsHolder.appendChild(this.buildTimeRow("first", startTime, endTime));
    innerDiv.appendChild(timeRowsHolder);

    div.appendChild(innerDiv);

    holder.appendChild(document.createElement("hr"));
    return holder;
  }

  buildDateField(date=null) {
    var input = document.createElement("input");
    input.type = "date";
    input.name =
      "autopilot_container[configuration_attributes][timeslots][" +
      this.fieldCountValue +
      "][date]";
    input.id = "date";
    input.placeholder = "mm/dd/yy";
    input.className = "form-control";
    input.setAttribute("data-controller", "flatpickr");
    input.setAttribute("data-flatpickr-min-date", "today");
    input.setAttribute("data-flatpickr-date-format", "m/d/Y");
    if(date){
      input.setAttribute("data-flatpickr-default-date", date)
    }
    var label = document.createElement("label");
    label.htmlFor =
      "autopilot_container[configuration_attributes][timeslots][" +
      this.fieldCountValue +
      "][date]";
    label.innerHTML = "Date*";
    label.className = "form-control-label";

    const dateField = document.createElement("div");
    dateField.className = "row";
    const innerDateField = document.createElement("div");
    innerDateField.className = "col-md-6 col-10";

    innerDateField.appendChild(label);
    innerDateField.appendChild(input);
    dateField.appendChild(innerDateField);

    const deleteBtnDiv = document.createElement("div");
    deleteBtnDiv.className = "col-md-6 col-1 text-right";

    deleteBtnDiv.appendChild(this.rowDeleteBtn());

    dateField.appendChild(deleteBtnDiv);

    return dateField;
  }

  buildTimeRow(type, startTime=null, endTime=null) {
    var row = document.createElement("div");
    row.className = "row col-md-7 col-12 single-time-row";

    var div1 = document.createElement("div");
    div1.className = "col-5";
    var stTime = startTime ? startTime : "09:00";
    div1.appendChild(this.buildTimeField(stTime, "start"));
    var div2 = document.createElement("div");
    div2.className = "col-5 time-row-right";
    var eTime = endTime ? endTime : "17:00";
    div2.appendChild(this.buildTimeField(eTime, "end"));

    row.appendChild(div1);
    row.appendChild(div2);
    if (type == "first") {
      row.appendChild(this.timeAddBtn());
    } else {
      row.appendChild(this.timeRemoveBtn());
    }
    return row;
  }

  buildTimeField(startTime, start_or_end) {
    var input = document.createElement("input");
    input.setAttribute("data-controller", "flatpickr");
    input.setAttribute("data-flatpickr-enable-time", true);
    input.setAttribute("data-flatpickr-no-calendar", true);
    input.setAttribute("data-flatpickr-date-format", "h:i K");
    input.setAttribute("data-flatpickr-default-date", startTime);
    input.type = "time";
    input.value = startTime;
    input.name =
      "autopilot_container[configuration_attributes][timeslots][" +
      this.fieldCountValue +
      "][" +
      start_or_end +
      "]";
    input.className = "form-control";
    return input;
  }

  rowDeleteBtn() {
    const containerDiv = document.createElement("div");
    containerDiv.className = "col-6";
    var deleteBtn = document.createElement("a");
    deleteBtn.className = "btn btn-sm btn-round-light";
    deleteBtn.setAttribute(
      "data-action",
      "click->one-time-availability#removeElement"
    );
    deleteBtn.setAttribute("data-list-number", this.fieldCountValue);
    deleteBtn.innerHTML = "<i class='fa fa-sm fa-trash'></i>";
    deleteBtn.title = "Delete";

    return containerDiv.appendChild(deleteBtn);
  }

  timeAddBtn() {
    var div = document.createElement("div");
    div.className = "col-2";

    var addBtn = document.createElement("a");
    addBtn.className = "btn btn-sm btn-round-light";
    addBtn.setAttribute(
      "data-action",
      "click->one-time-availability#addTimeRow"
    );
    addBtn.innerHTML = "<i class='fa fa-sm fa-plus'></i>";
    addBtn.title = "Add Time Slot";

    div.appendChild(addBtn);
    return div;
  }

  timeRemoveBtn() {
    var div = document.createElement("div");
    div.className = "col-2 d-flex";

    var removeBtn = document.createElement("a");
    removeBtn.className = "btn btn-sm remove-timeslot";
    removeBtn.setAttribute(
      "data-action",
      "click->one-time-availability#removeTimeRow"
    );
    removeBtn.innerHTML = "<i class='far fa-sm fa-times'></i>";
    removeBtn.title = "Remove Time Slot";

    div.appendChild(removeBtn);
    return div;
  }

  removeElement(element) {
    var row = element.target.closest(".one-time");
    row.remove();
  }

  addTimeRow(element) {
    var row = element.target.closest(".time-rows");
    row.appendChild(this.buildTimeRow("add"));
  }

  removeTimeRow(element) {
    var timeRow = element.target.closest(".single-time-row");
    timeRow.remove();
  }

  manageExistingSlots() {
    var existingSlots = JSON.parse(this.existingSlotsValue.replaceAll("=>", ':'))
    console.log(existingSlots)
    for(const [key,value] of Object.entries(existingSlots)) {
      this.addExistingDateRow(value.date, value.start, value.end)
    };
  }

  addExistingDateRow(date, startTime, endTime) {
    this.inputsTarget.appendChild(this.buildFormRow(date, startTime, endTime));
    this.fieldCountValue++;
  }
}
