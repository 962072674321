import { Controller } from "@hotwired/stimulus"
/**
 * Controller for confirming changes before navigating away from a dirty form
 *
 * @example HTML in view (if using Stimulus 2 or later)
 *    data: {
 *      controller: 'navigation-lock',
 *      action: '
 *        input->navigation-lock#registerChange
 *        beforeunload@window->navigation-lock#showCancelAlert
 *        turbo:before-visit@document->navigation-lock#showCancelAlert
 *      '
 *    }
 */
export default class extends Controller {
  static values = {
    hasFormChanges: { type: Boolean, default: false },
  }
  static targets = [ "cancelModal", "editEmployee" ]

  registerChange() {
    this.hasFormChangesValue = true
  }

  showCancelAlert(event) {
    if (this.hasFormChangesValue) {
      var confirm = window.confirm('You have unsaved changes. Are you sure you want leave?')
      if (confirm == true) {
        window.location = event.detail.url
      }
      else {
        event.preventDefault();
      }
    }
  }

  toggleChanges(){
    this.hasFormChangesValue = false
  }
}
