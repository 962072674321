import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["observed", "fireOn"]
  static values = {
    childList: { type: Boolean, default: true },
    subtree: { type: Boolean, default: true },
    fireOnObservedConnect: { type: Boolean, default: true },
    fireOnWindow: { type: Boolean, default: false },
    eventName: { type: String, default: 'mutated' }
  }
  observer;

  observedTargetConnected(element) {
    this.observer = new MutationObserver((_mutations, _observer) => {
      this.fireEvent();
    });
    this.observer.observe(this.observedTarget, { childList: this.childListValue, subtree: this.subtreeValue });
    if (this.fireOnObservedConnectValue) this.fireEvent();
  }

  observedTargetDisconnected(element) {
    this.observer.disconnect();
  }

  fireEvent() {
    this.dispatch(this.eventNameValue, { target: this.eventTarget() })
  }

  eventTarget() {
    if (this.fireOnWindow) return window;
    if (this.hasFireOnTarget)
      return this.fireOnTarget;
    else
      return this.element; // default to the element the controller is defined on
  }
}
