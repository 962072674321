/**
 * Number Input Controller
 * 
 * This Stimulus controller validates and formats number inputs.
 * 
 * Usage example:
 * <input type="text" data-controller="shared--number-input" data-shared--number-input-target="input">
 * 
 * This will create a text input that only allows numeric values, removes leading zeros,
 * and sets the value to '0' if empty.
 */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.validateAndUpdate(this.inputTarget)
  }

  validate(event) {
    this.validateAndUpdate(event.target)
  }

  validateAndUpdate(input) {
    let value = input.value
    // Remove non-digit characters
    value = value.replace(/[^\d]/g, '')
    // Remove leading zeros
    value = value.replace(/^0+/, '')
    input.value = value
  }
}
