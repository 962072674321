import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    this.clear();
  }

  showHint(event) {
    // find any existing active labels and hide them.
    this.clear();
    // show hint'
    $(event.target).siblings(".form-text").show();
  }

  showSelectHint(event) {
    // find any existing active labels and hide them.
    this.clear(event);
    // show hint
    $(event.target).parent().siblings(".form-text").show();
  }

  clear() {
    $("small.form-text").hide();
  }
}
