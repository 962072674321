// Calculates the positioning of a series of items in a list.
// After calculation adds the position to a data attribute on the item targets.
// Then triggers a custom event that can be listened for within other stimulus
// controllers that depend on item positioning.
// - Optional configuration for ignoring hidden elements

import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["item"];

  connect() {
    this.calculate();
  }

  calculate() {
    if (!this.hasItemTarget) return;

    let count = 0;

    this.itemTargets.forEach((element) => {
      if (
        this.data.get("ignoreHidden") == "true" &&
        this.elementHidden(element)
      ) {
        element.dataset.position = 0;
      } else {
        count += 1;
        element.dataset.position = count;
      }

      element.dispatchEvent(new CustomEvent("position-items:update"));
    });
  }

  elementHidden(element) {
    return $(element).is(":hidden");
  }
}
