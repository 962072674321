import { Controller } from "@hotwired/stimulus";
import PointerManager from "../../utils/pointer_manager";

// Arbitrary multiplier that affects how dramatic the zoom is based on the pinch length
const ZOOM_SPEED = 3;

// The Form-Input area is 33% of the screen-- used to calculate height of PDF image area.
const MOBILE_FORM_HEIGHT = 0.33;

// When panning to highlighter, the buffer from the top-left corner
const HIGHLIGHT_BUFFER = 50;

export default class extends Controller {
  static targets = ["highlight"];

  connect() {
    this._pointers = new PointerManager(this.element);
    this._previousPinchDistance = -1;
    this.element.style.touchAction = "pan-y";
    this.addEventHandlers();
    this.trackHighlight();
  }

  disconnect() {
    this._pointers.disconnect();
    this.removeEventHandlers();
  }

  /**
   * Not using stimulus actions because ALL these handlers ALWAYS need to be set exactly this way
   */
  addEventHandlers() {
    this.element.addEventListener("pointermove", this.pointermoveHandler);
    this.element.addEventListener("pointerup", this.pointerupHandler);
    this.element.addEventListener("pointercancel", this.pointerupHandler);
    this.element.addEventListener("pointerout", this.pointerupHandler);
    this.element.addEventListener("pointerleave", this.pointerupHandler);
  }

  removeEventHandlers() {
    this.element.removeEventListener("pointermove", this.pointermoveHandler);
    this.element.removeEventListener("pointerup", this.pointerupHandler);
    this.element.removeEventListener("pointercancel", this.pointerupHandler);
    this.element.removeEventListener("pointerout", this.pointerupHandler);
    this.element.removeEventListener("pointerleave", this.pointerupHandler);
  }

  /* EVENT HANDLERS */

  pointermoveHandler = (_event) => {
    if (this._pointers.count() < 2) {
      this.handlePan();
    } else {
      this.handleZoom();
    }
  };

  pointerupHandler = (_event) => {
    if (this._pointers.count() < 2) {
      this._previousPinchDistance = -1;
      this._zoomStart = null;
    }
    this._previousPan = null;
  };

  /* GESTURES */

  handleZoom() {
    const curDist = this.pointerDistance();

    if (this._previousPinchDistance > 0) {
      this.setZoomStartFromPinch();
      this.zoom = this.calculateZoom(curDist);
    }
    this._previousPinchDistance = curDist;
  }

  handlePan() {
    if (this.zoom <= 1) return;

    if (this._previousPan) {
      this.moveElement(
        this.touchA.clientX - this._previousPan.clientX,
        this.touchA.clientY - this._previousPan.clientY
      );
    }
    this._previousPan = this.touchA;
  }

  /* GETTERS/SETTERS */

  get touchA() {
    return this._pointers.index(0);
  }

  get touchB() {
    return this._pointers.index(1);
  }

  /* Memoized lazily evaluated constant */
  get originalSize() {
    Object.defineProperty(this, "originalSize", {
      value: {
        width: this.element.clientWidth,
        height: this.element.clientHeight
      },
      writable: false
    });
    return this.originalSize;
  }

  get zoom() {
    if (this._zoom == null) this._zoom = 1;
    return this._zoom;
  }

  /**
   * @param {Number} factor - Size of canvas element will be multiplied by this value
   */
  set zoom(factor) {
    this._zoom = Math.max(1, factor);
    if (factor > 1) {
      this.startZoom();
      this.element.style.width = `${Math.round(
        this.originalSize.width * factor
      )}px`;
      this.element.style.height = `${Math.round(
        this.originalSize.height * factor
      )}px`;
      this.recenterFromPinch();
    } else {
      this.stopZoom();
    }
  }

  /* HELPER FUNCTIONS */

  trackHighlight() {
    const parent = this.element.closest('[data-controller~="pdf-form-taker"]');
    if (!parent) return;

    parent.addEventListener("pdf_form_taker:highlight", this.highlightMoved);
  }

  pointerDistance() {
    const diffX = this.touchA.clientX - this.touchB.clientX,
      diffY = this.touchA.clientY - this.touchB.clientY;
    return Math.hypot(diffX, diffY);
  }

  calculateZoom(pinchDistance) {
    return (
      this.zoom +
      ((pinchDistance - this._previousPinchDistance) * ZOOM_SPEED) /
        this.originalSize.width
    );
  }

  moveElement(changeX, changeY) {
    const origLeft = parseInt(this.element.style.left),
      origTop = parseInt(this.element.style.top),
      newLeft = this.boundaryLeft(origLeft + changeX),
      newTop = this.boundaryTop(origTop + changeY);
    this.element.style.left = `${newLeft}px`;
    this.element.style.top = `${newTop}px`;
  }

  highlightMoved = (event) => {
    // allow scrolling if not zoomed
    if (this.zoom <= 1) return;

    event.preventDefault();
    // do not move highlighter while zooming
    if (this._previousPinchDistance > 0) return;

    this.panToHighlight();
  };

  panToHighlight() {
    const destX = Math.round(
        parseFloat(this.highlightTarget.style.left) *
          parseInt(this.element.style.width) *
          0.01
      ),
      destY = Math.round(
        parseFloat(this.highlightTarget.style.top) *
          parseInt(this.element.style.height) *
          0.01
      ),
      newLeft = this.boundaryLeft((destX - HIGHLIGHT_BUFFER) * -1),
      newTop = this.boundaryTop(
        (destY - HIGHLIGHT_BUFFER - this.element.parentElement.offsetTop) * -1
      );
    this.element.classList.add("pdf_form_taker__canvas_wrapper--panning");
    this.element.style.left = `${newLeft}px`;
    this.element.style.top = `${newTop}px`;
    setTimeout(() => {
      this.element.classList.remove("pdf_form_taker__canvas_wrapper--panning");
    }, getComputedStyle(this.element)["transitionDuration"]);
  }

  startZoom() {
    if (this.element.style.position == "absolute") return;

    this.element.style.touchAction = "none";
    this.element.style.position = "absolute";
  }

  stopZoom() {
    if (this.element.style.position == "relative") return;

    this.element.style.touchAction = "pan-y";
    this.element.style.position = "relative";
    this.element.style.width = null;
    this.element.style.height = null;
    this.element.style.left = null;
    this.element.style.top = null;
    this.element.parentElement.scrollTop = this._zoomStart.scroll;
  }

  recenterFromPinch() {
    const zoomedX = Math.round(this._zoomStart.clientX * this.zoom),
      zoomedY = Math.round(this._zoomStart.clientY * this.zoom),
      zoomedScroll = Math.round(
        (this._zoomStart.scroll - this.element.parentElement.offsetTop) *
          this.zoom
      ),
      newLeft = (zoomedX - this._zoomStart.clientX) * -1,
      newTop = (zoomedScroll + zoomedY - this._zoomStart.clientY) * -1;
    this.element.style.left = `${newLeft}px`;
    this.element.style.top = `${newTop}px`;
  }

  setZoomStartFromPinch() {
    if (this._zoomStart) return;

    const pinchX = (this.touchA.clientX + this.touchB.clientX) / 2,
      pinchY = (this.touchA.clientY + this.touchB.clientY) / 2;
    if (this.zoom > 1) {
      const offsetY = Math.round(pinchY * this.zoom) - pinchY,
        unzoomedTop = Math.round(
          (Math.abs(parseInt(this.element.style.top)) - offsetY) / this.zoom
        ),
        scrollTop = unzoomedTop + this.element.parentElement.offsetTop;
      this._zoomStart = {
        scroll: scrollTop,
        clientX: pinchX,
        clientY: pinchY
      };
    } else {
      this._zoomStart = {
        scroll: this.element.parentElement.scrollTop,
        clientX: pinchX,
        clientY: pinchY
      };
    }
  }

  boundaryLeft(proposedLeft) {
    return Math.min(
      0,
      Math.max(
        proposedLeft,
        (this.originalSize.width * this.zoom -
          this.element.parentElement.clientWidth) *
          -1
      )
    );
  }

  boundaryTop(proposedTop) {
    return Math.min(
      this.element.parentElement.offsetTop * this.zoom,
      Math.max(
        proposedTop,
        (this.originalSize.height * this.zoom - this.pdfElementHeight) * -1
      )
    );
  }

  get pdfElementHeight() {
    return (
      this.element.parentElement.clientHeight * (1 - MOBILE_FORM_HEIGHT) +
      this.element.parentElement.offsetTop
    );
  }
}
