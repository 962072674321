import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  disconnect() {
    if (this.iframe) {
      this.closeDialog();
    }
  }

  show() {
    this.build();
  }

  build() {
    this.title = document.title;

    this.iframe = document.createElement("iframe");

    this.iframe.onload = this.openDialog.bind(this);
    this.iframe.style.position = "fixed";
    this.iframe.style.right = "0";
    this.iframe.style.bottom = "0";
    this.iframe.style.width = "0";
    this.iframe.style.height = "0";
    this.iframe.style.border = "0";
    this.iframe.src = this.data.get("url");

    document.body.appendChild(this.iframe);
  }

  openDialog() {
    this.iframe.contentWindow.onbeforeunload = this.closeDialog.bind(this);
    this.iframe.contentWindow.onafterprint = this.closeDialog.bind(this);
    document.title = this.iframe.contentDocument.title;
    this.iframe.contentWindow.focus();
    this.iframe.contentWindow.print();
  }

  closeDialog() {
    document.title = this.title;
    document.body.removeChild(this.iframe);
    this.iframe = null;
  }
}
