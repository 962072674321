const { BroadcastChannel } = require("broadcast-channel");

export function itemMessage(id, payload) {
  return {
    id: id,
    payload: payload
  };
}

export function channel(name) {
  return new BroadcastChannel(name, { webWorkerSupport: false });
}

function channelGenerator(name) {
  return () => channel(`cp.${name}`);
}

function messageGenerator(channelFunc) {
  return (id, payload) => {
    const channel = channelFunc();
    channel.postMessage(itemMessage(id, payload));
    channel.close();
  };
}

const broadcaster = {};

export function ensureChannel(name) {
  if (typeof broadcaster[`${name}Channel`] == "function") return;

  const channel = channelGenerator(name);
  broadcaster[`${name}Channel`] = channel;
  broadcaster[`${name}Message`] = messageGenerator(channel);
}

const defaultChannels = ["app", "job", "portal", "settings"];
defaultChannels.forEach((name) => {
  ensureChannel(name);
});

// deprecated: import broadcast and call broadcast.appChannel()
export function appChannel() {
  return broadcaster.appChannel();
}

// deprecated: import broadcast and call broadcast.appMessage()
export function appMessage(id, payload) {
  return broadcaster.appMessage(id, payload);
}

export default broadcaster;
