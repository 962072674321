import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["extra"];

  toggle(event) {
    if (event.target.dataset.showText) {
      this.extraTargets.forEach((element) => {
        element.classList.add("d-none");
      });
      event.target.innerHTML = event.target.dataset.showText;
      delete event.target.dataset.showText;
    } else {
      this.extraTargets.forEach((element) => {
        element.classList.remove("d-none");
      });
      event.target.dataset.showText = event.target.innerHTML;
      event.target.innerHTML = event.target.dataset.hide;
    }
  }
}
