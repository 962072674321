import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  handle(event) {
    const ENTER_KEY_CODE = 13;
    if (event.keyCode == ENTER_KEY_CODE) {
      event.preventDefault();
    }
  }
}
