import { Controller } from "@hotwired/stimulus";

// Mirrors the selected inputs from one form to another form. You'll need to set the main
// form target and the clone form target and then any inputs you would like to be mirrored across
// forms.
//
// On submit run the mirrorFields function to perform the mirroring
export default class extends Controller {
  static targets = ["main", "clone", "input"];

  mirrorFields() {
    this.inputNames().forEach(name => {
      this.element(this.cloneTarget, name).value = this.elementValue(
        this.element(this.mainTarget, name)
      );
    });

    return true;
  }

  element(form, name) {
    let element = form.elements[name];

    if (element.length !== undefined) {
      element = this.lastElement(element);
    }

    return element;
  }

  elementValue(element) {
    switch (element.type) {
      case "checkbox":
        return element.checked;
      default:
        return element.value;
    }
  }

  lastElement(elements) {
    return elements[elements.length - 1];
  }

  inputNames() {
    return Array.from(this.inputTargets, (input) => input.name);
  }
}
