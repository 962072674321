import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

/**
 * Provides "accordion" functionality to any group of bootstrap-collapsible elements. A parent
 * element is assigned this controller, and this ensures that there is always only one descendant
 * collapsible element open at once.
 */
export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.collapse').forEach((element) => {
      $(element).on("show.bs.collapse", () => {
        this.collapseAllBut(element);
      });
    });
  }

  collapseAllBut(skipElement) {
    this.element.querySelectorAll('.collapse').forEach((element) => {
      if (element != skipElement) {
        $(element).collapse('hide');
      }
    });
  }
}
