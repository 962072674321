import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  triggerResult({ detail: { success } }) {
    let eventName = "failure";
    if (success) eventName = "success";

    this.element.dispatchEvent(
      new CustomEvent(`hotwire-translate:${eventName}`, {
        bubbles: true,
        cancelable: true
      })
    );
  }
}
