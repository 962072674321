export function remoteFormPromise(form) {
  const result = new Promise((resolve, reject) => {
    function success(event) {
      form.removeEventListener("ajax:success", success);
      form.removeEventListener("ajax:error", error);
      resolve(event);
    }
    function error(event) {
      form.removeEventListener("ajax:success", success);
      form.removeEventListener("ajax:error", error);
      reject(event);
    }
    form.addEventListener("ajax:success", success);
    form.addEventListener("ajax:error", error);
  });
  form.dispatchEvent(
    new CustomEvent("submit", { bubbles: true, cancelable: true })
  );
  return result;
}

const valid_error_classes = ["invalid", "warning"];
function isError(level) {
  for (let i = 0; i < valid_error_classes.length; i++) {
    if (valid_error_classes[i] == level) return true;
  }
  return false;
}

export function inputError(input, message, level = "invalid") {
  if (!isError(level)) {
    level = "invalid";
  }
  const wrapper = input.closest(".form-group");
  if (wrapper) {
    input.classList.add("is-invalid");
    let hint = wrapper.querySelector(".invalid-feedback");
    if (hint === null) {
      hint = document.createElement("div");
      hint.classList.add("invalid-feedback");
      input.insertAdjacentElement("afterend", hint);
    }
    hint.innerHTML = message;
    wrapper.classList.add(`form-group-${level}`);
  }
}

export function clearInputError(input) {
  const wrapper = input.closest(".form-group");
  if (wrapper) {
    input.classList.remove("is-invalid");
    const hint = wrapper.querySelector(".invalid-feedback");
    if (hint !== null) {
      hint.parentNode.removeChild(hint);
    }
    valid_error_classes.forEach((level) => {
      wrapper.classList.remove(`form-group-${level}`);
    });
  }
}

/**
 * For stimulus actions, depending on the event, the response is in a different place.
 * @see https://guides.rubyonrails.org/v6.1/working_with_javascript_in_rails.html#rails-ujs-event-handlers
 */
export function ajaxResponse(event) {
  let xhr = null;
  if (event.detail.length > 2) { // ajax:sucess or ajax:error
    xhr = event.detail[2];
  } else { // ajax:beforeSend, ajax:send or ajax:complete
    xhr = event.detail[0];
  }
  return xhr.response;
}
