import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["input", "switch"];
  static values = { enabled: Boolean };
  static disableSelector =
    "button, fieldset, input, optgroup, option, select, textarea";
  static clearSelector = "input";

  connect() {
    this.initializeChecked();
  }

  initializeChecked() {
    if (this.enabledValue) {
      this.switchTarget.checked = true;
      this.enable();
    } else if (this.hasEnabledValue) {
      this.switchTarget.checked = false;
      this.disable();
    }
    setTimeout(() => {
      this.switchTarget.dispatchEvent(new Event("change", { bubbles: true }));
    });
  }

  toggle(_event) {
    if (this.switchTarget.checked) {
      this.enable();
    } else if (this.sendClearedValueOnDisable) {
      this.clearInput();
    } else {
      this.disable();
    }
  }

  toggleByValue(_event) {
    if (this.switchTarget.value !== "") {
      this.enable();
    } else {
      this.disable();
    }
  }

  toggleByData(_event) {
    // jQuery is used because HTMLSelectElement.selectedOptions is not supported in IE
    if ($(this.switchTarget).find(":selected,:checked").data("show")) {
      this.enable();
    } else {
      this.disable();
    }
  }

  enable() {
    if (this.inputTarget.classList.contains("collapse")) {
      $(this.inputTarget).collapse("show");
    }
    this.inputTarget.classList.remove("disabled");
    this.applyCallbackToSelectChildren(
      this.constructor.disableSelector,
      (el) => {
        if (el.getAttribute('disabled') == 'disabled') return;
        el.disabled = false;
      }
    );
  }

  disable() {
    this.hideInput();
    this.inputTarget.classList.add("disabled");
    this.applyCallbackToSelectChildren(
      this.constructor.disableSelector,
      (el) => {
        if (el.getAttribute('disabled') == 'disabled') return;
        el.disabled = true;
      }
    );
  }

  clearInput() {
    this.hideInput();
    this.inputTarget.classList.add("disabled");
    this.applyCallbackToSelectChildren(this.constructor.clearSelector, (el) => {
      el.value = "";
    });
  }

  hideInput() {
    if (this.inputTarget.classList.contains("collapse")) {
      $(this.inputTarget).collapse("hide");
    }
  }

  applyCallbackToSelectChildren(selectors, callback) {
    this.inputTarget.querySelectorAll(selectors).forEach((el) => {
      // do not affect nested optional-input controllers
      if (
        el.closest(".form-group__container--optional-input") == this.element
      ) {
        callback(el);
      }
    });
  }

  get sendClearedValueOnDisable() {
    return this.data.get("send-cleared-value-on-disable");
  }
}
