import { Controller } from "@hotwired/stimulus";
import { toastSuccess, toastDanger } from "../manage/toasts_controller";
import axios from "axios";

export default class extends Controller {
  get container() {
    return $(this.element);
  }

  get appId() {
    return this.data.get("app-id");
  }

  send() {
    let errors = this.validate();

    this.toggleSpinner();
    event.preventDefault();
    if (errors.length > 0) {
      this.error("Please fix issues before submitting", errors.join("\n"));
    } else {
      this.saveEmail();
    }
  }

  toggleSpinner() {
    let submit = $("#submit-" + this.appId + " i");
    submit
      .prop("disabled", function (i, value) {
        return !value;
      })
      .toggleClass("fal fad fa-sync fa-spinner-third fa-check-circle");
  }

  validate() {
    const shareTo = document.getElementById("share-email-to-app-" + this.appId),
      shareSubject = document.getElementById(
        "share-email-subject-app-" + this.appId
      );

    let errors = [];
    shareTo.classList.remove("is-invalid");
    shareSubject.classList.remove("is-invalid");

    if (shareTo.value == "") {
      shareTo.classList.add("is-invalid");
      errors.push("Email must have a recipient");
    }

    return errors;
  }

  saveEmail() {
    const path = this.data.get("path");

    axios
      .post(path + "?" + $("#share-email-form-" + this.appId).serialize())
      .then((response) => {
        this.successNotice();
      })
      .catch((response) => {
        this.error("Something went wrong", response);
      });
  }

  error(errorTitle, errorMessage) {
    this.toggleSpinner();
    toastDanger({ title: errorTitle, body: errorMessage });
  }

  successNotice() {
    this.toggleSpinner();
    this.drawerPortalController.hide();
    toastSuccess({
      title: `Applicant shared`,
      body: `Applicant was successfully shared`
    });
  }

  get drawerPortalController() {
    return this.application.getControllerForElementAndIdentifier(
      this.container.parents("div[id^='drawer-portal']")[0],
      "drawer-portal"
    );
  }
}
