import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const element = document.querySelector('.onboarding_hire_form_outer_container');
    if (element) {
      element.scrollIntoView({ behavior: 'instant' });
    }
  }
}
