import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    element: String
  }

  scroll(event) {
    if (event.target.scrollTop == 0) {
      this.removeClass('scrolled-down')
      this.addClass('scrolled-top')
    } else {
      this.removeClass('scrolled-top')
      this.addClass('scrolled-down')
    }
  }

  removeClass(name) {
    this.withElement((el) => el.classList.remove(name))
  }

  addClass(name) {
    this.withElement((el) => el.classList.add(name))
  }

  withElement(fn) {
    const el = document.getElementById(this.elementValue);
    if (el) fn(el)
  }
}
