/*
************************************************************************************************

Usage notes:
  The intention of this controllers is to allow form fields to be toggled on or off.
  Usage has been implemented around the showing / hiding of a bootstrap modal. However,
  the methods are exposed and could be used in other context as well. The modal is an optional trigger.

  The idea is that you may have some form fields in a modal, or other element, that you do not want to be
  sent as part of the form payload unless the form is shown and handling the submission of the form.

************************************************************************************************
*/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    if (this.hasModalTarget) {
      $(this.modalTarget).on("shown.bs.modal", this.enable);
      $(this.modalTarget).on("hidden.bs.modal", this.disable);
    }
  }

  disconnect() {
    if (this.hasModalTarget) {
      $(this.modalTarget).on("shown.bs.modal", this.enable);
      $(this.modalTarget).off("hidden.bs.modal", this.disable);
    }
  }

  enable = () => {
    this.allFields((input) => input.removeAttribute("disabled"));
  };

  disable = () => {
    this.allFields((input) => input.setAttribute("disabled", "disabled"));
  };

  allFields(callback) {
    const element = this.hasModalTarget ? this.modalTarget : this.element;
    element.querySelectorAll("input, select").forEach(callback);
  }
}
