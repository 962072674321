import { Controller } from "@hotwired/stimulus";

/***
 *
 * A Stimulus controller used to make all external links within it's scope open
 * external to the current tab (it makes them open in a new tab/window).
 * @extends Controller
 *
 * @example <caption>Basic Usage</caption>
 *
 * // Any external links (`a` tags) contained in the rich text will have
 * // `target='_blank'` set on them so that they open in new tab/window.
 * <div data-controller='external-links'>
 *   @facade.rich_text_instructions
 * </div>
 *
 ***/
export default class extends Controller {
  connect() {
    this.element.querySelectorAll('a').forEach(function(link) {
      if (link.host !== window.location.host) {
        link.target = "_blank"
      }
    })
  }
}
