import { Controller } from "@hotwired/stimulus";
import { showSpinner, hideSpinner } from "../manage/loading_spinner_controller";

const ORIGINAL_STORAGE = "data-original-button-content";

function disableClick(ev) {
  ev.stopPropagation();
  ev.preventDefault();
  ev.target.disabled = true;
}

export default class extends Controller {
  disable() {
    if (this.fullPageSpinner) {
      this.fullPageSpinnerTimeout = setTimeout(
        () => showSpinner(this.fullPageSpinnerData),
        1000
      );
    }

    this.withSubmitButtons((button) => {
      if (!this.fullPageSpinner) {
        this.timeout = setTimeout(this.replaceButtonContent.bind(this), 1000);
      }
      button.classList.add("disabled");
      button.addEventListener("click", disableClick);
    });
  }

  replaceButtonContent() {
    this.withSubmitButtons(this.replaceSingleButton.bind(this));
  }

  replaceSingleButton(button) {
    if (button.classList.contains("disabled")) {
      if (!button.hasAttribute("data-original-button-content")) {
        button.setAttribute("data-original-button-content", button.innerHTML);
      }

      if (button.getAttribute("data-waiting")) {
        button.innerHTML = button.getAttribute("data-waiting");
      } else {
        button.innerHTML =
          '<i class="fad fa-spinner-third fa-spin"></i> Saving…';
      }
    }
  }

  reset() {
    clearTimeout(this.timeout);
    clearTimeout(this.fullPageSpinnerTimeout);
    this.withSubmitButtons(this.resetSingleButton.bind(this));
    this.withOpenDrawerSubmitButtons(this.resetSingleButton.bind(this));
  }

  resetSingleButton(button) {
    button.classList.remove("disabled");
    button.removeEventListener("click", disableClick);
    button.disabled = false;
    if (button.hasAttribute(ORIGINAL_STORAGE)) {
      button.innerHTML = button.getAttribute(ORIGINAL_STORAGE);
      button.removeAttribute(ORIGINAL_STORAGE);
    }
  }

  withSubmitButtons(callback) {
    this.findSubmitButtons().forEach((button) => {
      callback(button);
    });
  }

  externalSubmitButtons() {
    return document.querySelectorAll(
      `button[form="${this.element.id}"], input[form="${this.element.id}"]`
    );
  }

  internalSubmitButtons() {
    return this.element.querySelectorAll(
      `button[type="submit"], input[type="submit"]`
    );
  }

  /**
   * Many forms use the Drawer Portal controller's submitPrimaryForm to target
   * a form in a drawer from either the header or footer of the drawer. While
   * these could use the HTML form attribute to target the form by ID (see
   * externalSubmitButtons), in some cases the drawer helper is easier. This
   * finds those buttons.
   */
  drawerSubmitButtons() {
    const drawer = this.element.closest(".voyager-drawer");
    if (!drawer) return [];

    return drawer.querySelectorAll(
      'button[data-action*="drawer-portal#submitPrimaryForm"]'
    );
  }

  /**
   * For reset, the original form has already been replaced and thus
   * this.element is no longer inside a drawer. This ensure that the orphaned
   * drawer's buttons are reset properly.
   */
  withOpenDrawerSubmitButtons(callback) {
    const openDrawer = document.querySelector(".voyager-drawer__container--open");
    if (!openDrawer) return;

    openDrawer.querySelectorAll(
      'button[data-action*="drawer-portal#submitPrimaryForm"]'
    ).forEach((button) => {
      callback(button);
    });
  }

  findSubmitButtons() {
    const result = [];
    this.externalSubmitButtons().forEach((el) => {
      result.push(el);
    });
    this.internalSubmitButtons().forEach((el) => {
      result.push(el);
    });
    this.drawerSubmitButtons().forEach((el) => {
      result.push(el);
    });
    return result;
  }

  get fullPageSpinner() {
    return this.data.get("full-page-spinner") === "true";
  }

  get fullPageSpinnerData() {
    let data = this.data.get("full-page-spinner-data");
    if (data) return JSON.parse(data);

    return {};
  }
}
