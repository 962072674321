import "@stimulus/polyfills";
import "./polyfills";
import "./utils/active_http_request";

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import "@hotwired/turbo-rails";
import axios from "axios";
import Flatpickr from "stimulus-flatpickr";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

TimeAgo.addDefaultLocale(en);

import "bootstrap-select";
require("@rails/ujs").start();

axios.defaults.headers.common["X-CSRF-Token"] = document
  .querySelector("meta[name=csrf-token]")
  .getAttribute("content");

const application = Application.start();
const sharedContext = require.context("./controllers/shared", true, /\.js$/);
const manageContext = require.context("./controllers/manage", false, /\.js$/);
const viewComponentContext = require.context("./controllers/view_components", true, /\.js$/);

application.load(definitionsFromContext(sharedContext));
application.load(definitionsFromContext(manageContext));
application.load(definitionsFromContext(viewComponentContext));

// Manually register Flatpickr as a stimulus controller
application.register("flatpickr", Flatpickr);

export const stimulusApplication = application;
