import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

/**
 * Swaps the open/closed class of a bootstrap-collapse trigger to alter what is shown based on
 * whether the collapse is open or closed.
 */
export default class extends Controller {
  static targets = ['trigger', 'collapsible'];
  static values = {
    open: String,
    closed: String
  };

  connect() {
    $(this.collapsibleTarget).on('show.bs.collapse', this.onShow);
    $(this.collapsibleTarget).on('hide.bs.collapse', this.onHide);
  }

  disconnect() {
    $(this.collapsibleTarget).off('show.bs.collapse', this.onShow);
    $(this.collapsibleTarget).off('hide.bs.collapse', this.onHide);
  }

  onShow = () => {
    this.triggerTarget.classList.remove(this.closedValue);
    this.triggerTarget.classList.add(this.openValue);
  };

  onHide = () => {
    this.triggerTarget.classList.remove(this.openValue);
    this.triggerTarget.classList.add(this.closedValue);
  };
}
