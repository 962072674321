import { Controller } from "@hotwired/stimulus";
var pdfjs = require("pdfjs-dist/build/pdf.js");
var PdfjsWorker = require("worker-loader?inline=true!pdfjs-dist/build/pdf.worker.js");

if (typeof window !== "undefined" && "Worker" in window) {
  pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker();
}

export default class extends Controller {
  static targets = ["canvas", "pdfElement"];

  connect() {
    this.load();
  }

  load() {
    const pdfUrl = this.data.get("url");

    if (pdfUrl === "") {
      return;
    }

    pdfjs
      .getDocument(pdfUrl)
      .promise.then((pdf) => {
        const pdfElement = this.pdfElementTarget;
        let pageNum = 0;
        while (pageNum < pdf.numPages) {
          pageNum++;
          pdf.getPage(pageNum).then((page) => {
            const scale =
              (this.element.offsetWidth * window.devicePixelRatio) /
              page.getViewport({ scale: 1.0 }).width;

            // we will default to a scale of 2 for any element that is
            // hidden on initial rendering into the DOM e.g., the new apps review workflow
            const viewport = page.getViewport({ scale: scale * 2 || 2 });

            const content = `<div class="page pdf-page-wrapper-${page.pageIndex}"><canvas class="pdf-page pdf-page-${page.pageIndex}"></canvas></div>`;
            pdfElement.insertAdjacentHTML("beforeend", content);
            const canvas = this.element.querySelector(
              `.pdf-page-${page.pageIndex}`
            );
            const wrapper = this.element.querySelector(
              `.pdf-page-wrapper-${page.pageIndex}`
            );
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            canvas.style.width = "100%";
            canvas.style.height = "auto";
            wrapper.style.width = "100%";
            wrapper.style.height = "auto";
            page.render({
              canvasContext: canvas.getContext("2d"),
              viewport: viewport
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  zoomIn(e) {
    e.preventDefault();
    let newZoomValue = this.currentZoomValue + 25;

    if (newZoomValue <= 200) {
      this.canvasTarget.style.width = `${newZoomValue}%`;
      this.data.set("zoom", `${parseInt(newZoomValue)}`);
    }
  }

  zoomOut(e) {
    e.preventDefault();
    let newZoomValue = this.currentZoomValue - 25;

    if (newZoomValue >= 100) {
      this.canvasTarget.style.width = `${newZoomValue}%`;
      this.data.set("zoom", `${parseInt(newZoomValue)}`);
    }
  }

  get currentZoomValue() {
    return parseInt(this.data.get("zoom"));
  }
}
