import { Controller } from "@hotwired/stimulus";

export function disableChildren(parent, status) {
  parent.querySelectorAll("input,select,textarea").forEach((input) => {
    input.disabled = status;
  });
}

export default class extends Controller {
  lock() {
    disableChildren(this.element, true);
  }

  unlock() {
    disableChildren(this.element, false);
  }
}
