import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (!this.data.has("controller")) return;

    document.body.setAttribute(
      "data-controller",
      [
        document.body.getAttribute("data-controller"),
        this.data.get("controller")
      ].join(" ")
    );
  }

  disconnect() {
    if (!this.data.has("controller")) return;

    document.body.setAttribute(
      "data-controller",
      document.body
        .getAttribute("data-controller")
        .split(" ")
        .filter((controller) => {
          return controller != this.data.get("controller");
        })
        .join(" ")
    );
  }
}
