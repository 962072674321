import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "videoToPlay"]

  connect() {
    if (this.hasModalTarget) {
      $(this.modalTarget).on("shown.bs.modal", this.playVideo);
      $(this.modalTarget).on("hidden.bs.modal", this.pauseVideo);
    }
  }

  playVideo = () => {
    this.videoToPlayTarget.play();
  }

  pauseVideo() {
    this.videoToPlayTarget.pause();
  }
}
