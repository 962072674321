import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  rowLink(event) {
    if (event.target.tagName === 'A' || event.target.closest('a')) {
      return;
    }

    window.location.href = this.urlValue;
  }
}
