import Controller from "./modal_collection_controller";

export default class extends Controller {
  static targets = [
    "deselectAllText",
    "inputWrapper",
    "primarySelector",
    "selectAllText",
    "selectedCount",
    "selectionWrapper"
  ];

  connect() {
    super.connect();
    this.updateDom();
  }

  disconnect() {
    clearTimeout(this.toolTipTimeout);
  }

  locationSelected(evt) {
    const currentlySelected = evt.target.value;
    this.allInputs((input) => {
      if (input.value === currentlySelected) {
        input.disabled = true;
        input.checked = false;
      } else {
        input.disabled = false;
      }
    });
    this.updateDom();
  }

  cancel() {
    super.cancel();
    this.updateDom();
  }

  updateDom() {
    this.handleDisabledState();
    this.updateSelected();
    this.adjustText();
  }

  handleDisabledState() {
    if (!this.hasPrimarySelectorTarget || !this.hasSelectionWrapperTarget)
      return;
    const disabled = !this.primarySelectorTarget.value;
    this.toggleDisabled(disabled);
    this.toggleOpacity(disabled);
    if (disabled) this.reset();
    this.toggleToolTip(disabled);
  }

  toggleDisabled(disabled) {
    this.selectionWrapperTarget.querySelectorAll("*").forEach((node) => {
      if (disabled) {
        node.setAttribute("disabled", disabled);
      } else {
        node.removeAttribute("disabled");
      }
    });
  }

  toggleOpacity(disabled) {
    if (disabled) {
      this.selectionWrapperTarget.classList.add("opacity-5");
    } else {
      this.selectionWrapperTarget.classList.remove("opacity-5");
    }
  }

  toggleToolTip(disabled) {
    this.toolTipTimeout = setTimeout(() => {
      const ctrl = this.application.getControllerForElementAndIdentifier(
        this.selectionWrapperTarget,
        "tooltip"
      );
      if (ctrl) ctrl.toggle(!disabled);
    });
  }
}
