import { Controller } from "@hotwired/stimulus";
import cableStore from "../../utils/cable_subscriber";

export default class extends Controller {
  static targets = ["contactDrawerBtn"]

  connect() {
    cableStore.subscribe(this);
  }

  disconnect() {
    cableStore.unsubscribe(this);
  }

  cableChannel() {
    return {
      channel: "ApplicationAlertsChannel",
      user_id: this.userId
    };
  }

  cableMessage({ msg, data }) {
    switch (msg) {
      case "Received":
        if (data.id != this.id) return;
        this.render(data.markup);
    }
  }

  render(markup) {
    this.element.outerHTML = markup;
  }

  openContactDrawer() {
    this.contactDrawerBtnTarget.click();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  get userId() {
    return this.alert.user_id;
  }

  get id() {
    return this.alert.id;
  }

  get alert() {
    return JSON.parse(this.element.dataset.alert);
  }
}
