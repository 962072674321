import Controller from "./remote_modal_controller";

export default class extends Controller {
  connect() {
    super.connect();
    document.querySelectorAll(`[form="${this.element.id}"]`).forEach((el) => {
      el.addEventListener("click", this.enableRemote);
    });
  }

  disconnect() {
    super.disconnect();
    document.querySelectorAll(`[form="${this.element.id}"]`).forEach((el) => {
      el.removeEventListener("click", this.enableRemote);
    });
  }

  enableRemote = (event) => {
    this.lastTriggerClicked = event.target;

    if (event.target.getAttribute("remote") === "true") {
      this.element.setAttribute("data-remote", "true");
    } else {
      this.element.setAttribute("data-remote", "false");
    }
  };

  loadingElement() {
    this.lastTriggerClicked;
  }

  before() {
    this.loading = true;
  }

  complete(event) {
    const xhr = event.detail[0];
    if (xhr.status === 200) {
      this.loading = false;
      const element = document.getElementById(this.data.get("remoteModalId"));
      element.innerHTML = xhr.responseText;
      $(element).find(".modal").modal();
    }
    this.triggerButtonReset();
  }

  triggerButtonReset() {
    this.element.dispatchEvent(new CustomEvent("reset-buttons"));
  }
}
