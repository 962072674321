import Controller from "./remote_modal_controller";
import $ from "jquery";
import { ajaxResponse } from "../../utils/forms.js";

export default class extends Controller {
  launchModal(evt) {
    const element = document.getElementById(this.data.get("remoteModalId"));
    element.innerHTML = ajaxResponse(evt);
    $(element).find(".modal").modal();
  }
}
