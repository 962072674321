import { Controller } from "@hotwired/stimulus";

const MAX_FILE_SIZE = 10485760;

export default class extends Controller {
  static targets = ["file", "submit", "errors"];
  static fileSizeMessage = "File size too large. Max allowed: 10MB.";

  handleChange() {
    this.checkForFile();
    this.checkFileSize()
  }

  checkForFile() {
    if (this.fileTarget.files[0]) {
      this.enableTarget();
    } else {
      this.disableTarget();
    }
  }

  checkFileSize() {
    if (this.fileTarget.files[0] && this.fileTarget.files[0].size > MAX_FILE_SIZE) {
      this.errorsTarget.classList.remove("d-none");
      this.errorsTarget.innerHTML = this.constructor.fileSizeMessage;
      this.disableTarget();
    }
  }

  disableTarget() {
    this.submitTarget.classList.add("disabled")
    this.submitTarget.disabled = true;
  }

  enableTarget() {
    this.submitTarget.classList.remove("disabled")
    this.submitTarget.disabled = false;
  }
}
