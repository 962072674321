import { Controller } from "@hotwired/stimulus"

/**
 * Controller to disable an html element once a form is submitted
 *
 * @example HTML in view (if using Stimulus 2 or later)
 *   <%= button_to 'Submit', root_path, class: 'btn btn-primary', form: { "data": { "controller": 'lockable-form', "action": 'submit->lockable-form#lock', lockable_form_disabled_text_value: 'Reminder Sent' }}, data: { lockable_form_target: 'disableable' } %>
 */
export default class extends Controller {
  static targets = [
    'disableable',
  ]
  static values = {
    disabledText: String
  }

/**
 * Disable the element within the form
 *
 * @param {string} event the event from the action calling the function
 */
 lock(/*event*/) {
    this.disableableTarget.disabled = true;
    if(this.hasDisabledTextValue) {
      this.disableableTarget.value = this.disabledTextValue;
    }
  }
}
