import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    $(this.collapsible).on("show.bs.collapse", this.hideTrigger);
    $(this.collapsible).on("hide.bs.collapse", this.showTrigger);
  }

  disconnect() {
    $(this.collapsible).off("show.bs.collapse", this.hideTrigger);
    $(this.collapsible).off("hide.bs.collapse", this.showTrigger);
  }

  hideTrigger = () => {
    this.element.classList.add("d-none");
  };

  showTrigger = () => {
    this.element.classList.remove("d-none");
  };

  get collapsible() {
    return document.getElementById(this.element.href.split("#")[1]);
  }
}
