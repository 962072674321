import Controller from "./scrolling_history_controller";
import $ from "jquery";

export default class extends Controller {
  connect() {
    super.connect();
    $(`#${this.data.get("trigger")}`).on("shown.bs.tab", this.scrollBottom);

    if ($(`#${this.data.get("trigger")}`).hasClass("active")) {
      this.scrollBottom();
    }
  }

  disconnect() {
    $(`#${this.data.get("trigger")}`).off("shown.bs.tab", this.scrollBottom);
  }
}
