import { Controller } from "@hotwired/stimulus";
import * as CronofyElements from "cronofy-elements";
import moment from "moment";
import axios from "axios";

export default class extends Controller {
  static values = {
    name: String,
    redirectUri: String,
    clientId: String,
    avoidLinking: Boolean,
    token: String,
    logLevel: String,
    tzid: String,
    sub: String,
    duration: Number,
    interviewId: Number,
    userId: Number,
    availabilityViewerContext: String,
    primaryCalendarId: String
  };

  initialize() {
    this.base_url = window.location.origin;
  }

  connect() {
    switch (this.nameValue) {
      case "calendar-sync":
        this.loadCalendarSync();
        break;
      case "availability-rules":
        this.loadAvailabilityRules();
        break;
      case "availability-viewer":
        this.loadAvailabilityViewer();
        break;
    }
  }

  loadCalendarSync() {
    CronofyElements.CalendarSync(this.calendarSyncPayload);
  }

  get calendarSyncPayload() {
    let result = {
      target_id: "cronofy-calendar-sync",
      authorization: {
        redirect_uri: this.redirectUriValue,
        client_id: this.clientIdValue,
        scope: "read_events create_event delete_event",
        avoid_linking: this.avoidLinkingValue
      },
      config: {
        revoke_mode: "trigger_and_callback"
      },
      callback: this.handleCallback
    };
    if (this.tokenValue != "") {
      result.element_token = this.tokenValue;
    }
    return result;
  }

  handleCallback = (response) => {
    const notification = response.notification;
    if (notification.type == "profile_revoked") {
      const disconnect_url = this.base_url.concat(
        "/webhook/cronofy/disconnect"
      );

      axios.patch(disconnect_url, {
        id: this.userIdValue
      });
    }
  };

  loadAvailabilityRules() {
    if (this.tokenValue == "") {
      return;
    }
    this.primaryCalendarId = this.primaryCalendarIdValue;
    this.calendarIds = [];
    // Timeout currently seems like the only way to fix an issue with header alignment
    setTimeout(() => {
      CronofyElements.AvailabilityRules({
        target_id: "cronofy-availability-rules",
        element_token: this.tokenValue,
        config: {
          duration: 30,
          logs: this.logLevelValue
        },
        tzid: this.tzidValue,
        callback: this.handleAvailabilityRulesCallback
      });
    }, 200); // This also doesn't work if the timeout is less than this value
  }

  handleAvailabilityRulesCallback = (response) => {
    // Not sure how to get list of calendars on load
    if (response.notification.type == "availability_rule_edited") {
      this.calendarIds = response.availability_rule.calendar_ids;
      this.checkPrimaryCalendarSelected();
    } else if (response.notification.type == "availability_rule_saved") {
      this.updateUserTimezone(response.availability_rule.tzid)
    }

    // Rename Availability Rules labels
    window.onload = function() {
      let availabilityRulesLabels = document.getElementsByClassName("AvailabilityViewer__legend-label");
      if (availabilityRulesLabels) {
        availabilityRulesLabels[0].textContent = "Working Hours";
        availabilityRulesLabels[1].textContent = "Outside working hours";
      }
    };
  };

  checkPrimaryCalendarSelected() {
    const el = document.getElementById("primary-calendar-alert");
    if (!el) return;

    if (
      this.calendarIds.includes(this.primaryCalendarId) ||
      // If 'all' is selected
      this.calendarIds.length == 0
    ) {
      // Don't show warning
      el.style.display = "none";
    } else {
      // Show warning
      el.style.display = "block";
    }
  }

  updatePrimaryCalendarId(event) {
    this.primaryCalendarId = event.detail.primaryCalendarId;
    this.checkPrimaryCalendarSelected();
  }

  loadAvailabilityViewer() {
    CronofyElements.AvailabilityViewer(
      this.availabilityViewerPayload({
        duration: this.durationValue,
        sub: this.subValue,
        tzid: this.tzidValue,
        callback: this.availabilityViewerCallback
      })
    );
  }

  get availabilityViewerCallback() {
    if (this.availabilityViewerContextValue === "reschedule") {
      return this.rescheduleAvailabilityViewerCallback;
    } else if (this.availabilityViewerContextValue === "schedule_now") {
      return this.scheduleNowAvailabilityViewerCallback;
    }
  }

  availabilityViewerPayload({ duration, sub, tzid, callback }) {
    let now = moment().utc();
    let twoWeeks = moment().utc().add(2, "weeks"); // 2 weeks from now

    let result = {
      element_token: this.tokenValue,
      target_id: this.element.id,
      availability_query: {
        participants: [
          {
            required: "all",
            members: [{ sub: sub, managed_availability: true }]
          }
        ],
        required_duration: { minutes: duration },
        query_periods: [
          {
            start: now,
            end: twoWeeks
          }
        ]
      },
      config: {
        duration: 30,
        logs: this.logLevelValue,
        allow_expansion: true
      },
      tzid: tzid
    };

    if (callback) {
      result.callback = callback;
    }
    return result;
  }

  scheduleNowAvailabilityViewerCallback = (response) => {
    if (response.notification.type == "slot_selected") {
      this.updateScheduleNowInterviewTimes(response.notification.slot);
    }
  };

  updateScheduleNowInterviewTimes(slot) {
    // send message to parent schedule now controller
    this.element.dispatchEvent(
      new CustomEvent("updateInterviewTimes", {
        detail: {
          start: slot.start,
          end: slot.end
        },
        bubbles: true
      })
    );
  }

  updateUserTimezone(tzid) {
    axios.patch('/webhook/cronofy/update_timezone',
      {
        id: this.userIdValue,
        tzid: tzid
      }
    );
  }

  rescheduleAvailabilityViewerCallback = (response) => {
    if (response.notification.type == "slot_selected")
      this.rescheduleInterview(response.notification);
  };

  rescheduleInterview(notification) {
    document.getElementById("reschedule-interview-start").value =
      notification.slot.start;
    document.getElementById("reschedule-interview-end").value =
      notification.slot.end;
    document.getElementById("submit-autopilot-reschedule").click();
  }
}
