import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shortUrl"]

  copy(evt) {
    evt.preventDefault();
    const el = document.createElement("textarea");
    el.style.height = 0;
    el.style.width = 0;
    el.style.overflow = "hidden";
    el.value = this.hasShortUrlTarget ? this.shortUrl : this.data.get("value");
    document.body.append(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  get shortUrl() {
    return this.shortUrlTarget.value
  }
}
