import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editor", "toolbarPart", "dialog"];

  connect() {
    this._lookupTable = JSON.parse(this.data.get("lookup"));
    this.hydrateTags();
    this.toolbarPartTargets.forEach((part) => {
      part.classList.remove("trix-toolbar-part--inactive");
    });
    this.insertDialog();
  }

  disconnect() {
    this.toolbarPartTargets.forEach((part) => {
      part.classList.add("trix-toolbar-part--inactive");
    });
  }

  get editor() {
    return this.editorTarget.editor;
  }

  openDialog() {
    this.dialogTarget.classList.remove("d-none");
  }

  closeDialog() {
    this.dialogTarget.classList.add("d-none");
  }

  select(event) {
    event.preventDefault();
    this.closeDialog();
    this.insertTag(
      event.target.innerHTML,
      event.target.getAttribute("data-model"),
      event.target.getAttribute("data-value")
    );
  }

  insertTag(label, model, expression) {
    this.editor.insertAttachment(
      new Trix.Attachment({
        expression: expression,
        content: `<span class="${model}">${label}</span>`
      })
    );
  }

  hydrateTags() {
    let findTag = null;
    if (this.data.get("admin") == "true") {
      findTag = /{{([A-Za-z_.\s]+?)}}/g;
    } else {
      findTag = /{{(.+?)}}|{%(.+?)%}/g;
    }
    const content = this.editor.getDocument().toString();
    let singleResult;
    const combinedResults = [];
    while ((singleResult = findTag.exec(content)) !== null) {
      let content;
      if (singleResult[1]) {
        content = singleResult[1];
      } else {
        content = singleResult[2];
      }
      combinedResults.push({
        start: singleResult.index,
        end: singleResult.index + singleResult[0].length,
        tag: singleResult[0],
        content: content.trim()
      });
    }
    combinedResults.sort((a, b) => {
      return b.start - a.start;
    });
    combinedResults.forEach((item) => {
      this.editor.setSelectedRange([item.start, item.end]);
      const data = this._lookupTable[item.tag];
      if (data) {
        this.insertTag(data.label, data.model, item.tag);
      } else {
        this.insertTag(item.content, "unknown", item.tag);
      }
    });
  }

  insertDialog() {
    if (this.hasDialogTarget) return;

    this.element
      .querySelector("[data-trix-dialogs]")
      .insertAdjacentHTML("beforeend", this.data.get("dialog"));
  }
}
