import { Controller } from "@hotwired/stimulus";
import cableStore from "../../utils/cable_subscriber";
import axios from "axios";

export default class extends Controller {
  static targets = ["button"];
  static values = {
    path: String,
    accountId: Number
  };

  connect() {
    cableStore.subscribe(this);
  }

  disconnect() {
    cableStore.unsubscribe(this);
  }

  cableChannel() {
    return {
      channel: "AutopilotSetupButtonChannel",
      id: this.accountIdValue
    };
  }

  cableMessage({ msg, _data }) {
    switch (msg) {
      case "Received":
        this._refreshButton();
        break;
    }
  }

  _refreshButton = async () => {
    const { data } = await axios.get(this.pathValue);
    this.element.outerHTML = data;
  };

  hide(event) {
    event.preventDefault();
    this.buttonTarget.classList.add("d-none");
  }
}
