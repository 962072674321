import { Controller } from "@hotwired/stimulus";
import cableStore from "../../utils/cable_subscriber";
import axios from "axios";
import broadcaster from "../../utils/broadcast";
import { portal } from "../shared/drawer_portal_controller";

const indicatorClass = "sms__unread_indicator";

export default class extends Controller {
  static targets = ["holder"];

  connect() {
    cableStore.subscribe(this);
    this.channel = broadcaster.portalChannel();
    portal(this.element, this.application, (portal) => {
      this.portalMessage = this.portalMessage.bind(this, portal.element.id);
      this.channel.addEventListener("message", this.portalMessage);
      if (portal.status === "opened") {
        this.readAllIfShown();
      }
    });
  }

  disconnect() {
    cableStore.unsubscribe(this);
    this.channel.removeEventListener("message", this.portalMessage);
    this.channel.close();
  }

  portalMessage(id, message) {
    if (message.id !== id) return;

    switch (message.payload.opened) {
      case true:
        this.readAllIfShown();
      default:
        return;
    }
  }

  cableChannel() {
    return {
      channel: "SmsThreadUpdatesChannel",
      id: Number(this.data.get("thread"))
    };
  }

  cableMessage({ msg, data }) {
    switch (msg) {
      case "Received":
        const response = JSON.parse(data.response);
        if (response.read_at == null) {
          this.markUnread();
        }
        return;
      case "MarkRead":
        this.markRead();
        return;
    }
  }

  withIndicatorHolder(callback) {
    if (this.hasHolderTarget) {
      callback(this.holderTarget);
    } else {
      callback(this.element);
    }
  }

  markUnread() {
    this.withIndicatorHolder((el) => {
      el.classList.add(indicatorClass);
    });
  }

  markRead() {
    this.withIndicatorHolder((el) => {
      el.classList.remove(indicatorClass);
    });
  }

  readAllIfShown() {
    if (this.element.getAttribute("aria-selected") === "true") {
      this.readAll();
    }
  }

  async readAll() {
    await axios.patch(`/manage/sms_threads/${this.data.get("thread")}`);
    cableStore.sendMessage(this, {
      msg: "MarkRead",
      data: null
    });
  }
}
