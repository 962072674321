import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkboxes", "toggleAll"];

  toggleAll() {
    this.removeMixedClass();

    if (this.toggleAllTarget.checked) {
      this.checkAll();
    } else {
      this.uncheckAll();
    }
    this.setDrawer();
  }

  checkAll() {
    this.checkboxesTargets.forEach((target) => (target.checked = true));
  }

  uncheckAll() {
    this.checkboxesTargets.forEach((target) => (target.checked = false));
  }

  clearSelection() {
    this.uncheckAll();
  }

  get selectedIds() {
    return this.checkboxesTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);
  }

  setToggleAllStatus() {
    let checked = event.currentTarget.checked;
    this.toggleAllTarget.checked = checked;
    this.removeMixedClass();

    for (var i = 0; i < this.checkboxesTargets.length; i++) {
      if (this.checkboxesTargets[i].checked != checked) {
        this.toggleAllTarget.checked = true;
        this.addMixedClass();
        break;
      }
    }
  }

  setDrawer() {
    if (this.toggleAllTarget.checked) {
      if (this.selectedIds.length > 0) {
        this.showDrawer();
      }
    } else {
      this.hideDrawer();
    }
  }

  setToggle() {
    if (this.selectedIds.length === 0) {
      this.toggleAllTarget.checked = false;
      this.removeMixedClass();
      this.hideDrawer();
    }
  }

  addMixedClass() {
    this.toggleAllLabel.addClass("mixed");
  }

  removeMixedClass() {
    this.toggleAllLabel.removeClass("mixed");
  }

  deselectApplicantColumn() {
    this.hideDrawer();
    this.removeMixedClass();
    this.toggleAllTarget.checked = false;
    let filterEventTarget = $('#filters-drawer')[0];
    this.toggleFilterController.hideFilterSideBar(filterEventTarget);
  }

  get toggleAllLabel() {
    return $(this.toggleAllTarget).parent().find("label");
  }

  hideDrawer() {
    this.drawerController.hide();
  }

  showDrawer() {
    this.bulkActionsDrawerController.validate();
    this.drawerController.show();
    this.drawerController.portal.allowFocus();
  }

  get toggleFilterController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("bulk-action-sidebar-filter"),
      "toggle-filter-sidebar"
    );
  }

  get drawerController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("app_bulk-actions-drawer"),
      "drawer"
    );
  }

  get bulkActionsDrawerController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector("[data-controller~='bulk-actions-drawer']"),
      "bulk-actions-drawer"
    );
  }
}
