import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    // Extract digits and initialize the format on page load
    this.formatInitialValue();
  }

  formatInitialValue() {
    let input = this.inputTarget;
    if (input.value) {
      // Remove non-digits and ensure only the first 9 are kept
      let cleaned = input.value.replace(/\D/g, "").slice(0, 10);
      // Format and update the input value
      input.value = this.formatPhoneNumber(cleaned);
    }
  }

  formatPhoneNumber(numbers) {
    let format = "";
    let char = { 0: "(", 3: ") ", 6: "-" };
    for (let i = 0; i < numbers.length; i++) {
      format += (char[i] || "") + numbers[i];
    }
    return format;
  }

  format(event) {
    let input = this.inputTarget;
    let numbers = input.value.replace(/\D/g, "").slice(0, 10);
    input.value = this.formatPhoneNumber(numbers);
  }
}
