import { Controller } from "@hotwired/stimulus"
/**
 * Controller for adding show/hide functionality to any password form field
 *
 * @example HTML in view (if using Stimulus 2 or later)
 *   <div data-controller="password-visibility">
 *
 *     <input type="password" data-password-visibility-target="input" />
 *
 *     <button type="button" data-action="password-visibility#toggle">
 *       <span data-password-visibility-target="showOption">Show</span>
 *       <span data-password-visibility-target="hideOption">Hide</span>
 *     </button>
 *   </div>
 */
export default class extends Controller {
  static targets = ['input', 'showOption', 'hideOption']

  connect() {
    this.hidden = this.inputTarget.type === 'password'

    this.updateOptionVisibility()
  }

/**
 * Toggle the visibility of the password field
 *
 * @param {string} event the event from the action calling the function
 */
  toggle(event) {
    event.preventDefault()

    this.inputTarget.type = this.hidden ? 'text' : 'password'
    this.hidden = !this.hidden

    this.updateOptionVisibility()
  }

  // Private

  updateOptionVisibility() {
    this.hideOptionTarget.hidden = this.hidden
    this.showOptionTarget.hidden = !this.hidden
  }
}
