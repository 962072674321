// Super lightweight version of the compensation controller we use for job postings
// Initially used to control the salary input for the ADP WFN export form.
import { Controller } from "@hotwired/stimulus";
import AutoNumeric from "autonumeric";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    // Convert initial value from cents to dollars and clean-up decimals/commas
    const sanitizedValue = this.inputTarget.value.replace(/[,.]/g, '');

    const initialValue = sanitizedValue
      ? parseInt(sanitizedValue, 10) / 100
      : "";

    // Initialize AutoNumeric with the converted value
    this.autonumeric = new AutoNumeric(this.inputTarget, {
      decimalPlaces: 2,
      decimalCharacter: ".",
      digitGroupSeparator: ",",
      emptyInputBehavior: "null",
      // Set the initial value in the AutoNumeric configuration
      initialValue: initialValue
    });

    // Update the input with the formatted value
    this.autonumeric.set(initialValue);
  }

  disconnect() {
    if (this.autonumeric) {
      this.autonumeric.remove();
      this.autonumeric = null;
    }
  }
}
