import { Controller } from "@hotwired/stimulus";
import { getScrollParent } from "../../utils/scrolling";

/**
 * During `connect`, scrolls a form to the top of the page
 */
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.scrollToTop();
    }, 10);
  }

  scrollToTop() {
    const parent = getScrollParent(this.element);
    if (parent) {
      parent.scrollTo({
        top: 0,
        behavior: this.scrollBehavior
      });
    }
  }

  get scrollBehavior() {
    if (this.data.has("behavior")) return this.data.get("behavior");
    return "smooth";
  }
}
