import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { showSpinner, hideSpinner } from "../manage/loading_spinner_controller";

export default class extends Controller {
  static targets = ["content"];

  handleChange(evt) {
    this.timeout = setTimeout(() => {
      showSpinner();
    }, 1000);
    this.changeSelectValue(evt.target);
  }

  async changeSelectValue(target) {
    try {
      let { data } = await axios.get(this.deriveValue(target));

      if (this.hasContentTarget) {
        this.contentTarget.innerHTML = data;
      }
    } finally {
      hideSpinner();
      clearTimeout(this.timeout);
    }
  }

  /*
      It is possible that a select box may need to be both used for
      selecting an actual value as well as triggering a re-render.
      In that case setting remotePath on the child
      <option></option> tags will allow us to still set value on the select input.
    */
  deriveValue(target) {
    let value = target.value;
    let child = target.querySelector("option:checked");

    return (child && child.dataset.remotePath) || value;
  }
}
