/**
 * Manages the set of pointers (or fingers) currently pressed on the given element.
 * Finger-presses are stored as pointer-events.
 */
export default class PointerManager {
  constructor(element) {
    this._pointers = {};
    this._element = element;
    this._element.addEventListener("pointerdown", this._pointerdownHandler);
    this._element.addEventListener("pointermove", this._pointermoveHandler);
    this._element.addEventListener("pointerup", this._pointerupHandler);
    this._element.addEventListener("pointercancel", this._pointerupHandler);
    this._element.addEventListener("pointerout", this._pointerupHandler);
    this._element.addEventListener("pointerleave", this._pointerupHandler);
  }

  /**
   * De-register event handlers. Similar to a destructor
   */
  disconnect() {
    this._element.removeEventListener("pointerdown", this._pointerdownHandler);
    this._element.removeEventListener("pointermove", this._pointermoveHandler);
    this._element.removeEventListener("pointerup", this._pointerupHandler);
    this._element.removeEventListener("pointercancel", this._pointerupHandler);
    this._element.removeEventListener("pointerout", this._pointerupHandler);
    this._element.removeEventListener("pointerleave", this._pointerupHandler);
    this._element = null;
  }

  /**
   * Add or Update a PointerEvent
   *
   * @param {PointerEvent} event Finger press to track
   */
  set(event) {
    this._pointers[event.pointerId] = event;
  }

  /**
   * Retrieve PointerEvent
   *
   * @param {PointerEvent} event Finger press to retrieve
   */
  get(event) {
    this._pointers[event.pointerId] = event;
  }

  /**
   * Remove a PointerEvent
   *
   * @param {PointerEvent} event Finger press to stop tracking
   */
  remove(event) {
    delete this._pointers[event.pointerId];
  }

  /**
   * @returns {Number} Current number of fingers pressed at the moment
   */
  count() {
    return Object.keys(this._pointers).length;
  }

  /**
   * Retrieve a finger-press by index
   *
   * @param {Number} index
   * @returns {PointerEvent} Retrieved finger-press or null if not found
   */
  index(index) {
    return Object.values(this._pointers)[index];
  }

  _pointerdownHandler = (event) => {
    this.set(event);
  };

  _pointermoveHandler = (event) => {
    this.set(event);
  };

  _pointerupHandler = (event) => {
    this.remove(event);
  };
}
