export function pendoTrackEvent(eventName, properties = {}) {
  if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
    window.pendo.track(eventName, properties);
  } else {
    console.warn(
      `Pendo is not ready, could not track event: ${eventName} with properties: ${JSON.stringify(
        properties
      )}`
    );
  }
}