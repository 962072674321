import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { showSpinner, hideSpinner } from "./loading_spinner_controller";

export default class extends Controller {
  static targets = ["form"];

  createExpress() {
    setTimeout(() => showSpinner({ timeout: 100 }), 0);
    let url = `${this.selectedTemplate.data("express-url")}.json`;
    this.drawerElement.addEventListener("drawer_portal:fetched", () => {
      this.validationsController.validate();
    });

    axios
      // We have to send an unused value so that @params[:job] exists for JobCreator
      .post(url, { job: { _id: 1 } })
      .then(({ data }) => {
        //
        this.drawerPortalController.data.set(
          "remote-path",
          `/manage/jobs/${data.id}/express/${data.id}/edit`
        );
        this.drawerPortalController.show();
        this.validationsController.validate();
        hideSpinner();
      });
  }

  setDrawerTitle(evt) {
    document.getElementById("express_post_title").innerHTML = $(
      evt.currentTarget
    )
      .next()
      .html();
  }

  success(evt) {
    console.log(evt);
    this.indexController.setStatus(null, "1", false);
  }

  get selectedTemplate() {
    return $("[name='job[clone_from_template_id]']:checked");
  }

  get drawerElement() {
    return document.getElementById("edit_express_job");
  }

  get validationElement() {
    return document.getElementsByClassName("express-drawer")[0];
  }

  get drawerPortalController() {
    return this.application.getControllerForElementAndIdentifier(
      this.drawerElement,
      "drawer-portal"
    );
  }

  get validationsController() {
    return this.application.getControllerForElementAndIdentifier(
      this.validationElement,
      "validations"
    );
  }

  get indexController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("jobIndexController"),
      "job-index"
    );
  }
}
