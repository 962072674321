import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let option = this.element;
    let select = option.parentElement;
    select.value = option.value;
    select.dispatchEvent(new Event("change"));
  }
}
