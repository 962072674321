import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import debounce from "lodash/debounce";

/**
 * Controller for giving real-time feedback of our passsword requirements and confirmation.
 *
 * @example HTML in view (note: this controller uses Bootstrap 4 formatting to show validation errors)
 *   <div data-controller="password-validation">
 *     <input type="text" data-password-validation-target="firstname" />
 *     <input type="text" data-password-validation-target="lastname" />
 *     <input type="text" data-password-validation-target="email" />
 *     <div class="form-group">
 *       <input type="password" data-password-validation-target="input" data-action="input->password-validation#validate" />
 *     </div>
 *     <div class="form-group">
 *       <input type="password" data-password-validation-target="confirmation" data-action="input->password-validation#validate" />
 *     </div>
 *   </div>
 */
export default class extends Controller {
  static targets = ["input", "confirmation", "firstname", "lastname", "email"];

  initialize() {
    this.validate = debounce(this.validate.bind(this), 250);
  }

  /**
   * Run validations on both the password field and confirmation field.
   *
   * @param {InputEvent} event the event from the action calling the function
   */
  validate(event) {
    event.preventDefault();

    if (event.target === this.inputTarget) this.validatePassword();
    this.validateConfirmation();
  }

  // Private

  validatePassword() {
    const self = this;
    axios
      .post("/password_validation", {
        firstname: this.firstnameTarget.value,
        lastname: this.lastnameTarget.value,
        email: this.emailTarget.value,
        password: this.inputTarget.value
      })
      .then((resp) => {
        if (resp.data.errors && resp.data.errors.length > 0) {
          self.setError(self.inputTarget, resp.data.errors.join("<br/>"));
        } else {
          self.clearError(self.inputTarget);
        }
      });
  }

  validateConfirmation() {
    if (
      this.confirmationTarget.value &&
      this.confirmationTarget.value !== this.inputTarget.value
    ) {
      this.setError(this.confirmationTarget, "Passwords do not match");
    } else {
      this.clearError(this.confirmationTarget);
    }
  }

  setError(element, message) {
    const wrapperElement = element.closest(".form-group");
    wrapperElement.classList.add("form-group-invalid");
    this.withErrorElement(wrapperElement, true, (errorElement) => {
      errorElement.innerHTML = message;
    });
  }

  clearError(element) {
    const wrapperElement = element.closest(".form-group");
    wrapperElement.classList.remove("form-group-invalid");
    this.withErrorElement(wrapperElement, false, (errorElement) => {
      errorElement.remove();
    });
  }

  withErrorElement(wrapperElement, force, callback) {
    let errorElement = wrapperElement.querySelector(".invalid-feedback");
    if (!errorElement && force) {
      wrapperElement.insertAdjacentHTML(
        "beforeend",
        '<div class="invalid-feedback"></div>'
      );
      errorElement = wrapperElement.querySelector(".invalid-feedback");
    }
    if (errorElement) callback(errorElement);
  }
}
