import { Controller } from "@hotwired/stimulus";
import broadcast, { ensureChannel, itemMessage } from "../../utils/broadcast";

export default class extends Controller {
  connect() {
    ensureChannel(this.data.get("channel"));
    this.channel = broadcast[`${this.data.get("channel")}Channel`]();
  }

  disconnect() {
    this.channel.close();
  }

  notify() {
    this.channel.postMessage(itemMessage(this.id, this.payload()));
  }

  get id() {
    if (this.data.has("id")) return this.data.get("id");

    return "index";
  }

  payload() {
    if (this.data.has("payload")) {
      return this.data.get("payload");
    } else {
      return null;
    }
  }
}
