import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this._initializePopovers();
    this._preventParentClick();
  }

  _initializePopovers() {
    $('[data-toggle="popover"]').popover();
  }

  // Prevents event bubbling up if the popup trigger happens to be within another clickable
  // element (e.g. a sortable table heading).
  _preventParentClick() {
    $('[data-toggle="popover"]').click((e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  }
}
