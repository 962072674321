import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["search", "items", "clear"];

  search() {
    const term = this.searchTarget.value;
    if (term.length < 1) {
      this.clearSearch();
    } else {
      this.storeTerm("search", term);
      this.performSearch();
    }
  }

  selectTerm(event) {
    const term = event.target.value;
    this.storeTerm("select", term);
    this.performSearch();
  }

  storeTerm(key, value) {
    if (!this.searchValues) this.searchValues = {};

    this.searchValues[key] = value;
  }

  performSearch() {
    this.clearTarget.classList.remove("d-none");
    this.fireEvent("search");
    this.matchSearchTerms();
  }

  matchSearchTerms() {
    this.allItems((el) => {
      if (this.searchExpression(el.innerHTML)) {
        el.parentNode.style.display = "block";
      } else {
        el.parentNode.style.display = "none";
      }
    });
  }

  searchExpression(value) {
    return Object.values(this.searchValues)
      .filter((term) => term.length > 0)
      .every((term) => value.match(new RegExp(term, "i")));
  }

  preventSubmit(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      const selectedLabel = Array.from(this.queryItems()).find((el) => {
        return el.offsetParent !== null;
      });
      const selectedItem = selectedLabel.parentNode.querySelector("input");
      if (selectedItem) {
        selectedItem.checked = true;
        selectedItem.dispatchEvent(new Event("change"));
      }
    }
  }

  clearSearch(event) {
    if (event) event.preventDefault();
    this.storeTerm("search", "");
    this.searchTarget.value = "";
    this.matchSearchTerms();
    this.fireEvent("cleared");
    this.clearTarget.classList.add("d-none");
  }

  scrollIfNeeded(el) {
    const position = el.getBoundingClientRect(),
      parent = el.closest("fieldset").getBoundingClientRect();

    if (position.top >= parent.top && position.bottom <= parent.bottom) {
      return;
    }
    el.scrollIntoView();
  }

  itemSelector() {
    if (this.data.get("selector")) {
      return this.data.get("selector");
    } else {
      return "label";
    }
  }

  queryItems() {
    return this.itemsTarget.querySelectorAll(this.itemSelector());
  }

  allItems(callback) {
    this.queryItems().forEach(callback);
  }

  fireEvent(name) {
    this.element.dispatchEvent(
      new CustomEvent(`refine:${name}`, { bubbles: true, cancelable: true })
    );
  }
}
