import { Controller } from "@hotwired/stimulus";
import IframeResizer from "iframe-resizer";

export default class extends Controller {
  connect() {
    IframeResizer.iframeResizer(this.options, `#${this.element.id}`);
  }

  disconnect() {
    this.element.iFrameResizer.removeListeners();
  }

  get options() {
    if (this.data.has("options")) {
      return this.addMessageCallback(JSON.parse(this.data.get("options")));
    } else {
      return this.addMessageCallback({});
    }
  }

  addMessageCallback(options) {
    if ("onMessage" in options) return options;

    options.onMessage = this.onMessage;
    return options;
  }

  onMessage = (data) => {
    const message = JSON.parse(data.message);
    if ("redirect" in message) this.handleRedirect(message);
  };

  handleRedirect(message) {
    window.location = message.redirect;
  }
}
