import { Controller } from "@hotwired/stimulus";

/**
 * Activates a drawer based on Location#hash. Can be used to:
 * - Open a drawer when landing on a page with the given hash
 * - Open a drawer by clicking on a link with the given hash
 */
export default class extends Controller {
  connect() {
    window.addEventListener("hashchange", this.openDrawer);
    this.openDrawer();
  }

  disconnect() {
    window.removeEventListener("hashchange", this.openDrawer);
  }

  openDrawer = () => {
    if (window.location.hash != this.hash) return;

    this.withDrawer((drawer) => {
      drawer.show();
      drawer.portal.element.addEventListener(
        "drawer_portal:hide",
        this.resetHash,
        {
          once: true
        }
      );
    });
  };

  resetHash = () => {
    history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search
    );
    Turbo.navigator.history.push(window.location.pathname + window.location.search);
  };

  get hash() {
    if (!this.data.has("hash")) return "";
    if (this.data.get("hash").indexOf("#") === 0) return this.data.get("hash");

    return `#${this.data.get("hash")}`;
  }

  withDrawer(callback) {
    const drawerElement = this.element.closest("[data-controller~='drawer']");
    const drawer = this.application.getControllerForElementAndIdentifier(
      drawerElement,
      "drawer"
    );
    if (drawer && drawer.portal) {
      callback(drawer);
    } else {
      // deals with race-condition between this#connect and drawer#connect
      setTimeout(() => {
        this.withDrawer(callback);
      }, 100);
    }
  }
}
