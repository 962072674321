import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "details",
    "hint",
    "input",
    "inputWrapper",
    "option",
    "video",
    "phone",
    "inPerson",
    "autoContainer",
    "autoToggle"
  ];
  static values = {
    defaultConfig: Boolean,
    selectedLocation: String,
    inPersonHint: String,
    inPersonLabel: String,
    inPersonPlaceholder: String,
    inPersonDetails: String,
    phoneHint: String,
    phoneLabel: String,
    phoneDetails: String,
    videoHint: String,
    videoLabel: String,
    videoPlaceholder: String,
    videoDetails: String,
    autoVideoDetails: String
  }

  connect() {
    // set up default
    this.handleLoad(this.selectedLocationValue);
  }

  handleSelection(event) {
    this.processLocationType(event.target.value, true);
  }

  handleLoad(location) {
    this.processLocationType(location, false);
  }

  videoToggle(event) {
    this.handleAutoVideoToggle(true);
  }

  processLocationType(location, isClick) {
    switch (location) {
      case "video":
        this.handleVideo();
        if (isClick && this.hasAutoToggleTarget) {
          this.autoToggleTarget.checked = true;
        }
        this.handleAutoVideoToggle(true);
        break;
      case "phone":
        this.handlePhone();
        this.handleAutoVideoToggle(false);
        break;
      case "in person":
        this.handleInPerson();
        this.handleAutoVideoToggle(false);
        break;
      default:
        this.handleInPerson();
        this.handleAutoVideoToggle(false);
        break;
    }
  }

  handleVideo() {
    this.detailsTarget.innerHTML = this.videoLabelValue;
    this.hintTarget.innerHTML = this.videoHintValue;
    this.inputTarget.value = this.videoDetailsValue;
    this.inputTarget.placeholder = this.videoPlaceholderValue;
    this.inputTarget.removeAttribute("readonly");

    this.clearPreviousChoice();
    this.videoTarget.querySelector("input[type=radio]").checked = true;
    this.videoTarget.classList.add("active");
  }

  handlePhone() {
    this.detailsTarget.innerHTML = this.phoneLabelValue;
    this.hintTarget.innerHTML = this.phoneHintValue;
    this.inputTarget.placeholder = "";
    this.inputTarget.value = this.phoneDetailsValue;
    if (this.defaultConfigValue === true) {
      this.inputTarget.setAttribute("readonly", true);
    } else {
      this.inputTarget.removeAttribute("readonly");
    }

    this.clearPreviousChoice();
    this.phoneTarget.querySelector("input[type=radio]").checked = true;
    this.phoneTarget.classList.add("active");
  }

  handleInPerson() {
    this.detailsTarget.innerHTML = this.inPersonLabelValue;
    this.hintTarget.innerHTML = this.inPersonHintValue;
    this.inputTarget.placeholder = this.inPersonPlaceholderValue;
    this.inputTarget.value = this.inPersonDetailsValue;
    this.inputTarget.removeAttribute("readonly");

    this.clearPreviousChoice();
    this.inPersonTarget.querySelector("input[type=radio]").checked = true;
    this.inPersonTarget.classList.add("active");
  }

  handleAutoVideoToggle(isVideo) {
    if (isVideo && this.hasAutoContainerTarget && this.hasAutoToggleTarget) {
      // are we on the video tab?
      this.autoContainerTarget.style.display = "block";
      if (this.autoToggleTarget.checked) {
        // is the toggle still on?
        this.inputWrapperTarget.style.display = "none";
        this.inputTarget.value = this.autoVideoDetailsValue;
      } else {
        this.inputWrapperTarget.style.display = "block";
        this.inputTarget.value = this.videoDetailsValue;
      }
    } else {
      if(this.hasAutoToggleTarget) this.autoToggleTarget.checked = false;
      if(this.hasAutoContainerTarget) this.autoContainerTarget.style.display = "none";
      this.inputWrapperTarget.style.display = "block";
    }
  }

  clearPreviousChoice() {
    this.optionTargets.forEach((option) => {
      option.classList.remove("active");
    });
  }
}
