import { Controller } from "@hotwired/stimulus";
import axios from "axios";

const updateNavbarSetting = (element, val) => {
  axios.patch(`/admin/users/${element.dataset.user}/update_setting`, {
    value: val,
    name: element.dataset.name
  });
};

export default class extends Controller {
  toggleCollapse() {
    const className = "impersonation-navbar--mini";
    const classList = this.element.classList;

    if (classList.contains(className)) {
      classList.remove(className);
      updateNavbarSetting(this.element, 0);
    } else {
      classList.add(className);
      updateNavbarSetting(this.element, 1);
    }
  }
}
