import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const selector = "#collapse_" + this.elementId;
    const caret = ".icon_" + this.elementId;

    // event handlers for caret (flip caret)
    $(selector).on("hide.bs.collapse", () => {
      $(caret).removeClass("fa-chevron-up").addClass("fa-chevron-down");
      this.element.classList.remove("collapse-active");
    });
    $(selector).on("show.bs.collapse", () => {
      $(caret).removeClass("fa-chevron-down").addClass("fa-chevron-up");
      this.element.classList.add("collapse-active");
    });
  }

  get elementId() {
    return this.data.get("element-id");
  }

  toggle(event) {
    event.preventDefault();
    this.toggle_accordion();
  }

  toggle_accordion() {
    const element = "#collapse_" + this.elementId;
    $(element).collapse("toggle");
  }
}
