import { Controller } from "@hotwired/stimulus";
import TimeAgo from 'javascript-time-ago';
export default class extends Controller {
  static targets = ["result"];

  static values = {
    time: String,
    style: String,
    prepend: String
  }

  connect() {
    TimeAgo.addLabels('en', 'custom', this.customLabels());
    const timeAgo = new TimeAgo('en-US');
    let formatted_time = timeAgo.format(new Date(this.timeValue), this.customStyle());
    this.resultTarget.innerHTML =  formatted_time;
  }

  // quasi private

  customLabels() {
    const label = this.styleValue == 'current' ? this.currentLabel() : this.defaultLabel();
    return label;
  }

  customStyle() {
    // if we add custom rounding rules, update here.
    const customStyle = {
      labels: 'custom'
    }
    return customStyle;
  }

  currentLabel() {
    const currentLabel = {
      minute: {
        past: {
          one: "for less than a minute",
          other: "for about {0} minutes"
        }
      },
      hour: {
        past: {
          one: "for about {0} hour",
          other: "for about {0} hours"
        }
      },
      day: {
        past: {
          one: "for about {0} day",
          other: "for about {0} days"
        }
      },
      year: {
        past: {
          one: "for about {0} year",
          other: "for about {0} years"
        }
      }
    }

    return currentLabel;
  }

  defaultLabel() {
    const defaultLabel = {
      minute: {
        past: {
          one: this.buildLabel("{0} minute ago"),
          other: this.buildLabel("{0} minutes ago")
        }
      },
      hour: {
        past: {
          one: this.buildLabel("about an hour"),
          other: this.buildLabel("about {0} hours ago")
        }
      },
      day: {
        past: {
          one: this.buildLabel("{0} day ago"),
          other: this.buildLabel("{0} days ago")
        }
      },
      year: {
        past: {
          one: this.buildLabel("{0} year ago"),
          other: this.buildLabel("{0} years ago")
        }
      }
    }

    return defaultLabel;
  }

  buildLabel(content) {
    if (!this.hasPrependValue) return content;

    return this.prependValue + " " + content;
  }
}
