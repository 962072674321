import { Controller } from "@hotwired/stimulus";

/**
 * This controller turns a select input into a navigation control. Calling the
 * goToNewLocation event handler will send the user to the location specified as
 * the value of the option selected.
 *
 * @example <caption>Example using select_tag helper.</caption>
 *   <%= select_tag(:access_level,
 *     options_for_select({
 *       t("user.role_name.recruiter") => retain_employees_path(@facade.access_level_params('limited')),
 *       t("user.role_name.employee") => retain_employees_path(@facade.access_level_params('none'))
 *     }, url_for(only_path: true, params: @facade.access_level_params(params[:access_level]))),
 *     data: {
 *       controller: 'select-navigation',
 *       action: 'change->select-navigation#goToNewLocation'
 *     }
 *   ) %>
 *
 * @example <caption>Example using plain HTML.</caption>
 *   <select data-controller="select-navigation"
 *           data-action="change->select-navigation#goToNewLocation">
 *     <option value="/some/path">Some Path</option>
 *     <option value="/another/path">Another Path</option>
 *   </select>
 */
export default class extends Controller {

  /**
   * Handler for when an option is selected to trigger navigation to the new
   * location.
   *
   * If Turbo is defined on the window, use this to navigate to the new location
   * to get the benefits of caching.
   *
   * @param {Event} event object implementing the Event interface
   */
  goToNewLocation(event) {
    if (window.Turbo) {
      window.Turbo.visit(event.target.value);
    } else {
      window.location = event.target.value;
    }
  }
}
