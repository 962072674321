import { Controller } from "@hotwired/stimulus";

/*
  Use this controller whenever you need to use a similar pattern to:
  https://thoughtbot.com/blog/dynamic-forms-with-turbo

  Example usage for a dynamic form created with Simple Form:

  <% form.element...
    wrapper_html: {
      data: {
        action: 'change/click/etc->hotwire-form-refresh#refreshForm'
      }
    }
  %>

  Hidden button (Note: must be placed AFTER the form submit button in the DOM structure):

  <div class="d-none">
    <%= form.button :submit, 'Refresh',
                     formmethod: :get,
                     formaction: path_that_renders_your_form,
                     data: {
                      hotwire_form_refresh_target: 'refreshButton'
                    }
    %>
  </div>

  Example usage: app/views/manage/settings/campaign_registry/sms_registry_brands/_form.html
*/

export default class extends Controller {
  static targets = ["refreshButton"];

  refreshForm() {
    this.refreshButtonTarget.click();
  }
}
