import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { targetId: String, maxChars: Number, disabled: Boolean }

  /**
   * Connects the controller to the DOM. Initializes the input field and sets up event listeners.
   */
  connect() {
    this.inputField = document.getElementById(this.targetIdValue);
    this.updateCounterBind = this.updateCounter.bind(this);
    this.inputField.addEventListener('input', this.updateCounterBind);
    this.updateCounter();
  }

  /**
   * Disconnects the controller from the DOM. Cleans up the event listeners.
   */
  disconnect() {
    if (this.inputField) {
      this.inputField.removeEventListener('input', this.updateCounterBind);
    }
  }

  /**
   * Updates the character count display. If the character count exceeds the max limit,
   * applies a shake and highlight effect to the counter.
   */
  updateCounter() {
    const text = !!this.inputField.value ? this.inputField.value : this.inputField.innerText,
      l = text.length;

    if (l > this.maxCharsValue) {
      if (this.disabledValue) {
        this.inputField.value = text.substring(0, this.maxCharsValue);
      }

      this._addShakeAndHighlight();
    } else {
      this._removeShakeAndHighlight();
    }
    this.element.innerText = `${l} / ${this.maxCharsValue}`;
  }

  /**
   * Adds a shake and highlight effect to the character counter.
   * @private
   */
  _addShakeAndHighlight() {
    this.element.classList.add('shake', 'highlight-red');
    setTimeout(() => this.element.classList.remove('shake', 'highlight-red'), 820);
  }

  /**
   * Removes the shake and highlight effect from the character counter.
   * @private
   */
  _removeShakeAndHighlight() {
    this.element.classList.remove('shake', 'highlight-red');
  }
}


