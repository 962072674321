import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["country", "state", "zip", "ein"];
  static values = {
    blankDefault: Boolean,
  };

  connect() {
    this.stateValueCache = {};
    this.changeState();
    this.changeLocale();
  }

  changeState() {
    this.stateValueCache[this.countryTarget.value] = this.stateTarget.value;
  }

  changeLocale() {
    const locale = JSON.parse(this.data.get("data"))[
      `en-${this.countryTarget.value}`
    ];
    if (locale) {
      this.replaceStateOptions(locale.state.options);
      this.replaceLabel(
        this.stateTarget.closest(".form-group").querySelector("label"),
        locale.state.label,
        this.stateTarget.classList.contains("required")
      );
      if(this.hasZipTarget){
        this.replaceLabel(
          this.zipTarget.closest(".form-group").querySelector("label"),
          locale.zip.label,
          this.zipTarget.classList.contains("required")
        );
      }
      if(this.hasEinTarget){
        this.replaceLabel(
          this.einTarget.closest(".form-group").querySelector("label"),
          locale.ein.label,
          this.einTarget.classList.contains("required")
        );
        this.replaceHint(
          this.einTarget.closest(".form-group").querySelector("small"),
          locale.ein.hint
        );
      }
    }
  }

  replaceStateOptions(options) {
    if (this.blankDefaultValue){
      options.unshift([" "," "]);
    }
    const oldValue = this.stateValueCache[this.countryTarget.value];
    this.stateTarget.innerHTML = options.map((item) => {
      let option = `<option value="${item[1]}"`;
      if (item[1] == oldValue) {
        option = option + ' selected="selected"';
      }
      return option + `>${item[0]}</option>`;
    });
  }

  replaceLabel(label, value, required) {
    if (required) {
      label.innerHTML = `${value} ${this.data.get("required")}`;
    } else {
      label.innerHTML = value;
    }
  }

  replaceHint(hint, value) {
    hint.innerHTML = value;
  }
}
