import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "resetPasswordModal",
  ];

  showResetPasswordModal() {
    $(this.resetPasswordModalTarget).modal();
  }

  hideModal() {
    $(this.resetPasswordModalTarget).modal("hide");
  }
}
