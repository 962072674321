import { Controller } from "@hotwired/stimulus";
import { showToast, toastDanger } from "../manage/toasts_controller";
import { ajaxResponse } from "../../utils/forms";

export default class extends Controller {
  connect() {
    const responses = this.data.get("flashed-responses");
    if (responses) {
      showToastResponses(responses);
      this.data.set("flashed-responses", null);
    }
  }

  complete(evt) {
    showToastResponses(ajaxResponse(evt));
  }

  genericFailure() {
    toastDanger({ title: "Something went wrong", body: "Please try again." });
  }
}

export const showToastResponses = function (response) {
  let toasts = response;
  if (toasts == "" || toasts == "[]" || toasts == "null") return;
  if (typeof toasts === "string") toasts = JSON.parse(response);
  if (typeof toasts.forEach === "undefined") toasts = [toasts];
  toasts.forEach((toast) => {
    showToast(toast);
  });
};
