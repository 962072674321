import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { ajaxResponse } from "../../utils/forms";

export default class extends Controller {
  replace = () => {
    const path = this.data.get("url");
    axios.get(path).then(({ data }) => {
      this.replaceContent(data);
    });
  };

  replaceFromResponse(event) {
    this.replaceContent(ajaxResponse(event));
  }

  replaceContent(content) {
    const target = document.getElementById(this.data.get("target"));
    if (this.data.has("insert")) {
      target.insertAdjacentHTML(this.data.get("insert"), content);
    } else {
      target.outerHTML = content;
    }
  }
}
