import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["on", "off"];

  connect() {
    this.toggle = document.getElementById(this.data.get("id"));
    this.setInitialState();

    if (this.toggle) {
      this.switchContent = this.switchContent.bind(this);
      this.toggle.addEventListener("change", this.switchContent);
    }
  }

  disconnect() {
    if (this.toggle) {
      this.toggle.removeEventListener("change", this.switchContent);
    }
  }

  setInitialState() {
    if (this.toggle && this.toggle.checked && this.hasOnTarget) {
      this.show(this.onTarget);
    } else {
      this.show(this.offTarget);
    }
  }

  switchContent() {
    if (!this.hasOnTarget || !this.hasOffTarget) return;

    if (this.toggle.checked) {
      this.hide(this.offTarget);
      this.show(this.onTarget);
    } else {
      this.hide(this.onTarget);
      this.show(this.offTarget);
    }
  }

  show(element) {
    element.classList.remove("d-none");
  }

  hide(element) {
    element.classList.add("d-none");
  }
}
