import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.limitSelected();
  }

  limitSelected(_event) {
    const disabled =
      parseInt(this.data.get("limit")) <=
      this.element.querySelectorAll("input:checked").length;
    this.allInputs((el) => {
      el.disabled = !el.checked && disabled;
    });
  }

  allInputs(callback) {
    this.element.querySelectorAll("input").forEach(callback);
  }
}
