import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "slider", "leftText", "rightText"];

  connect() {
    this.update();
  }

  toggle() {
    this.update();
  }

  update() {
    if (this.inputTarget.checked) {
      this.sliderTarget.style.transform = "translateX(100%)";
      this.leftTextTarget.style.color = "black";
      this.rightTextTarget.style.color = "white";
    } else {
      this.sliderTarget.style.transform = "translateX(0)";
      this.leftTextTarget.style.color = "white";
      this.rightTextTarget.style.color = "black";
    }
  }
}
