window.ACTIVE_HTTP_REQUEST = 0;

(function (open) {
  return (XMLHttpRequest.prototype.open = function (
    method,
    url,
    async,
    user,
    pass
  ) {
    this.addEventListener(
      "readystatechange",
      function () {
        if (this.readyState == 1) {
          window.ACTIVE_HTTP_REQUEST += 1;
        } else if (this.readyState == 4) {
          window.ACTIVE_HTTP_REQUEST -= 1;
        }
      },
      false
    );
    return open.call(this, method, url, async, user, pass);
  });
})(XMLHttpRequest.prototype.open);
