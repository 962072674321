import { Controller } from "@hotwired/stimulus";
import "spectrum-colorpicker2/dist/spectrum.min.js";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.input = $(this.inputTarget);
    this.color = this.inputTarget.value;

    this.input.spectrum({
      type: "component",
      showPalette: true,
      showInput: true,
      showInitial: true,
      showAlpha: false,
      showButtons: false,
      hide: (color) => {
        const hexColor = this.hexColor(color);

        this.inputTarget.value = hexColor;
        this.updateEvent(hexColor);
      }
    });

    this.handleEnter = this.handleEnter.bind(this);
    this.inputTarget.addEventListener("keypress", this.handleEnter);

    $(this.element)
      .find(".sp-colorize-container")
      .on("click", () => {
        this.input.spectrum("show");
        return false;
      });
  }

  disconnect() {
    this.input.spectrum("destroy");
    this.inputTarget.removeEventListener("keypress", this.handleEnter);
  }

  updateEvent(color) {
    if (this.color == color) return;

    this.inputTarget.dispatchEvent(
      new CustomEvent("color_picker:update", {
        detail: color,
        bubbles: true,
        cancelable: true
      })
    );

    this.color = color;
  }

  handleEnter(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      event.stopPropagation();

      this.input.spectrum("toggle");
    }
  }

  hexColor(color) {
    if (color === null) return "";

    return color.toHexString();
  }
}
