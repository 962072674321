import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    this.openModal();
  }

  openModal() {
    $(this.modalTarget).modal({ backdrop: "static" });
  }
}
