import { Controller } from "@hotwired/stimulus";
import { inputError, clearInputError } from "../../utils/forms";

const SMS_LENGTH = 160;
const INITIAL_WARNING = 150;
const MAXIMUM_LENGTH = 1000;

export default class extends Controller {
  static targets = ["input", "help"];

  connect() {
    this.displayError();
  }

  displayError(onNoError = () => {}) {
    if (this.exceedsMaxLength()) {
      inputError(this.inputTarget, this.data.get("reachedMaximum"));
      this.truncateInput();
    } else if (this.exceedsWarningLength()) {
      this.showInfoTemplate();
      inputError(this.inputTarget, this.hintContent(), "warning");
    } else {
      onNoError();
    }
  }

  exceedsMaxLength() {
    return this.inputTarget.value.length >= MAXIMUM_LENGTH;
  }

  exceedsWarningLength() {
    return this.inputTarget.value.length > INITIAL_WARNING;
  }

  truncateInput() {
    this.inputTarget.value = this.inputTarget.value.substring(
      0,
      MAXIMUM_LENGTH
    );
    console.log(this.inputTarget.value.length);
  }

  showInfoTemplate() {
    if (this.hasHelpTarget) {
      this.helpTarget.classList.remove("d-none");
    } else {
      this.element
        .querySelector(".form-label-wrapper")
        .insertAdjacentHTML("afterbegin", this.data.get("infoTemplate"));
    }
  }

  fieldUpdated(_event) {
    this.displayError(() => {
      this.hideInfoTemplate();
      clearInputError(this.inputTarget);
    });
  }

  hideInfoTemplate() {
    if (this.hasHelpTarget) {
      this.helpTarget.classList.add("d-none");
    }
  }

  hintContent() {
    const blocks = this.blockCount();
    if (blocks > 0) {
      return `<div class="d-flex align-items-start">
        <div class="flex-grow-1">${this.data
          .get("parts")
          .replace("{parts}", blocks + 1)}</div>
        <div>${this.smsCounter(blocks + 1)}</div>
      </div>`;
    } else {
      return `<div class="d-flex align-items-start">
        <div class="flex-grow-1">&nbsp;</div>
        <div>${this.smsCounter(blocks)}</div>
      </div>`;
    }
  }

  blockCount() {
    return Math.floor(this.inputTarget.value.length / SMS_LENGTH);
  }

  remainingCount() {
    return SMS_LENGTH - (this.inputTarget.value.length % SMS_LENGTH);
  }

  smsCounter(blocks) {
    let result = this.remainingCount();
    if (blocks > 0) result = result + " / " + blocks;
    return result;
  }
}
