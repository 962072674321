import { Controller } from "@hotwired/stimulus"
/**
 * Controller for adding toggle functionality to any button/link text
 *
 * @example HTML in view (if using Stimulus 2 or later)
 *   <div data-controller="toggle-text" data-toggle-text-open-value=false>
 *     <%= link_to "Link Text", class: 'btn', data: { action: 'click->toggle-text#toggleText' } do %>
 *        <span data-toggle-text-target="closedText">Before Click</span>
 *        <span data-toggle-text-target="openText">After Click</span>
 *     <% end %>
 *   </div>
 */
export default class extends Controller {
  static targets = ['closedText', 'openText']
  static values = {
    open: Boolean
  }

  connect() {
    this.setTextVisibility();
  }

/**
 * Toggle the text of the button/link
 *
 * @param {string} event the event from the action calling the function
 */
  toggleText(event) {
    event.preventDefault()

    this.openValue = this.openValue ? false : true
    this.openTextTarget.hidden = !this.openValue
    this.closedTextTarget.hidden = this.openValue
  }

  // Private

  setTextVisibility() {
    this.openTextTarget.hidden = !this.openValue
    this.closedTextTarget.hidden = this.openValue
  }
}
