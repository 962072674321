import { Controller } from "@hotwired/stimulus";

// This is the same as select_and_replace_controller except it doesn't request from server
// Also the same as customize_message_controller except less specialized
export default class extends Controller {
  static targets = ["content"];

  handleChange(event) {
    let selected = event.target.querySelector("option:checked");
    if (selected == null && event.target.dataset.content) selected = event.target;
    if (selected == null) return;

    this.contentTargets.forEach((el) => {
      el.innerHTML = selected.dataset.content;
    });
  }
}
