import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import $ from "jquery";

// This was produced by trial and error and is the difference from the window height
// required to determine whether the input is visible or not. This includes the full
// drawer header and footer given that the footer is absolutely positioned
const MAGIC_BUFFER = 90;
const MAX_SCROLL = 1000000;

export default class extends Controller {
  static targets = [
    "bottomVisibilityMarker",
    "scrollButton",
    "history",
    "loadMore",
    "scrollable"
  ];
  static values = {
    showButtonPos: { type: String, default: '6rem' },
    hideButtonPos: { type: String, default: '2rem' }
  }

  connect() {
    this.scrollTop();

    if (this.scrollTarget == this.element) return;

    this.scrollTarget.addEventListener("scroll", this.onScroll);
  }

  disconnect() {
    if (this.scrollTarget == this.element) return;

    this.scrollTarget.removeEventListener("scroll", this.onScroll);
  }

  get scrollTarget() {
    if (this.hasScrollableTarget) return this.scrollableTarget;

    const configuredTarget = this.element.closest(
      this.data.get("scrollTarget")
    );
    if (configuredTarget) {
      return configuredTarget;
    } else {
      return this.element;
    }
  }

  scrollTop = () => {
    this.scrollTarget.scrollTop = 0;
  };

  scrollBottom = () => {
    this.scrollTarget.scrollTop = MAX_SCROLL;
  };

  onScroll = () => {
    if (this.hasScrollButtonTarget) {
      if (this.inputVisible()) {
        this.hideScrollButton();
      } else {
        this.showScrollButton();
      }
    }
    if (this.hasLoadMoreTarget && this.scrollTarget.scrollTop === 0) {
      this.loadMoreHistory();
    }
  };

  showScrollButton() {
    if (
      !this.hasScrollButtonTarget ||
      parseInt(this.data.get("inputVisible")) === 1
    )
      return;

    this.data.set("inputVisible", 1);
    this.scrollButtonTarget.style.bottom = this.showButtonPosValue;
  }

  hideScrollButton() {
    if (
      !this.hasScrollButtonTarget ||
      parseInt(this.data.get("inputVisible")) !== 1
    )
      return;

    this.data.set("inputVisible", 0);
    this.scrollButtonTarget.style.bottom = this.hideButtonPosValue;
  }

  loadMoreHistory() {
    this.loadMoreTarget.innerHTML = this.loadMoreTarget.getAttribute(
      "data-loading"
    );
    const opts = {};
    if (this.loadMoreTarget.hasAttribute("data-params")) {
      opts["params"] = JSON.parse(
        this.loadMoreTarget.getAttribute("data-params")
      );
    }
    this.replaceHistoryWithPosition(opts);
  }

  replaceHistoryWithPosition(opts) {
    let topItem = this.element.querySelector("[data-id]");
    let topItemOffsetTop = null;
    let topItemId = null;
    if (topItem !== null) {
      topItemOffsetTop = $(topItem).position().top;
      topItemId = topItem.getAttribute("data-id");
    }
    this.replaceHistory(opts, () => {
      if (topItemId !== null) {
        topItem = this.element.querySelector(`[data-id=${topItemId}]`);
        if (topItem !== null) {
          this.scrollTarget.scrollTop =
            $(topItem).position().top - topItemOffsetTop;
        }
      }
    });
  }

  replaceHistory(opts, callback) {
    axios.get(this.data.get("path"), opts).then(({ data }) => {
      this.historyTarget.outerHTML = data;
      if (typeof callback === "function") callback();
    });
  }

  initialHistory() {
    if (this.data.get("history") !== "false") return;

    this.replaceHistory();
  }

  inputVisible() {
    const scroll = this.scrollTarget.scrollTop;
    const boundsTop =
      this.bottomVisibilityMarkerTarget.getBoundingClientRect().top + scroll;

    const viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight - MAGIC_BUFFER
    };

    const bounds = {
      top: boundsTop,
      bottom: boundsTop + this.bottomVisibilityMarkerTarget.clientHeight
    };

    return (
      (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
      (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
    );
  }
}
