import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import $ from "jquery";
import { toastDanger } from "../manage/toasts_controller";
import { generateRemoteModal, removeRemoteModal } from "../../utils/modals";

export default class extends Controller {
  static targets = ["container", "triggerElement"];

  connect() {
    this.data.set("remoteModalId", generateRemoteModal());
  }

  disconnect() {
    removeRemoteModal(this.data.get("remoteModalId"));
  }

  retrieve(e) {
    e.preventDefault();

    if (this.loading === true) return;

    this.loading = true;

    axios
      .get(this.data.get("location"))
      .then(({ data }) => {
        if (
          $(this.triggerElementTarget).find('[data-dismiss="modal"]').length > 0
        ) {
          $(this.triggerElementTarget).closest(".modal").modal("hide");
        }
        this.loading = false;
        const element = document.getElementById(this.data.get("remoteModalId"));
        element.innerHTML = data;

        //IE fix for flatpickr - bootstrap focus issue
        // https://github.com/flatpickr/flatpickr/issues/1352
        // https://github.com/flatpickr/flatpickr/issues/1730
        if (
          (navigator.userAgent.indexOf("MSIE") !== -1 ||
            navigator.appVersion.indexOf("Trident/") > -1) &&
          this.data.get("location").indexOf("reschedule") > 0
        ) {
          $(element).find(".modal").modal({ focus: false });
        } else $(element).find(".modal").modal();
      })
      .catch((thrown) => {
        this.loading = false;
        toastDanger({
          title: "Error",
          body: "Something went wrong, please try again."
        });
      });
  }

  loadingElement() {
    return this.triggerElementTarget.firstElementChild;
  }

  toggleLoading() {
    const element = this.loadingElement();

    if (this.loading === true) {
      if (element) {
        element.disabled = true;
        this.elementHTML = element.innerHTML;
        this.timeout = setTimeout(() => {
          element.insertAdjacentHTML(
            "afterbegin",
            '<i class="fal fa-sync fa-spin"></i> '
          );

          if (this.withOverlay) {
            document
              .querySelector(".voyager-loading-overlay")
              .classList.add("voyager-loading-overlay--shown");
          }
        }, 750);
      }
    } else {
      clearTimeout(this.timeout);
      if (element) {
        element.innerHTML = this.elementHTML;
        element.disabled = false;
      }
      if (this.withOverlay) {
        document
          .querySelector(".voyager-loading-overlay")
          .classList.remove("voyager-loading-overlay--shown");
      }
    }
  }

  get loading() {
    return this._loading;
  }

  get withOverlay() {
    return this.data.get("withOverlay") === "true";
  }

  set loading(value) {
    this._loading = value;
    this.toggleLoading();
  }
}
