import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "cancelModal",
  ];

  connect() {
    this.beginningFormState = this.getCurrentForm;
  }

  showCancelModal() {
    if (this.noFormChanges) {
      window.location = this.element.dataset.cancelPath
    } else {
      $(this.cancelModalTarget).modal();
    }
  }

  get noFormChanges() {
    return this.beginningFormState == this.getCurrentForm;
  }

  get getCurrentForm() {
    let currentFormState = $("#new_resource_form :not([name='authenticity_token'])").serialize();
    return currentFormState
  }

  get noFormChanges() {
    return this.beginningFormState == this.getCurrentForm;
  }
}
