import { Controller } from "@hotwired/stimulus";
import { toastSuccess } from "./toasts_controller";

// This controller will provide similar flash functionality on page load.
// Use cases: redirect to a page after a form submit and display a success message
export default class extends Controller {
  connect() {
    const message = this.data.get("message") || "";
    const has_flash = (message && message.length > 0) || false;

    if (has_flash) {
      toastSuccess({
        title: "Success!",
        body: message
      });
    }
  }
}
