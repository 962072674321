import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "subject", "body"];

  changeTemplate() {
    const selected = this.templateTarget.querySelector("option:checked");
    if (selected) {
      this.subjectTarget.value = selected.dataset.subject;
      this.bodyTarget.value = selected.dataset.body;
    }
  }
}
