import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];
  updating = false;
  checkboxGroups = new Map();

  initialize() {
    this.toggle = this.toggle.bind(this);
  }

  checkboxTargetConnected(checkbox) {
    const value = checkbox.getAttribute('value');
    if (!this.checkboxGroups.has(value)) {
      this.checkboxGroups.set(value, new Set());
    }
    this.checkboxGroups.get(value).add(checkbox);
  }

  syncCheckboxes(target) {
    const checkboxValue = target.getAttribute('value');

    (this.checkboxGroups.get(checkboxValue) || []).forEach(checkbox => {
      if (checkbox !== target) {
        checkbox.checked = target.checked;
        this.triggerInputEvent(checkbox);
      }
    });
  }

  toggle(e) {
    if (this.updating) return;

    this.updating = true;
    this.syncCheckboxes(e.target);
    this.updating = false;
  }

  triggerInputEvent(checkbox) {
    const event = new Event("input", { bubbles: false, cancelable: true });

    checkbox.dispatchEvent(event);
  }
}
