import { Controller } from "@hotwired/stimulus";

/** Used to conditionally disabled another input based on the value of another input changing */
export default class extends Controller {
    static targets = ['toDisable'];
    static values = {
        disables: Array,
        reset: { type: Boolean, default: false}
    };

    /**
     * Disables the toDisable input if the event target value is in the disablesValue array
     * @param target {HTMLElement}
     */
    disable({target}) {
        const selected = parseInt(target.value);

        if(this.disablesValue.includes(selected)) {
            this.toDisableTarget.setAttribute('disabled', true);
            this._reset();
        } else {
            this.toDisableTarget.removeAttribute('disabled');
        }
    }

    /**
     * Conditionally resets the toDisable target based on resetValue
     * @private
     */
    _reset() {
        if (this.resetValue) {
            this.toDisableTarget.value = '';
        }
    }
}
