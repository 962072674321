import { Controller } from "@hotwired/stimulus";
import template from "lodash/template";

export default class extends Controller {
  static targets = [
    "trigger",
    "cardElement",
    "cardErrors",
    "cardErrorsTemplate",
    "cardHolderField"
  ];

  static values = {
    publicKey: String,
    clientSecret: String,
    returnUrl: String
  };

  connect() {
    this._prepareTemplate();
    this._prepareStripe();
  }

  async submit(event) {
    if (!this.triggerTarget.checked) return true;

    event.preventDefault();

    if (this._valid_card_holder()) {
      const formData = new FormData(this.element);
      const params = new URLSearchParams(formData);

      const { error } = await this.stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: this._buildUrl(this.returnUrlValue, params.toString())
        }
      });

      if (error) {
        this._renderErrors(error.message);
        this._withSingleSubmitController((singleSubmit) =>
          singleSubmit.reset()
        );
      }
    } else {
      await new Promise((r) => setTimeout(r, 200));
      this._renderErrors(this.cardHolderFieldTarget.dataset.error);
      this._withSingleSubmitController((singleSubmit) => singleSubmit.reset());
    }
  }

  _prepareStripe() {
    this.stripe = window.Stripe(this.publicKeyValue);

    var options = {
      clientSecret: this.clientSecretValue
    };

    this.elements = this.stripe.elements(options);

    this.card = this.elements.create("payment");
    this.card.mount(this.cardElementTarget);
  }

  _prepareTemplate() {
    this.cardErrorsTemplate = template(this.cardErrorsTemplateTarget.innerHTML);
  }

  _valid_card_holder() {
    if (this.cardHolderFieldTarget.value.trim().length === 0) return false;

    return true;
  }

  _renderErrors(message) {
    this.cardErrorsTarget.innerHTML = this.cardErrorsTemplate({
      message: message
    });
  }

  _withSingleSubmitController(callback) {
    const result = this.application.getControllerForElementAndIdentifier(
      this.element,
      "single-submit"
    );
    if (result) callback(result);
  }

  _buildUrl(url, query) {
    if (url.indexOf("?") === -1) return url + "?" + query;
    return url + "&" + query;
  }
}
